import React, { ChangeEvent, useEffect, useState } from "react";
import AppMenu from "../includes/AppMenu";
import PageTopbar from "../includes/PageTopbar";
import { ToasterMessage } from "../utils/Toaster";
import { ErrorMessage, Field, Form, Formik } from "formik";
import InputErrorMsg from "../utils/InputErrorMsg";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import { bookHotel, fetchVendor } from "../request/api";
import { useDispatch, useSelector } from "react-redux";
import { OHotelFormData } from "../interface/DataInterface";
import {
  normalizeFieldName,
  sanitizePhoneNumber,
  sanitizePriceInput,
} from "../utils/helpers";
import { HotelBookingFormSchema } from "../formValidation/validation";
import ResponseModal from "../modal/ResponseModal";
import { DatePicker, Message, useToaster } from "rsuite";
import { SpinnerLoader } from "../utils/Loading";
import CreditInsufficientBalance from "../modal/CreditInsufficientBalance";
import HotelSidebar from "../includes/hotel/HotelSidebar";

const initialOHotelFormValues: OHotelFormData = {
  vendor_id: 1,
  booking_key: "",
  receiver_email: "",
  receiver_mobile: "",
  base_price: 0,
  fees_and_taxes: 0,
  selling_price: 0,
  hotel_name: "",
  hotel_address: "",
  hotel_contact: "",
  hotel_location: "",
  hotel_rating: "",
  room_type: "",
  room_inclusions: [],
  total_rooms: 1,
  adult_guests: [
    {
      salutation: "Mr",
      first_name: "",
      last_name: "",
    },
  ],
  child_guests: [],
  check_in_datetime: "",
  check_out_datetime: "",
  cancellation_policies: "",
  hotel_image: null,
};

const roomInclusionOptions = [
  {
    value: "free_breakfast",
    label: "Free Breakfast",
  },
  {
    value: "free_wifi",
    label: "Free WiFi",
  },
  {
    value: "non-smoking",
    label: "Non-smoking",
  },
  {
    value: "parking",
    label: "Parking",
  },
];

const ratingOptions = [
  {
    value: "1",
    label: "1 Star rating",
  },
  {
    value: "2",
    label: "2 Star rating",
  },
  {
    value: "3",
    label: "3 Star rating",
  },
  {
    value: "4",
    label: "4 Star rating",
  },
  {
    value: "5",
    label: "5 Star rating",
  },
];

export const adultSalutationOptions = [
  {
    value: "mr",
    label: "Mr",
  },
  {
    value: "ms",
    label: "Ms",
  },
];

export const childSalutationOptions = [
  {
    value: "mstr",
    label: "Mstr",
  },
  {
    value: "miss",
    label: "Miss",
  },
];

const checkInAllowedHours = [14, 15, 16]; // 2 PM, 3 PM, 4 PM
const checkOutAllowedHours = [10, 11, 12]; // 10 AM, 11 AM, 12 NOON

const CreateEVoucher: React.FC = () => {
  const dispatch = useDispatch();
  const toaster = useToaster();
  const { currentUser } = useSelector((state: any) => state);
  const [formData, setFormData] = useState<OHotelFormData | null>(null);
  const [showRoomGuestsDropdown, setShowRoomGuestsDropdown] =
    useState<boolean>(false);
  const [vendorOptions, setVendorOptions] = useState<any[]>([]);
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [checkInDateTime, setCheckInDateTime] = useState<Date | null>(null);
  const [checkOutDateTime, setCheckOutDateTime] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInsufficient, setIsInsufficient] = useState(false);

  // Get today's date in the required format (YYYY-MM-DDTHH:mm)
  const today = new Date();

  const handleSubmit = async (
    values: OHotelFormData,
    {
      setSubmitting,
      resetForm,
      setFieldValue,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
      resetForm: () => void;
      setFieldValue: (field: string, value: any) => void;
    }
  ) => {
    if (currentUser.credits === 0) {
      setIsInsufficient((prev) => !prev);
      return;
    }

    localStorage.setItem(
      "savedOutsourceHotelFormDetails",
      JSON.stringify(values)
    );

    setIsLoading(true);
    setSubmitting(true);

    await bookHotel(dispatch, values)
      .then((res) => {
        if (res?.result?.success) {
          resetForm();

          setFormData(initialOHotelFormValues);
          setMessage(res?.result?.message);
          setIsSuccess(res?.result?.success);
          setIsResponseModalOpen(true);
        }
      })
      .catch((err) => {
        setMessage(
          err?.response?.data?.result?.message ?? "Something went wrong."
        );
        setIsSuccess(err?.response?.data?.result?.success);
        setIsResponseModalOpen(true);

        // Rehydrate form from localStorage if available
        const savedFormDetails = localStorage.getItem(
          "savedOutsourceHotelFormDetails"
        );
        if (savedFormDetails) {
          const savedValues = JSON.parse(savedFormDetails);
          Object.keys(savedValues).forEach((field) => {
            setFieldValue(field, savedValues[field]);
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
        setCheckInDateTime(null);
        setCheckOutDateTime(null);
      });
  };

  const handleSaveDetails = (values: OHotelFormData) => {
    setFormData({ ...values });
  };

  const handleCheckInDate = (
    value: Date | null,
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (value) {
      let updatedDate = moment(value);

      // If no time is set (i.e., it's midnight), default to 2:00 PM
      if (
        !checkInAllowedHours.includes(updatedDate.hours()) &&
        updatedDate.minutes() !== 0
      ) {
        updatedDate = updatedDate.set({
          hour: 14,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }

      const formattedDateTime = updatedDate.format("YYYY-MM-DD HH:mm:ss");

      setCheckInDateTime(updatedDate.toDate()); // Convert the moment object back to a Date object for React
      setFieldValue("check_in_datetime", formattedDateTime); // Set the formatted value

      let autoCheckOutDate = updatedDate.add(1, "days").set({
        hour: 12,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      setCheckOutDateTime(autoCheckOutDate.toDate());
      setFieldValue("check_out_datetime", autoCheckOutDate);
    } else {
      setCheckInDateTime(null);
      setFieldValue("check_in_datetime", "");
      setFieldValue("check_out_datetime", "");
    }
  };

  const handleCheckOutDate = (
    value: Date | null,
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (value) {
      let updatedDate = moment(value);

      // If no time is set (i.e., it's midnight), default to 2:00 PM
      if (
        !checkOutAllowedHours.includes(updatedDate.hours()) &&
        updatedDate.minutes() !== 0
      ) {
        updatedDate = updatedDate.set({
          hour: 10,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }

      // Format to 'datetime-local' format (YYYY-MM-DDTHH:mm)
      const formattedDateTime = updatedDate.format("YYYY-MM-DD HH:mm:ss");

      setCheckOutDateTime(updatedDate.toDate()); // Convert the moment object back to a Date object for React
      setFieldValue("check_out_datetime", formattedDateTime); // Set the formatted value
    } else {
      setCheckOutDateTime(null);
      setFieldValue("check_out_datetime", "");
    }
  };

  const getVendorList = async () => {
    await fetchVendor(dispatch, { from_hotel: true })
      .then((res) => {
        if (res?.result.success) {
          setVendorOptions(res?.result?.data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getVendorList();
  }, []);

  return (
    <div id="layout-wrapper">
      {isLoading && <SpinnerLoader />}
      {!isLoading && (
        <>
          <ToasterMessage />
          <PageTopbar />
          <AppMenu />
          <CreditInsufficientBalance
            showModal={isInsufficient}
            handleClose={() => setIsInsufficient((prev) => !prev)}
          />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid px-0 px-md-2">
                <div className="col-xl-12">
                  <Formik
                    initialValues={initialOHotelFormValues}
                    validationSchema={HotelBookingFormSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      isSubmitting,
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                      setFieldError,
                      setSubmitting,
                      handleSubmit,
                      validateForm,
                    }) => {
                      return (
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();

                            // Run validation manually before submission
                            validateForm(values).then((errors) => {
                              if (Object.keys(errors).length > 0) {
                                // Show errors in a notification
                                const errorMessages = Object.entries(
                                  errors
                                ).map(([field, error]) => (
                                  <li key={field}>
                                    <strong>
                                      {normalizeFieldName(field)}:
                                    </strong>{" "}
                                    {Array.isArray(error)
                                      ? error.join(", ")
                                      : error}
                                  </li>
                                ));

                                toaster.push(
                                  <Message showIcon type="error" closable>
                                    <strong>Validation Errors:</strong>
                                    <br />
                                    {errorMessages}
                                  </Message>,
                                  {
                                    placement: "topEnd",
                                    duration: 30000,
                                  }
                                );

                                setSubmitting(false); // Prevent submission
                                return;
                              }

                              // Proceed with submission if no errors
                              handleSubmit();
                            });
                          }}
                        >
                          <div className="row mt-0 mt-md-4">
                            <div className="col-lg-8 order-1 order-md-1">
                              <div className="card rounded-2 border-shadow mb-2">
                                <div className="card-header">
                                  <h5 className="mb-0">Hotel Details</h5>
                                </div>
                                <div className="card-body px-4">
                                  <div className="mt-2">
                                    <div className="row mb-3">
                                      <div className="col-lg-7 col-8">
                                        <label
                                          htmlFor="name"
                                          className="form-label"
                                        >
                                          Hotel Name
                                        </label>
                                        <Field
                                          type="text"
                                          id="hotel_name"
                                          name="hotel_name"
                                          className={`form-control h-form fs-16 ${
                                            errors.hotel_name &&
                                            touched.hotel_name
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Exp: Crown Regency Prince Resort"
                                        />
                                        <ErrorMessage
                                          name="hotel_name"
                                          component={InputErrorMsg}
                                        />
                                      </div>

                                      <div className="col-lg-2 col-4 px-md-0 ps-0">
                                        <label
                                          htmlFor="hotel_rating"
                                          className="form-label"
                                        >
                                          Star Rating
                                        </label>
                                        <Field
                                          as="select"
                                          className={`form-control h-form ${
                                            errors.hotel_rating &&
                                            touched.hotel_rating
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="hotel_rating"
                                          name="hotel_rating"
                                          aria-label=".form-select-sm example"
                                          style={{
                                            paddingRight: "10px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <option value="0" disabled>
                                            Select Rating
                                          </option>
                                          {ratingOptions.map((ratingOption) => (
                                            <option
                                              key={ratingOption.label}
                                              value={ratingOption.value}
                                            >
                                              {ratingOption.label}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="hotel_rating"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                      <div className="col-md-3 mt-md-0 mt-3">
                                        <label
                                          htmlFor="booking_key"
                                          className="form-label"
                                        >
                                          Booking ID
                                        </label>
                                        <Field
                                          type="text"
                                          id="booking_key"
                                          name="booking_key"
                                          className={`form-control h-form fs-16 text-uppercase ${
                                            errors.booking_key &&
                                            touched.booking_key
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Exp: 25858850654"
                                        />
                                        <ErrorMessage
                                          name="booking_key"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mb-3 mt-md-0 mt-3">
                                      <div className="col-md-3 col-4">
                                        <label
                                          htmlFor="hotel_location"
                                          className="form-label"
                                        >
                                          Destination
                                        </label>
                                        <Field
                                          type="text"
                                          id="hotel_location"
                                          name="hotel_location"
                                          className={`form-control h-form fs-16 ${
                                            errors.hotel_location &&
                                            touched.hotel_location
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Provices/City"
                                        />
                                        <ErrorMessage
                                          name="hotel_location"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                      <div className="col-md-6 col-8 ps-0">
                                        <label
                                          htmlFor="hotel_address"
                                          className="form-label"
                                        >
                                          Hotel Address
                                        </label>
                                        <Field
                                          type="text"
                                          id="hotel_address"
                                          name="hotel_address"
                                          className={`form-control h-form fs-16 ${
                                            errors.hotel_address &&
                                            touched.hotel_address
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter hotel Address here"
                                        />
                                        <ErrorMessage
                                          name="hotel_address"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                      <div className="col-md-3 ps-md-0 mt-md-0 mt-3">
                                        <label
                                          htmlFor="hotel_contact"
                                          className="form-label"
                                        >
                                          Hotel Contact Details
                                        </label>
                                        <Field
                                          type="text"
                                          id="hotel_contact"
                                          name="hotel_contact"
                                          className={`form-control h-form fs-16 ${
                                            errors.hotel_contact &&
                                            touched.hotel_contact
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                          ) => {
                                            let value = sanitizePhoneNumber(
                                              e.target.value
                                            );
                                            setFieldValue(
                                              "hotel_contact",
                                              value
                                            );
                                          }}
                                          placeholder="Exp: 091885 00022"
                                          maxLength={15}
                                        />
                                        <ErrorMessage
                                          name="hotel_contact"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                    </div>

                                    <div className="row mb-3">
                                      <div className="col-md-3 col-6 pe-md-0 pe-0">
                                        <label
                                          htmlFor="check_in_datetime"
                                          className="form-label"
                                        >
                                          Check in Date/Time
                                        </label>
                                        <DatePicker
                                          format="dd MMM, yyyy hh:mm a"
                                          placeholder="Select date and time"
                                          value={checkInDateTime}
                                          onChange={(value) =>
                                            handleCheckInDate(
                                              value,
                                              setFieldValue
                                            )
                                          }
                                          // Hides all hours except allowed ones
                                          hideHours={(hour) =>
                                            !checkInAllowedHours.includes(hour)
                                          }
                                          // Hide all minutes except on the hour (e.g., :00)
                                          hideMinutes={(minute) => minute !== 0}
                                          disabledDate={(
                                            date?: Date | undefined
                                          ) => {
                                            if (date) {
                                              return moment(date)
                                                .startOf("day")
                                                .isBefore(today, "day");
                                            }
                                            return false;
                                          }}
                                          style={{ width: "100%" }}
                                          className="hotel-datetime-picker hotel-left-bordered"
                                        />
                                        <ErrorMessage
                                          name="check_in_datetime"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                      <div className="col-md-3 col-6 ps-md-0 ps-0">
                                        <label
                                          htmlFor="check_out_datetime"
                                          className="form-label"
                                        >
                                          Check out Date/Time
                                        </label>
                                        <DatePicker
                                          format="dd MMM, yyyy hh:mm a"
                                          placeholder="Select date and time"
                                          value={checkOutDateTime}
                                          onChange={(value) =>
                                            handleCheckOutDate(
                                              value,
                                              setFieldValue
                                            )
                                          }
                                          // Hides all hours except allowed ones
                                          hideHours={(hour) =>
                                            !checkOutAllowedHours.includes(hour)
                                          }
                                          // Hide all minutes except on the hour (e.g., :00)
                                          hideMinutes={(minute) => minute !== 0}
                                          disabledDate={(
                                            date?: Date | undefined
                                          ) => {
                                            if (date) {
                                              return moment(date)
                                                .startOf("day")
                                                .isBefore(today, "day");
                                            }
                                            return false;
                                          }}
                                          style={{ width: "100%" }}
                                          placement={"bottomRight" as any}
                                          className="hotel-datetime-picker hotel-right-bordered"
                                        />
                                        <ErrorMessage
                                          name="check_out_datetime"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                      <div className="col-md-6 col-12 ps-md-0 mt-2 mt-md-0 mt-3">
                                        <label
                                          htmlFor="rooms_and_guests_dropdown"
                                          className="form-label"
                                        >
                                          Rooms and Guests
                                        </label>
                                        <div
                                          className="p-2 px-3 rounded-1 position-relative h-form"
                                          style={{
                                            border: "1px solid #ced4da",
                                          }}
                                          onClick={() => {
                                            setShowRoomGuestsDropdown(
                                              (prev) => !prev
                                            );
                                          }}
                                        >
                                          <div className="fw-medium mt-1">
                                            {values.total_rooms} Room,{" "}
                                            {values.adult_guests.length} Adult,{" "}
                                            {values.child_guests.length}{" "}
                                            Children
                                          </div>
                                          <div className="position-absolute end-0 top-0 mt-2 me-2">
                                            <i className="ri-arrow-down-s-line fs-3 text-muted cursor-pointer"></i>
                                          </div>
                                        </div>
                                        {showRoomGuestsDropdown && (
                                          <div
                                            className="position-absolute p-md-0"
                                            style={{
                                              zIndex: "5",
                                              width: "96.9%",
                                            }}
                                          >
                                            <div className="p-2 card shadow-lg border border-1">
                                              <div className="row p-2">
                                                <div className="col-8 col-lg-8">
                                                  <div className="fs-14">
                                                    Rooms
                                                  </div>
                                                </div>
                                                <div className="col text-end">
                                                  <div className="row align-items-center">
                                                    <div className="col text-center pe-0">
                                                      <div>
                                                        <i
                                                          className="ri-indeterminate-circle-line fs-19 text-info fw-normal cursor-pointer"
                                                          onClick={() => {
                                                            if (
                                                              values.total_rooms ===
                                                              1
                                                            )
                                                              return;
                                                            setFieldValue(
                                                              "total_rooms",
                                                              values.total_rooms -
                                                                1
                                                            );
                                                          }}
                                                        ></i>
                                                      </div>
                                                    </div>
                                                    <div className="col text-center fw-semibold fs-14 px-0">
                                                      {values.total_rooms}
                                                    </div>
                                                    <div className="col text-center ps-0">
                                                      <div>
                                                        <i
                                                          className="ri-add-circle-line fs-19 text-info fw-normal cursor-pointer"
                                                          onClick={() => {
                                                            setFieldValue(
                                                              "total_rooms",
                                                              values.total_rooms +
                                                                1
                                                            );
                                                          }}
                                                        ></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row p-2">
                                                <div className="col-8 col-lg-8">
                                                  <div className="fs-14">
                                                    Adults{" "}
                                                    <span className="text-muted fs-13 fw-medium">
                                                      12+ Yrs
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="col text-end">
                                                  <div className="row align-items-center">
                                                    <div className="col text-center pe-0">
                                                      <div>
                                                        <i
                                                          className="ri-indeterminate-circle-line fs-19 text-info fw-normal cursor-pointer"
                                                          onClick={() => {
                                                            if (
                                                              values
                                                                .adult_guests
                                                                .length === 1
                                                            )
                                                              return;
                                                            const updatedAdults =
                                                              values.adult_guests.slice(
                                                                0,
                                                                -1
                                                              );
                                                            setFieldValue(
                                                              "adult_guests",
                                                              updatedAdults
                                                            );
                                                          }}
                                                        ></i>
                                                      </div>
                                                    </div>
                                                    <div className="col text-center fw-semibold fs-14 px-0">
                                                      {
                                                        values.adult_guests
                                                          .length
                                                      }
                                                    </div>
                                                    <div className="col text-center ps-0">
                                                      <div>
                                                        <i
                                                          className="ri-add-circle-line fs-19 text-info fw-normal cursor-pointer"
                                                          onClick={() => {
                                                            const updatedAdults =
                                                              [
                                                                ...values.adult_guests,
                                                                {
                                                                  salutation:
                                                                    "Mr",
                                                                  first_name:
                                                                    "",
                                                                  last_name: "",
                                                                },
                                                              ];
                                                            setFieldValue(
                                                              "adult_guests",
                                                              updatedAdults
                                                            );
                                                          }}
                                                        ></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row p-2">
                                                <div className="col-8 col-lg-8">
                                                  <div className="fs-15">
                                                    Children{" "}
                                                    <span className="text-muted fs-13 fw-medium">
                                                      (2 - 12 Yrs)
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="col text-end">
                                                  <div className="row align-items-center">
                                                    <div className="col text-center pe-0">
                                                      <div>
                                                        <i
                                                          className="ri-indeterminate-circle-line fs-19 text-info fw-normal cursor-pointer"
                                                          onClick={() => {
                                                            if (
                                                              values
                                                                .child_guests
                                                                .length === 0
                                                            )
                                                              return;
                                                            const updatedChildren =
                                                              values.child_guests.slice(
                                                                0,
                                                                -1
                                                              );
                                                            setFieldValue(
                                                              "child_guests",
                                                              updatedChildren
                                                            );
                                                          }}
                                                        ></i>
                                                      </div>
                                                    </div>
                                                    <div className="col text-center fw-semibold fs-14 px-0">
                                                      {
                                                        values.child_guests
                                                          .length
                                                      }
                                                    </div>
                                                    <div className="col text-center ps-0">
                                                      <div>
                                                        <i
                                                          className="ri-add-circle-line fs-19 text-info fw-normal cursor-pointer"
                                                          onClick={() => {
                                                            const updatedChildren =
                                                              [
                                                                ...values.child_guests,
                                                                {
                                                                  salutation:
                                                                    "Mstr",
                                                                  first_name:
                                                                    "",
                                                                  last_name: "",
                                                                },
                                                              ];
                                                            setFieldValue(
                                                              "child_guests",
                                                              updatedChildren
                                                            );
                                                          }}
                                                        ></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="p-2">
                                                Please select your child's age
                                                at check-in to help us find the
                                                best room types and discounts
                                                htmlFor your needs.
                                              </div>

                                              <div className="text-end my-2 pe-2">
                                                <button
                                                  type="button"
                                                  onClick={() =>
                                                    setShowRoomGuestsDropdown(
                                                      false
                                                    )
                                                  }
                                                  className="btn btn-info btn-sm"
                                                >
                                                  Done!
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div className="row mb-3">
                                      <div className="col-md-5">
                                        <label
                                          htmlFor="room_type"
                                          className="form-label"
                                        >
                                          Room Type
                                        </label>
                                        <Field
                                          type="text"
                                          id="room_type"
                                          name="room_type"
                                          className={`form-control h-form fs-16 ${
                                            errors.room_type &&
                                            touched.room_type
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Exp: Double or Twin Superion"
                                        />
                                        <ErrorMessage
                                          name="room_type"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                      <div className="col-md-7 ps-md-0 mt-md-0 mt-3">
                                        <label
                                          htmlFor="room_inclusions"
                                          className="form-label"
                                        >
                                          Room Inclusion
                                        </label>
                                        <CreatableSelect
                                          name="room_inclusions"
                                          isMulti
                                          isClearable
                                          placeholder="Select Room Inclusions"
                                          options={roomInclusionOptions}
                                          value={values.room_inclusions}
                                          onChange={(inputVal) => {
                                            setFieldValue(
                                              "room_inclusions",
                                              inputVal || []
                                            );
                                          }}
                                          onCreateOption={(inputVal) => {
                                            const newOption = {
                                              value: inputVal
                                                .toLowerCase()
                                                .replace(/\W/g, ""),
                                              label: inputVal,
                                            };

                                            const updatedInclusions = [
                                              ...(values.room_inclusions || []),
                                              newOption,
                                            ];
                                            setFieldValue(
                                              "room_inclusions",
                                              updatedInclusions
                                            );
                                          }}
                                          styles={{
                                            control: (base: any) => ({
                                              ...base,
                                              backgroundColor:
                                                "rgba(var(--vz-primary-rgb), var(--vz-bg-opacity))",
                                              color: "white",
                                              minHeight: "45px",
                                              boxShadow: "none",
                                              borderColor:
                                                "var(--vz-input-border) !important",
                                              fontSize: "16px !important",
                                            }),
                                            placeholder: (base: any) => ({
                                              ...base,
                                              color: "white",
                                            }),
                                            multiValue: (base: any) => ({
                                              ...base,
                                              backgroundColor:
                                                "rgba(var(--vz-primary-rgb), 1)",
                                            }),
                                            multiValueLabel: (base: any) => ({
                                              ...base,
                                              color: "white",
                                            }),
                                          }}
                                          className="w-100"
                                        />
                                        <ErrorMessage
                                          name="room_inclusions"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                    </div>
                                    <div className="mt-md-2 mt-4">
                                      <label
                                        htmlFor="cancellation_policies"
                                        className="form-label"
                                      >
                                        Cancellation Policy
                                      </label>
                                      <Field
                                        as="textarea"
                                        className={`form-control fs-16 ${
                                          errors.cancellation_policies &&
                                          touched.cancellation_policies
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        id="cancellation_policies"
                                        name="cancellation_policies"
                                        rows={3}
                                      />
                                      <ErrorMessage
                                        name="cancellation_policies"
                                        component={InputErrorMsg}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card rounded-2 border-shadow mb-2">
                                <div className="card-header">
                                  <h5 className="mb-0">Guest Details</h5>
                                </div>
                                <div className="card-body px-4">
                                  <div className="mt-2">
                                    {values.adult_guests.map(
                                      (adultGuest, adultGuestIdx) => (
                                        <div className="row mb-2 mb-md-3">
                                          <div className="col-12">
                                            <label
                                              htmlFor={`adult_guests[${adultGuestIdx}]`}
                                              className="form-label"
                                            >
                                              Adult {adultGuestIdx + 1}{" "}
                                              {adultGuestIdx === 0
                                                ? "(Lead Guest)"
                                                : ""}
                                            </label>
                                          </div>
                                          <div className="col-md-2 col-12 pe-lg-0 mt-2 mt-md-0">
                                            <Field
                                              as="select"
                                              className={`form-select h-form ${
                                                errors.adult_guests &&
                                                (
                                                  errors.adult_guests[
                                                    adultGuestIdx
                                                  ] as any
                                                )?.salutation &&
                                                touched.adult_guests &&
                                                (
                                                  touched.adult_guests[
                                                    adultGuestIdx
                                                  ] as any
                                                )?.salutation
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              id={`adult_guests[${adultGuestIdx}].salutation`}
                                              name={`adult_guests[${adultGuestIdx}].salutation`}
                                              aria-label="Default select example"
                                              style={{
                                                paddingRight: "10px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <option value="0" disabled>
                                                Title
                                              </option>
                                              {adultSalutationOptions.map(
                                                (adultSalutationOption) => (
                                                  <option
                                                    key={
                                                      adultSalutationOption.label
                                                    }
                                                    value={
                                                      adultSalutationOption.label
                                                    }
                                                  >
                                                    {
                                                      adultSalutationOption.label
                                                    }
                                                  </option>
                                                )
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name={`adult_guests[${adultGuestIdx}].salutation`}
                                              component={InputErrorMsg}
                                            />
                                          </div>
                                          <div className="col-md-5 col-6 mt-md-0 mt-2">
                                            <Field
                                              type="text"
                                              id={`adult_guests[${adultGuestIdx}].first_name`}
                                              name={`adult_guests[${adultGuestIdx}].first_name`}
                                              className={`form-control h-form fs-16 text-uppercase ${
                                                errors.adult_guests &&
                                                (
                                                  errors.adult_guests[
                                                    adultGuestIdx
                                                  ] as any
                                                )?.first_name &&
                                                touched.adult_guests &&
                                                (
                                                  touched.adult_guests[
                                                    adultGuestIdx
                                                  ] as any
                                                )?.first_name
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              placeholder="First Name"
                                            />
                                            <ErrorMessage
                                              name={`adult_guests[${adultGuestIdx}].first_name`}
                                              component={InputErrorMsg}
                                            />
                                          </div>
                                          <div className="col-md-5 col-6 ps-0 mt-md-0 mt-2">
                                            <Field
                                              type="text"
                                              id={`adult_guests[${adultGuestIdx}].last_name`}
                                              name={`adult_guests[${adultGuestIdx}].last_name`}
                                              className={`form-control h-form fs-16 text-uppercase ${
                                                errors.adult_guests &&
                                                (
                                                  errors.adult_guests[
                                                    adultGuestIdx
                                                  ] as any
                                                )?.last_name &&
                                                touched.adult_guests &&
                                                (
                                                  touched.adult_guests[
                                                    adultGuestIdx
                                                  ] as any
                                                )?.last_name
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              placeholder="Last Name"
                                            />
                                            <ErrorMessage
                                              name={`adult_guests[${adultGuestIdx}].last_name`}
                                              component={InputErrorMsg}
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                    {values.child_guests.map(
                                      (childGuest, childGuestIdx) => (
                                        <div className="row mb-2 mb-md-3">
                                          <label
                                            htmlFor={`adult_guests[${childGuestIdx}]`}
                                            className="form-label"
                                          >
                                            Child {childGuestIdx + 1}
                                          </label>
                                          <div className="col-md-2 col-12 pe-lg-0 mt-2 mt-md-0">
                                            <Field
                                              as="select"
                                              className={`form-select h-form ${
                                                errors.child_guests &&
                                                (
                                                  errors.child_guests[
                                                    childGuestIdx
                                                  ] as any
                                                )?.salutation &&
                                                touched.child_guests &&
                                                (
                                                  touched.child_guests[
                                                    childGuestIdx
                                                  ] as any
                                                )?.salutation
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              id={`child_guests[${childGuestIdx}].salutation`}
                                              name={`child_guests[${childGuestIdx}].salutation`}
                                              aria-label="Default select example"
                                              style={{
                                                paddingRight: "10px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <option value="0" disabled>
                                                Title
                                              </option>
                                              {childSalutationOptions.map(
                                                (childSalutationOption) => (
                                                  <option
                                                    key={
                                                      childSalutationOption.label
                                                    }
                                                    value={
                                                      childSalutationOption.label
                                                    }
                                                  >
                                                    {
                                                      childSalutationOption.label
                                                    }
                                                  </option>
                                                )
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name={`child_guests[${childGuestIdx}].salutation`}
                                              component={InputErrorMsg}
                                            />
                                          </div>
                                          <div className="col-md-5 col-6 mt-md-0 mt-2">
                                            <Field
                                              type="text"
                                              id={`child_guests[${childGuestIdx}].first_name`}
                                              name={`child_guests[${childGuestIdx}].first_name`}
                                              className={`form-control h-form fs-16 text-uppercase ${
                                                errors.child_guests &&
                                                (
                                                  errors.child_guests[
                                                    childGuestIdx
                                                  ] as any
                                                )?.first_name &&
                                                touched.child_guests &&
                                                (
                                                  touched.child_guests[
                                                    childGuestIdx
                                                  ] as any
                                                )?.first_name
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              placeholder="First Name"
                                            />
                                            <ErrorMessage
                                              name={`child_guests[${childGuestIdx}].first_name`}
                                              component={InputErrorMsg}
                                            />
                                          </div>
                                          <div className="col-md-5 col-6 ps-0 mt-md-0 mt-2">
                                            <Field
                                              type="text"
                                              id={`child_guests[${childGuestIdx}].last_name`}
                                              name={`child_guests[${childGuestIdx}].last_name`}
                                              className={`form-control h-form fs-16 text-uppercase ${
                                                errors.child_guests &&
                                                (
                                                  errors.child_guests[
                                                    childGuestIdx
                                                  ] as any
                                                )?.last_name &&
                                                touched.child_guests &&
                                                (
                                                  touched.child_guests[
                                                    childGuestIdx
                                                  ] as any
                                                )?.last_name
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              placeholder="Last Name"
                                            />
                                            <ErrorMessage
                                              name={`child_guests[${childGuestIdx}].last_name`}
                                              component={InputErrorMsg}
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card rounded-2 border-shadow mb-2">
                                <div className="card-header">
                                  <h5 className="mb-0">Contact Details</h5>
                                </div>
                                <div className="card-body px-4">
                                  <div className="row">
                                    <div className="col-md-6 col-6 pe-md-1 pe-1">
                                      <div className="form-floating">
                                        <Field
                                          type="email"
                                          id="receiver_email"
                                          name="receiver_email"
                                          className={`form-control ${
                                            errors.receiver_email &&
                                            touched.receiver_email
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          placeholder="Enter receiver's email"
                                        />
                                        <ErrorMessage
                                          name="receiver_email"
                                          component={InputErrorMsg}
                                        />
                                        <label htmlFor="receiver_email">
                                          Email Address
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-6 ps-md-1 ps-1">
                                      <div className="form-floating">
                                        <Field
                                          type="text"
                                          id="receiver_mobile"
                                          name="receiver_mobile"
                                          className={`form-control ${
                                            errors.receiver_mobile &&
                                            touched.receiver_mobile
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                          ) => {
                                            let value = sanitizePhoneNumber(
                                              e.target.value
                                            );
                                            setFieldValue(
                                              "receiver_mobile",
                                              value
                                            );
                                          }}
                                          placeholder="Enter receiver's mobile number"
                                          maxLength={11}
                                        />
                                        <ErrorMessage
                                          name="receiver_mobile"
                                          component={InputErrorMsg}
                                        />
                                        <label htmlFor="mobile">
                                          Mobile Number
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      className="col-md-12 mt-2"
                                      style={{ textAlign: "center" }}
                                    >
                                      <p className="p-md-1 mb-0 bg-soft-warning rounded-3 p-2 fs-13">
                                        Your booking confirmation voucher will
                                        be sent to this email address and mobile
                                        number.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card rounded-2 border-shadow mb-2">
                                <div className="card-header">
                                  <h5 className="mb-0">Pricing Details</h5>
                                </div>
                                <div className="card-body px-4">
                                  <div className="row">
                                    <div className="col-lg-2 ">
                                      <div className="form-floating">
                                        <Field
                                          as="select"
                                          className={`form-select p-3 fs-15 mb-1 ${
                                            errors.vendor_id &&
                                            touched.vendor_id
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="vendor_id"
                                          name="vendor_id"
                                          aria-label=".form-select-lg example"
                                          style={{
                                            paddingRight: "10px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {/* Default option */}
                                          <option value="0" disabled>
                                            Select Provider
                                          </option>

                                          {/* Vendor options */}
                                          {vendorOptions.map((vendorOption) => (
                                            <option
                                              key={vendorOption.label}
                                              value={vendorOption.value}
                                            >
                                              {vendorOption.label}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="vendor_id"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-6 ps-md-0">
                                      <div className="form-floating">
                                        <Field
                                          type="text"
                                          id="base_price"
                                          name="base_price"
                                          className={`form-control fs-16 ${
                                            errors.base_price &&
                                            touched.base_price
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                          ) => {
                                            let value = sanitizePriceInput(
                                              e.target.value
                                            );
                                            setFieldValue("base_price", value);
                                          }}
                                          placeholder="Enter the base price"
                                        />
                                        <label htmlFor="base_price">
                                          Base Price
                                        </label>
                                      </div>
                                      <ErrorMessage
                                        name="base_price"
                                        component={InputErrorMsg}
                                      />
                                    </div>
                                    <div className="col-lg-3 col-6 ps-0 mb-md-0">
                                      <div className="form-floating">
                                        <Field
                                          type="text"
                                          id="fees_and_taxes"
                                          name="fees_and_taxes"
                                          className={`form-control fs-16 ${
                                            errors.fees_and_taxes &&
                                            touched.fees_and_taxes
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                          ) => {
                                            let value = sanitizePriceInput(
                                              e.target.value
                                            );
                                            setFieldValue(
                                              "fees_and_taxes",
                                              value
                                            );
                                          }}
                                          placeholder="Enter the fees and taxes"
                                        />
                                        <ErrorMessage
                                          name="fees_and_taxes"
                                          component={InputErrorMsg}
                                        />
                                        <label htmlFor="lastnamefloatingInput">
                                          Fees and Taxes
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-12 ps-md-0 mb-md-0 mt-1 mt-lg-0">
                                      <div className="form-floating">
                                        <Field
                                          type="text"
                                          id="selling_price"
                                          name="selling_price"
                                          className={`form-control fs-16 ${
                                            errors.selling_price &&
                                            touched.selling_price
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                          ) => {
                                            let value = sanitizePriceInput(
                                              e.target.value
                                            );
                                            setFieldValue(
                                              "selling_price",
                                              value
                                            );
                                          }}
                                          placeholder="Enter the selling price"
                                        />
                                        <ErrorMessage
                                          name="selling_price"
                                          component={InputErrorMsg}
                                        />
                                        <label htmlFor="selling_price">
                                          Selling Price
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mb-2">
                                    <div className="col-md-12 mt-2 text-center">
                                      <p className="p-md-1 mb-0 bg-soft-warning rounded-3 p-2 fs-13">
                                        Please ensure that you provide the exact
                                        amount in this section for accurate
                                        recording of your transaction.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row mt-2">
                                    <div className="col-md-9"></div>
                                    <div className="col-md-3 text-end">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          handleSaveDetails(values);
                                        }}
                                        className="btn btn-success btn-sm w-100 rounded-5"
                                      >
                                        Save details
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 order-2 order-md-2">
                              <HotelSidebar
                                isDirectHotel={false}
                                checkInDateTime={formData?.check_in_datetime}
                                checkOutDateTime={formData?.check_out_datetime}
                                hotelName={formData?.hotel_name}
                                hotelRating={formData?.hotel_rating}
                                hotelAddress={formData?.hotel_address}
                                roomType={formData?.room_type}
                                roomInclusions={formData?.room_inclusions}
                                totalRooms={formData?.total_rooms}
                                adultGuests={formData?.adult_guests}
                                childGuests={formData?.child_guests}
                                sellingPrice={formData?.selling_price}
                                feesAndTaxes={formData?.fees_and_taxes}
                                formData={formData}
                                errors={errors}
                                isSubmitting={isSubmitting}
                                setSubmitting={setSubmitting}
                                setFieldValue={setFieldValue}
                                setFieldError={setFieldError}
                                setFieldTouched={setFieldTouched}
                              />
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>

          <ResponseModal
            showModal={isResponseModalOpen}
            handleClose={() => setIsResponseModalOpen(false)}
            message={message}
            isSuccess={isSuccess}
          />
        </>
      )}
    </div>
  );
};

export default CreateEVoucher;
