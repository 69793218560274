import moment from "moment";
import { calculateTotalNights, currencyFormat } from "../../utils/helpers";
import HotelImageUpload from "../../utils/HotelImageUpload";
import { OHotelFormData, Select2Default } from "../../interface/DataInterface";
import { FormikErrors } from "formik";
import { GuestDetail, HotelSearchDetails } from "../../types/Types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

interface HotelSidebarInterface {
  isDirectHotel: boolean;
  checkInDateTime: string | undefined;
  checkOutDateTime: string | undefined;
  hotelName: string | undefined;
  hotelImage?: string | undefined;
  hotelRating: string | number | undefined;
  hotelAddress: string | undefined;
  roomType: string | undefined;
  roomInclusions?: Select2Default[] | undefined;
  directRoomInclusions?: string | undefined;
  totalRooms: number | undefined;
  adultGuests: GuestDetail[] | undefined;
  childGuests: GuestDetail[] | undefined;
  sellingPrice: number | undefined;
  feesAndTaxes: number | undefined;
  isCancellationPolicyAgreed?: boolean;
  showBookingConfirmationModal?: (values: any) => Promise<void> | undefined;
  formData?: OHotelFormData | null;
  errors?: FormikErrors<OHotelFormData>;
  directValues?: HotelSearchDetails;
  directErrors?: FormikErrors<HotelSearchDetails>;
  isSubmitting: boolean;
  setSubmitting: (field: any) => void;
  setFieldValue: (field: string, value: any) => void;
  setFieldError: (field: string, message: any) => void;
  setFieldTouched: (field: string, isTouched?: boolean) => void;
}

const HotelSidebar: React.FC<HotelSidebarInterface> = ({
  isDirectHotel,
  checkInDateTime,
  checkOutDateTime,
  hotelName,
  hotelImage,
  hotelRating,
  hotelAddress,
  roomType,
  roomInclusions,
  directRoomInclusions,
  totalRooms,
  adultGuests,
  childGuests,
  sellingPrice,
  feesAndTaxes,
  isCancellationPolicyAgreed,
  showBookingConfirmationModal,
  formData,
  directValues,
  errors,
  isSubmitting,
  setSubmitting,
  setFieldValue,
  setFieldError,
  setFieldTouched,
}) => {
  const getBasePriceWithMarkup = () => {
    const basePriceWithMarkup =
      Number(sellingPrice ?? 0) - Number(feesAndTaxes ?? 0);
    return currencyFormat(basePriceWithMarkup, false);
  };

  return (
    <div>
      <div className="card rounded-3 shadow-none border-shadow mb-1">
        <div className="card-body py-1">
          <div className="row p-2">
            <div className="col-6 col-lg-5 border-end border-3">
              <div className="fs-11 text-muted">Check-In</div>
              <div className="fs-14 fw-semibold">
                {checkInDateTime
                  ? moment(checkInDateTime).format("ddd, MMM DD YYYY")
                  : "mm/day/year"}
              </div>
              <div>
                <span className="text-muted">After: </span>
                <span className="fw-semibold fs-13">
                  {checkInDateTime
                    ? moment(checkInDateTime).format("hh:mm A")
                    : "00:00 PM"}
                </span>
              </div>
            </div>
            <div className="col-6 col-lg-5">
              <div className="fs-11 text-muted">Check-Out</div>
              <div className="fs-14 fw-semibold">
                {checkOutDateTime
                  ? moment(checkOutDateTime).format("ddd, MMM DD YYYY")
                  : "mm/day/year"}
              </div>
              <div>
                <span className="text-muted">Before: </span>
                <span className="fw-semibold fs-13">
                  {checkOutDateTime
                    ? moment(checkOutDateTime).format("hh:mm A")
                    : "00:00 PM"}
                </span>
              </div>
            </div>
            <div className="col-2 text-center bg-soft-dark rounded-3 d-md-block d-none">
              <div className="fs-14 mt-2 fw-semibold">
                {moment(checkOutDateTime).isValid() &&
                moment(checkInDateTime).isValid()
                  ? calculateTotalNights(checkInDateTime, checkOutDateTime)
                  : 0}
              </div>
              <div className="fs-12 position-absolute" style={{ left: "9px" }}>
                night(s)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card rounded-3 shadow-none border-shadow mb-2">
        <div className="card-body">
          {isDirectHotel && (
            <img
              src={hotelImage}
              alt="Hotel preview"
              className="preview-image"
              loading="lazy"
            />
          )}
          {!isDirectHotel && (
            <HotelImageUpload
              name="hotel_image"
              error={errors?.hotel_image ?? ""}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
            />
          )}

          <div className="mt-2">
            <div className="row align-items-center">
              <div className="col">
                <div
                  className="fs-15 fw-semibold text-truncate"
                  style={{ minWidth: "110%" }}
                >
                  {hotelName ?? "Hotel Name..."}
                </div>
              </div>
              <div className="col-4 text-end">
                {Array.from({ length: 5 }).map((_, index) => (
                  <i
                    key={index}
                    className={
                      index < Number(hotelRating)
                        ? "ri-star-s-fill fs-18 text-warning"
                        : "ri-star-line fs-17 text-muted"
                    }
                  ></i>
                ))}
              </div>
            </div>
            <div>
              <div className="fs-13">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="text-warning"
                />{" "}
                {hotelAddress ?? "Hotel Address..."}
              </div>
            </div>
            <div className="mt-2">
              <div className="fs-14 fw-semibold">
                {roomType ?? "Room type view"}
              </div>
              <div className="d-flex gap-1 w-100 mt-1">
                {isDirectHotel ? (
                  <div className="px-1 border border-1 rounded-1 text-bg-light fs-12">
                    {directRoomInclusions}
                  </div>
                ) : (
                  <>
                    {/* Only check roomInclusions once */}
                    {(roomInclusions ?? []).length > 0 ? (
                      <>
                        {/* Map over the roomInclusions, slicing the first 5 */}
                        {(roomInclusions ?? [])
                          .slice(0, 5)
                          .map((roomInclusion, roomInclusionIdx) => (
                            <div
                              key={roomInclusionIdx}
                              className="px-1 border border-1 rounded-1 text-bg-light fs-12"
                            >
                              {roomInclusion.label}
                            </div>
                          ))}

                        {/* If there are more than 5 room inclusions, show the "more" text */}
                        {(roomInclusions ?? []).length > 5 && (
                          <div className="px-1 border border-1 rounded-1 text-bg-light fs-12">
                            +{(roomInclusions ?? []).length - 5} more
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="text-muted mt-1 fs-13">
                        Room Inclusion
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <hr />
            <div className="row mt-2">
              <div className="col-4 text-center">
                <div>Room(s)</div>
                <div className="fw-semibold">{totalRooms ?? 0}</div>
              </div>
              <div className="col-4 text-center">
                <div>Adult(s)</div>
                <div className="fw-semibold">
                  {(adultGuests ?? []).length ?? 0}
                </div>
              </div>
              <div className="col-4 text-center">
                <div>Child(ren)</div>
                <div className="fw-semibold">
                  {(childGuests ?? []).length ?? 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card rounded-3 shadow-none border-shadow mb-2">
        <div className="card-body">
          <div className="d-flex text-center pb-1 mb-2 border-bottom">
            <div className="col-5 text-start mb-2">
              <div className="fs-6 fw-semibold side-line">Payment info</div>
            </div>
            <div className="col text-end">
              <div className="fs-6 fw-semibold">Price</div>
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="col-5 text-start fs-14">
              {moment(checkOutDateTime).isValid() &&
              moment(checkInDateTime).isValid()
                ? calculateTotalNights(checkInDateTime, checkOutDateTime)
                : 0}{" "}
              {"night(s)"}
            </div>
            <div className="col text-end fs-14 fw-normal">
              {getBasePriceWithMarkup()}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="col-5 text-start fs-14">Fees and Taxes</div>
            <div className="col text-end fs-14 fw-normal">
              {currencyFormat(feesAndTaxes ?? 0, false)}
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="col-5 text-start fs-14">Room(s)/Guest(s)</div>
            <div className="col text-end fs-14 fw-normal">
              {totalRooms ?? 0} /{" "}
              {(adultGuests ?? []).length + (childGuests ?? []).length} Pax
            </div>
          </div>
          <hr />
          <div className="d-flex mt-2">
            <div className="col-5 text-start">
              <div className="fs-5 fw-bold">Total Cost</div>
            </div>
            <div className="col text-end">
              <div className="fs-5 fw-bold text-danger">
                {currencyFormat(sellingPrice ?? 0)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3">
        {!isDirectHotel && (
          <button
            type="submit"
            disabled={isSubmitting || formData === null}
            className="btn btn-success waves-effect waves-light rounded-5 w-100 fs-14"
          >
            Book Now!
          </button>
        )}
      </div>

      <div className="card bg-info border-bottom">
        <div className="card-body">
          <div className="bell_icon">
            <i className="bx bx-bell"></i>
          </div>
          <h5 className="text-white">Note:</h5>
          <div className="text-white">
            Double-check the details before generating the itinerary. Once the
            ticket is generated, your credits will be deducted, and the ticket
            cannot be edited.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelSidebar;
