import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "../../css/HotelImageUploadCss.css";
import { ErrorMessage } from "formik";
import InputErrorMsg from "./InputErrorMsg";

interface HotelImageUploadProps {
  name: string;
  error: string;
  setFieldTouched: (field: string, isTouched?: boolean) => void;
  setFieldValue: (field: string, value: any) => void;
  setFieldError: (field: string, error: any) => void;
}

const HotelImageUpload: React.FC<HotelImageUploadProps> = ({
  name,
  error,
  setFieldTouched,
  setFieldValue,
  setFieldError,
}) => {
  const [preview, setPreview] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      // Cleanup preview URL when component unmounts
      if (preview && preview.startsWith("blob:")) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (!file) return;

      try {
        setIsLoading(true);
        setFieldValue(name, file);
        // Mark the field as touched to ensure errors are displayed
        setFieldTouched(name, true);

        // Create new preview
        if (preview && preview.startsWith("blob:")) {
          URL.revokeObjectURL(preview);
        }

        const objectUrl = URL.createObjectURL(file);
        setPreview(objectUrl);
        setFieldValue(name, file);
      } catch (err) {
        const errorMsg = "An error occurred while processing the image.";
        setFieldError(name, errorMsg);
      } finally {
        setIsLoading(false);
      }
    },
    [name, preview, setFieldTouched, setFieldError, setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    disabled: isLoading,
    maxFiles: 1,
    multiple: false,
  });

  const removeBanner = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (preview && preview.startsWith("blob:")) {
      URL.revokeObjectURL(preview);
    }
    setPreview("");
    setFieldValue(name, null);
    setFieldError(name, "");
  };

  return (
    <div className="banner-upload-container">
      <div
        {...getRootProps()}
        className={`dropzone-area ${isDragActive ? "drag-active" : ""} 
          ${error ? "has-error" : ""} 
          ${isLoading ? "is-loading" : ""}`}
        style={{
          border: preview ? "none" : "2px dashed #dee2e6",
          padding: "0.1rem !important",
        }}
      >
        <input {...getInputProps()} aria-label="Upload banner image" />

        {isLoading ? (
          <div className="loading-indicator">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p>Processing image...</p>
          </div>
        ) : preview ? (
          <div className="preview-wrapper">
            <img
              src={preview}
              alt="Hotel preview"
              className="preview-image"
              loading="lazy"
            />
            <button
              type="button"
              className="remove-button"
              onClick={removeBanner}
              aria-label="Remove banner"
            >
              <i className="ri-delete-bin-line"></i>
            </button>
          </div>
        ) : (
          <div className="dropzone-content">
            <i className="ri-upload-cloud-2-line dropzone-icon"></i>
            <p className="dropzone-text fs-14 mb-2 px-3">
              {isDragActive
                ? "Drop your image here..."
                : "Drag and drop the image here, or click to select"}
            </p>
          </div>
        )}
      </div>

      <ErrorMessage name="hotel_image" component={InputErrorMsg} />
    </div>
  );
};

export default HotelImageUpload;
