import React, { useEffect, useState, KeyboardEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Search } from "lucide-react";
import Logout from "./Logout";
import {
  updateUserCredits,
  updateCreditSummary,
  setCategories,
  setCurrentUser,
} from "../redux/actions";
import { Channel } from "../utils/Channel";
import SocketService from "../utils/SocketService";
import { LazyImage } from "../utils/helpers";
import TermsCondition from "../modal/TermsCondition";
import { logout, setLoader } from "../redux/actions";
import "../../css/PageTopbarCss.css";
import { globalSearch } from "../request/Search";
import GlobalSearchResult from "../modal/GlobalSearchResult";
import type { GlobalSearchResults } from "../types/GlobalSearchType";
import Loader from "../utils/Loader";

const PageTopbar: React.FC = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: any) => state);
  const [terms, setTerms] = useState(
    currentUser ? !currentUser.term_condition : true
  );
  const [searchValue, setSearchValue] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [gSearchResults, setgSearchResult] = useState<GlobalSearchResults>([]);
  const [showgSearchModal, setShowgSearchModal] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "testing") {
      const socket = new SocketService(currentUser?.company?.id);
      socket.connect();

      socket.on(
        `${Channel.COMPANY}${currentUser?.company?.id}`,
        (data: any) => {
          dispatch(updateUserCredits(data));
        }
      );

      socket.on(`${Channel.ADMIN_CREDIT_SUMMARY}`, (data: any) => {
        dispatch(updateCreditSummary(data));
      });

      socket.on(
        `${Channel.CATEGORY_LIST}${currentUser?.company?.id}`,
        (data: any) => {
          dispatch(setCategories(data));
        }
      );

      socket.on(
        `${Channel.CURRENT_FUNDS}${currentUser?.company?.id}`,
        (data: any) => {
          const userD = data?.data !== undefined ? data.data : data;
          if (userD) {
            dispatch(setCurrentUser(userD));
          }
        }
      );

      socket.on(`${Channel.FORCE_LOGOUT}`, (data: any) => {
        dispatch(logout());
        dispatch(setLoader(false));
      });

      socket.on(
        `${Channel.FORCE_LOGOUT}:${currentUser?.company?.id}`,
        (data: any) => {
          console.log(`Logout: ${currentUser?.company?.id}`);
          dispatch(logout());
          dispatch(setLoader(false));
        }
      );
    }
  }, [currentUser, dispatch]);

  const handleCloseModal = () => {
    setTerms(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      triggerSearch();
    }
  };

  const triggerSearch = async () => {
    if (searchValue.trim()) {
      dispatch(setLoader(true));
      await globalSearch(dispatch, { search: searchValue.trim() })
        .then((res: any) => {
          if (res?.result.success) {
            setgSearchResult(res?.result?.data);
          }
          setShowgSearchModal(true);
          dispatch(setLoader(false));
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    }
  };

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          {/* Left Section */}
          <div className="d-flex align-items-center">
            <GlobalSearchResult
              showModal={showgSearchModal}
              handleClose={() => setShowgSearchModal(false)}
              data={gSearchResults}
            />
            <TermsCondition showModal={terms} handleClose={handleCloseModal} />
            {/* LOGO */}
            <div className="navbar-brand-box horizontal-logo">
              <a href="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    src="https://cdn.mywanderlinx.com/assets/images/mjc-logo.png"
                    alt="Logo"
                    height="22"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src="https://cdn.mywanderlinx.com/assets/images/mjc-logo.png"
                    alt=""
                    height="17"
                  />
                </span>
              </a>

              <a href="/" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src="https://cdn.mywanderlinx.com/assets/images/mjc-logo.png"
                    alt=""
                    height="22"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src="https://cdn.mywanderlinx.com/assets/images/mjc-logo.png"
                    alt=""
                    height="17"
                  />
                </span>
              </a>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
              id="topnav-hamburger-icon"
            >
              <span className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>

          {/* Center Search - Desktop */}
          {currentUser?.roles === "Admin" && (
            <div className="d-none d-md-flex flex-grow-1 justify-content-center px-4">
              <div className="search-box">
                <div className="search-container">
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                  />
                  <button
                    className="search-button"
                    onClick={triggerSearch}
                    title="Search"
                  >
                    <Search className="search-icon" />
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Right Section */}
          <div className="d-flex align-items-center">
            {/* Search Icon - Mobile */}
            <div className="d-md-none me-2">
              <button className="mobile-search-toggle" onClick={toggleSearch}>
                <Search className="search-icon-mobile" />
              </button>
            </div>

            <span className="d-block d-md-none fs-12 text-muted user-name-sub-text">
              Account ID: <br /> {currentUser?.account_id ?? 0}
            </span>
            <div className="dropdown ms-sm-3 header-item topbar-user pl-1">
              <span className="d-flex align-items-center ml-2">
                <LazyImage
                  src={currentUser?.profile}
                  alt={`Profile picture of ${
                    currentUser?.fname + " " + currentUser?.lname
                  }`}
                  imgSrc={currentUser?.profile}
                  className="rounded-circle header-profile-user"
                />
                <span className="text-start ms-xl-2">
                  <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                    {currentUser?.fname + " " + currentUser?.lname}
                  </span>
                  <span className="d-none d-md-block d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                    {`Account ID: ${currentUser?.account_id ?? 0}`}
                  </span>
                </span>
              </span>
              <Logout />
            </div>
          </div>
        </div>

        {/* Mobile Search Input */}
        {currentUser?.roles === "Admin" && isSearchVisible && (
          <div className="mobile-search-container">
            <div className="position-relative">
              <input
                type="text"
                className="mobile-search-input"
                placeholder="Search..."
                value={searchValue}
                onChange={handleSearch}
                onKeyDown={handleKeyDown}
              />
              <button
                className="mobile-search-button"
                onClick={triggerSearch}
                title="Search"
              >
                <Search className="search-icon-mobile" />
              </button>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default PageTopbar;
