import React from "react";
import { BallTriangle } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

const Loader: React.FC = () => {
  const data = useSelector((state: any) => state);

  return (
    <Modal
      size="sm"
      show={data.loader.show}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-loader"
    >
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" role="status" className="color-0ab39c" />
      </Modal.Body>
    </Modal>
  );
};

export const BallTriangleLoader: React.FC = () => {
  const data = useSelector((state: any) => state);

  return (
    <Modal
      size="sm"
      show={data.loader.show}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-loader"
    >
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#0ab39c"
          ariaLabel="ball-triangle-loading"
          visible={true}
        />
      </Modal.Body>
    </Modal>
  );
};

export default Loader;
