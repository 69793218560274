import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  faStar,
  faStar as faEmptyStar,
} from "@fortawesome/free-solid-svg-icons";

interface HotelFilterListProps {
  title: string;
  items: string[];
  setSelectedItems: (field: any) => any;
  isRating?: boolean;
}

const HotelFilterList: React.FC<HotelFilterListProps> = ({
  title,
  items,
  setSelectedItems,
  isRating = false,
}) => {
  const [showAll, setShowAll] = useState(false); // State to toggle "Show All"

  // Limit for initial display
  const limit = 6;

  // Max stars
  const maxStars = 5;

  // Determine which items to show based on the state
  const visibleLists = showAll
    ? Object.entries(items)
    : Object.entries(items).slice(0, limit);

  return (
    <div className="accordion accordion-flush filter-accordion">
      <div className="card-body border-bottom">
        <div>
          <p className="text-muted text-uppercase fs-12 fw-medium mb-2">
            {title}
          </p>
          <ul className="list-unstyled mb-0 filter-list">
            {!isRating &&
              visibleLists.map(([item, count]) => (
                <li key={item}>
                  <div className="d-flex py-1 align-items-center">
                    <div className="flex-grow-1 ckd-btt">
                      <input
                        onClick={() => {
                          setSelectedItems((prevSelected: string[]) => {
                            let updatedItems;

                            // Check if the item is already selected
                            if (prevSelected.includes(item)) {
                              // If already selected, remove it
                              updatedItems = prevSelected.filter(
                                (a) => a !== item
                              );
                            } else {
                              // If not selected, add it
                              updatedItems = [...prevSelected, item];
                            }

                            return updatedItems;
                          });
                        }}
                        className="form-check-input mt-0"
                        type="checkbox"
                        id={`formCheck-${item}`}
                      />
                      <h5 className="fs-13 mb-0 listname">{item}</h5>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <span className="badge bg-light text-muted">{count}</span>
                    </div>
                  </div>
                </li>
              ))}
            {isRating &&
              Object.entries(visibleLists) // Convert object to array of key-value pairs
                .sort((a, b) => {
                  return parseInt(b[0]) - parseInt(a[0]); // Sort descending by numeric star value
                })
                .map(([label, [newLabel, count]], index) => {
                  return (
                    <li key={`star-rating-${label}`}>
                      <div className="d-flex py-1 align-items-center">
                        <div className="flex-grow-1 ckd-btt">
                          <input
                            onClick={() => {
                              setSelectedItems((prevSelected: string[]) => {
                                let updatedItems;

                                // Check if the item is already selected
                                if (prevSelected.includes(label)) {
                                  // If already selected, remove it
                                  updatedItems = prevSelected.filter(
                                    (a) => a !== label
                                  );
                                } else {
                                  // If not selected, add it
                                  updatedItems = [...prevSelected, label];
                                }

                                return updatedItems;
                              });
                            }}
                            className="form-check-input mt-0"
                            type="checkbox"
                            id={`star-rating-formCheck-${label}`}
                          />
                          <div
                            key={`star-row-${index}`}
                            className="d-flex align-items-center"
                          >
                            {/* Render stars */}
                            {[...Array(parseInt(label.split(" ")[0], 10))].map(
                              (_, starIndex) => (
                                <FontAwesomeIcon
                                  key={`star-${index}-${starIndex}`}
                                  icon={faStar}
                                  className="text-warning px-1"
                                  size="sm"
                                />
                              )
                            )}
                            {[
                              ...Array(
                                maxStars - parseInt(label.split(" ")[0], 10)
                              ),
                            ].map((_, starIndex) => (
                              <FontAwesomeIcon
                                key={`empty-star-${index}-${starIndex}`}
                                icon={faEmptyStar}
                                className="text-muted px-1"
                                size="sm"
                              />
                            ))}
                          </div>
                        </div>
                        <div className="flex-shrink-0 ms-2">
                          <span className="badge bg-light text-muted">
                            {count}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
          </ul>

          {/* "Show All" or "Show Less" toggle */}
          {Object.entries(items).length > limit && (
            <div>
              <div
                className="text-secondary mt-2 fw-medium"
                onClick={() => setShowAll(!showAll)}
                style={{ cursor: "pointer" }}
              >
                {showAll
                  ? "Show Less"
                  : `Show All (${Object.entries(items).length})`}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HotelFilterList;
