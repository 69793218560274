interface HotelLoadingModalProps {
  showModal: boolean;
}

const HotelLoadingModal: React.FC<HotelLoadingModalProps> = ({ showModal }) => {
  return (
    <div
      className={`modal fade ${showModal ? "show" : ""}`}
      tabIndex={-1}
      aria-labelledby="mySmallModalLabel"
      style={
        showModal
          ? {
              display: "block",
              zIndex: 1040,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }
          : { display: "none" }
      }
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-2">
            <h2 className="fs- text-center mt-3">Loading Your Perfect Stay!</h2>
            <p className="text-center fs-15">
              This might take a few seconds. Your next adventure awaits!
            </p>
            <div className="container mb-2 card mb-2">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="row py-2 ps-0 align-items-center">
                    <div className="col-lg-9 col-8 border-2 border-end">
                      <div className="placeholder-glow">
                        <div className="placeholder placeholder-lg w-25 p-2 rounded-0"></div>
                      </div>
                      <div className="placeholder-glow">
                        <div className="placeholder placeholder-lg w-50 h-75 rounded-0"></div>
                      </div>
                      <div className="placeholder-glow">
                        <div className="placeholder placeholder-lg w-75 h-75 rounded-0"></div>
                      </div>
                      <div className="mt-1">
                        <div className="mb-2 placeholder-glow">
                          <div className="placeholder placeholder-lg w-25 h-75 rounded-0 p-2"></div>
                        </div>
                        <div className="d-flex gap-1 placeholder-glow">
                          <div className="placeholder placeholder-lg w-25"></div>
                          <div className="placeholder placeholder-lg w-25"></div>
                          <div className="placeholder placeholder-lg w-25"></div>
                          <div className="placeholder placeholder-lg w-25"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-4 text-end">
                      <div className="d-inline">
                        <div className="placeholder-glow">
                          <div className="placeholder w-50 rounded-0"></div>
                        </div>
                        <div className="my-2 placeholder-glow">
                          <div className="placeholder p-2 w-75 rounded-0"></div>
                        </div>
                        <div className="placeholder-glow mb-1">
                          <div className="placeholder w-50 p-1 rounded-0"></div>
                        </div>
                      </div>
                      <div>
                        <div className="placeholder-glow">
                          <div className="placeholder p-3 w-100 rounded-0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelLoadingModal;
