import createAxiosInstance from "./Axios";
import { setNewAuthToken } from "./ApiHelperFunctions";
import { Logger } from "../utils/Logger";
import { logout } from "../redux/actions";

export const globalSearch = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/search", {
      params: data,
    });
    Logger.info("Search Results: ", response.data);
    if (dispatch) {
      setNewAuthToken(dispatch, response.data.result?.access_token);
    }
    return response.data;
  } catch (err: any) {
    Logger.error("Search Results: ", err);
    if (err?.response?.status === 401 && dispatch) {
      dispatch(logout());
    }
    throw err;
  }
};
