import React, { FC, useState, useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { MdCircleNotifications } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { setToasterMessage } from '../redux/actions';
import { toasterInitialState } from '../redux/reducer';

export const ToasterMessage: FC = () => {
  const dispatch = useDispatch();
  const { toaster } = useSelector((state: any) => state);
  const [show, setShow] = useState(false);
  const [className, setClassName] = useState('toast-fade-enter');

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (toaster.isShow) {
      setShow(true);
      setClassName('toast-fade-enter-active');
      timer = setTimeout(() => {
        setClassName('toast-fade-exit');
        setTimeout(() => {
          setShow(false);
          setClassName('toast-fade-exit-active');
          dispatch(setToasterMessage(toasterInitialState));
        }, 300);
      }, toaster.delay);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [toaster, dispatch]);

  return (
    <ToastContainer position="top-end" className="p-3" style={{ position: 'fixed', zIndex: 9999, top: 0, right: 0 }}>
      {show && (
        <Toast onClose={() => setShow(false)} className={className} show={show} bg={toaster.type}>
          <Toast.Header>
            <MdCircleNotifications />
            <strong className="me-auto">{ toaster.header }</strong>
          </Toast.Header>
          <Toast.Body className="text-white">{ toaster.message }</Toast.Body>
        </Toast>
      )}
    </ToastContainer>
  );
};
