import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";

const Footer: React.FC = () => {
  const scripts = useMemo(
    () => [
      "https://cdn.mywanderlinx.com/assets/libs/bootstrap/js/bootstrap.bundle.min.js",
      "https://cdn.mywanderlinx.com/assets/libs/simplebar/simplebar.min.js",
      "https://cdn.mywanderlinx.com/assets/libs/node-waves/waves.min.js",
      "https://cdn.mywanderlinx.com/assets/libs/feather-icons/feather.min.js",
      "https://cdn.mywanderlinx.com/assets/js/pages/plugins/lord-icon-2.1.0.js",
      "https://cdn.mywanderlinx.com/assets/js/plugins.js",
      "https://cdn.mywanderlinx.com/assets/js/appNavBar.js",
    ],
    []
  );

  useEffect(() => {
    const loadedScripts = new Set<string>();

    const loadScript = (src: string) => {
      return new Promise<void>((resolve, reject) => {
        // If script is already loaded, don't load it again
        if (
          loadedScripts.has(src) ||
          document.querySelector(`script[src="${src}"]`)
        ) {
          resolve();
          return;
        }

        const script = document.createElement("script");
        script.src = src;
        script.async = true;

        script.onload = () => {
          loadedScripts.add(src);
          resolve();
        };

        script.onerror = () => {
          script.remove();
          reject(new Error(`Failed to load script: ${src}`));
        };

        document.body.appendChild(script);
      });
    };

    const loadAllScripts = async () => {
      try {
        // Load scripts sequentially to maintain order
        for (const src of scripts) {
          await loadScript(src);
        }
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    };

    loadAllScripts();

    // No cleanup function - we want scripts to persist
  }, []); // Empty dependency array since we only want to load scripts once

  return (
    <footer>
      <Helmet>
        {scripts.map((src, index) => (
          <link key={index} rel="preload" href={src} as="script" />
        ))}
      </Helmet>
    </footer>
  );
};

export default Footer;
