import axios from "axios";
import { loadAuthToken } from "../redux/store";

const createAxiosInstance = async () => {
  try {
    // Get the authentication token from your Redux store or wherever you store it
    const authToken = await loadAuthToken();

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        "Content-Type": "application/json",
        "App-Version": process.env.REACT_APP_VER,
      },
    });

    // If authToken is available, set it in the Authorization header
    if (authToken) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${authToken}`;
    }

    return axiosInstance;
  } catch (error) {
    console.error("Error while creating axios instance:", error);
    throw error;
  }
};

export const getAirportData = async (iataCode?: string) => {
  const apiKey = "0ooCQGAs5Zj4cQSC4Pe4Nw==dZmECxq4CBJQJgfi"; // anthony.naluz15@gmail.com account
  // const apiKey = "MEWbTGOoOsWEmcOeI6zx1w==7YgaZdB3DncaZh2C"; // aglipaync@gmail.com account
  // const apiKey = "OqeocUnGsaw3TXSr/yzgZg==dYPUVQty4kRgOuLz"; // master@mywanderlinx.com account
  const apiUrl = "https://api.api-ninjas.com/v1/airports";

  const config = {
    headers: {
      "X-Api-Key": apiKey,
    },
  };

  return axios.get(apiUrl, {
    params: {
      iata: iataCode,
    },
    headers: config.headers,
  });
};

export default createAxiosInstance;
