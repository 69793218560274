import React from "react";

interface InputErrorMsgProps {
  children?: string;
}

const InputErrorMsg: React.FC<InputErrorMsgProps> = ({ children }) => {
  return children ? <span className="text-danger">{children}</span> : null;
};

export default InputErrorMsg;
