import { Logger } from "../utils/Logger";
import {
  setAuthToken,
  clearAuthToken,
} from "../redux/actions";

export const setNewAuthToken = async (dispatch: any, token?: string) => {
  if (token) {
    Logger.info("Clearing authorization: ", token);
    dispatch(clearAuthToken());
    Logger.info("Authorization Set: ", token);
    dispatch(setAuthToken(token));
  }
};
