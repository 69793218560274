import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { termsCondition } from "../request/api";
import { logout } from "../redux/actions";
import Modal from "react-bootstrap/Modal";
import { TermsConditionProps } from "../interface/DataInterface";

const TermsCondition: React.FC<TermsConditionProps> = ({
  showModal,
  handleClose,
  isBooking,
}) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: any) => state);
  const [loading, setLoading] = useState<boolean>(false);

  const acceptTermsCondition = async () => {
    setLoading(true);
    await termsCondition(dispatch, (currentUser?.age !== 1 ? currentUser?.company?.id : 0))
      .then((res) => {
        if (res?.result.success) {
          currentUser.term_condition = true;
          setLoading(false);
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const autoLogout = async () => {
    handleClose();
    dispatch(logout());
  };

  return (
    <Modal show={showModal} onHide={handleClose} backdrop="static" size="lg">
      <Modal.Body className="p-5">
        <div className="mt-0">
          <div className="bg-warning-subtle position-relative">
            <div className="card-body p-4">
              <div className="text-center">
                <h3>Member Term &amp; Conditions</h3>
                <p className="mb-0 text">Last update: 01 Jan, 2024</p>
              </div>
              <div className="mt-3">
                <h5 className="fw-bold">Welcome to Wanderlinx!</h5>
                <p className="text">
                  These Terms and Conditions ("Terms") govern your use of
                  WanderLinx's services, including, but not limited to, mistake
                  bookings, membership, and account access. By accessing or
                  using our services, you agree to be bound by these Terms.
                </p>

                <p className="text">
                  Most interactive websites use cookies to let us retrieve the
                  user's details for each visit. Cookies are used by our website
                  to enable the functionality of certain areas to make it easier
                  for people visiting our website. Some of our
                  affiliate/advertising partners may also use cookies.
                </p>
              </div>

              <div>
                <h5 className="fw-bold">User Accounts:</h5>
                <ul className="text vstack gap-2">
                  <li>
                    <h6 className="fw-bold">Account Registration:</h6>
                    <p className="text">
                      Agents must be at least 18 years old to create an account
                      with Wanderlinx. Agents agree to provide accurate,
                      current, and complete information during the registration
                      process and to update such information to keep it
                      accurate, current, and complete.
                    </p>
                  </li>
                  <li>
                    <h6 className="fw-bold">Account Security:</h6>
                    <p className="text">
                      Agents are responsible for maintaining the confidentiality
                      of their own account login information and for all
                      activities that occur under your Account. Agents also
                      agree to notify WanderLinx immediately of any unauthorized
                      use of your Account or any other breach of security.
                    </p>
                  </li>
                  <li>
                    <h6 className="fw-bold">Use of Account:</h6>
                    <p className="text">
                      Accounts created for agents use only. Unauthorized sharing
                      of an agent account to any other person or entity is
                      prohibited as it may compromise the user’s booking history
                      and transactions.Users are solely responsible for
                      maintaining the confidentiality of their account
                      credentials. Each account is protected with unique access
                      credentials assigned to the agent upon account creation.
                    </p>
                  </li>
                  <li>
                    <h6 className="fw-bold">Refund and Transfer Policy:</h6>
                    <p className="text">
                      Once an account is created, it is deemed non-refundable
                      and non-transferable. No refunds shall be issued, and the
                      account cannot be transferred to another agent.
                    </p>
                  </li>
                </ul>
              </div>

              <div>
                <h5 className="fw-bold">Data Privacy:</h5>
                <p className="text">
                  WanderLinx ("we," "us," or "our") is committed to protecting
                  the privacy and security of your personal information. This
                  Privacy Policy describes how we collect, use, and disclose
                  information when you use our services.
                </p>

                <ul className="textvstack gap-2">
                  <li>
                    <h6 className="fw-bold">Personal Data:</h6>
                    <p className="text">
                      WanderLinx respects your privacy and does not sell any
                      personal data of its users to any third party. We collect
                      and process personal data solely for the purpose of
                      providing and improving our services to you. Personal data
                      may include but is not limited to your name, email
                      address, contact information, and usage data.
                    </p>
                  </li>
                  <li>
                    <h6 className="fw-bold">Data Handling:</h6>
                    <p className="text">
                      We handle all personal information in accordance with
                      WanderLinx's Privacy Policy. This includes the collection,
                      processing, storage, and deletion of personal data. We
                      implement appropriate security measures to protect your
                      personal information from unauthorized access, alteration,
                      disclosure, or destruction.
                    </p>
                  </li>
                  <li>
                    <h6 className="fw-bold">Third-Party Services:</h6>
                    <p className="text">
                      WanderLinx may utilize third-party services or integrate
                      with third-party applications to enhance the user
                      experience. Please note that these third-party services
                      may have their own privacy policies and terms of service,
                      and WanderLinx is not responsible for the privacy
                      practices of these third parties.
                    </p>
                  </li>
                  <li>
                    <h6 className="fw-bold">User Consent:</h6>
                    <p className="text">
                      By using WanderLinx, you consent to the collection,
                      processing, and storage of your personal data as outlined
                      in our Privacy Policy. You have the right to withdraw your
                      consent or request the deletion of your personal data at
                      any time by contacting us.
                    </p>
                  </li>
                </ul>
              </div>

              <div>
                <h5 className="fw-bold">Booking Responsibility:</h5>
                <p className="text">
                  WanderLinx acknowledges its responsibility for bookings made
                  directly through its platform or authorized channels. However,
                  WanderLinx shall not be held liable for any inaccuracies or
                  issues arising from bookings made through third-party OTA
                  (Online Travel Agency) providers.
                </p>
                <ul className="text vstack gap-2">
                  <li>
                    <h6 className="fw-bold">Third-Party OTA Bookings:</h6>
                    <p className="text">
                      In cases where an agent utilizes OTA services to make
                      bookings on behalf of the customer, WanderLinx assumes no
                      liability for any discrepancies, inaccuracies, or issues
                      arising from such bookings. The agent acknowledges that
                      bookings made through third-party OTAs are subject to the
                      terms and conditions of the respective OTA provider.
                    </p>
                  </li>
                  <li>
                    <h6 className="fw-bold">Booking Verification:</h6>
                    <p className="text">
                      Agents are encouraged to verify all booking details,
                      including but not limited to dates, accommodations, and
                      activities, regardless of the booking platform used. It is
                      the responsibility of the customer to ensure the accuracy
                      of booking information before confirming the reservation.
                    </p>
                  </li>
                  <li>
                    <h6 className="fw-bold">Dispute Resolution:</h6>
                    <p className="text">
                      In the event of any dispute or discrepancy related to
                      bookings made through third-party OTA providers, travel
                      agents are advised to contact the respective OTA directly
                      for resolution. WanderLinx may provide assistance in the
                      communication process or procedure between agents and the
                      OTA but shall not be held responsible for the final
                      outcome of any dispute.
                    </p>
                  </li>
                  <li>
                    <h6 className="fw-bold">Indemnification:</h6>
                    <p className="text">
                      By using WanderLinx's services, the agent agrees to
                      indemnify and hold WanderLinx, its officers, directors and
                      employees harmless from any claims, damages, losses, or
                      expenses arising out of your use of or inability to use
                      the Website or services, including but not limited to
                      bookings made through third-party OTA providers, including
                      but not limited to disputes, cancellations, or errors in
                      reservation information.
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <h5 className="fw-bold">Membership Termination:</h5>
                <p className="text">
                  WanderLinx reserves the right, in its sole discretion, to
                  terminate agent access to the website in the event of any
                  words or actions by the agent that may potentially harm the
                  company or its reputation.
                </p>
                <p className="text">This includes but is not limited to:</p>
                <ul className="text vstack gap-2">
                  <li>
                    <span className="fw-medium">Misconduct:</span> Any behavior
                    that is deemed inappropriate, offensive, or illegal.
                  </li>
                  <li>
                    <span className="fw-medium">Misrepresentation:</span>
                    Providing false information to WanderLinx or its affiliates.
                  </li>
                  <li>
                    <span className="fw-medium">Abuse:</span> Any form of abuse
                    directed towards WanderLinx staff, members, or partners.
                  </li>
                  <li>
                    <span className="fw-medium">Violation of Policies:</span>
                    Failure to comply with WanderLinx's policies and guidelines
                  </li>
                </ul>
                <p className="text">
                  WanderLinx will notify the member of the termination and
                  provide reasons for the decision. Upon termination, the member
                  will lose access to all benefits and services associated with
                  the membership.
                </p>
              </div>

              <div>
                <h5 className="fw-bold">Disclaimer:</h5>
                <p className="text">
                  Wanderlinx has not made any guarantees about the results of
                  taking any action, whether recommended on this website or not.
                  Wanderlinx provides educational and informational resources
                  that are intended to help users of this website succeed.
                  Agents nevertheless recognize that their ultimate success or
                  failure will be the result of their own efforts, particular
                  situation, and innumerable other circumstances beyond the
                  control and/or knowledge of the company.
                </p>
                <p className="text">
                  You also recognize that prior results do not guarantee a
                  similar outcome. Thus, the results obtained by others -
                  whether agents of Wanderlinx or otherwise - applying the
                  principles set out are no guarantee that you or any other
                  person or entity will be able to obtain similar results
                </p>
              </div>

              <div>
                <h5 className="fw-bold">Changes to Terms:</h5>
                <p className="text">
                  WanderLinx reserves the right to update or modify these Terms
                  & Conditions at any time without prior notice. It is your
                  responsibility to review these terms periodically for any
                  changes. Your continued use of WanderLinx after any
                  modifications indicates your acceptance of the updated Terms &
                  Conditions.
                </p>
              </div>

              <div>
                <h5 className="fw-bold">Contact Us:</h5>
                <p className="text">
                  If you have any questions or concerns about these Terms &
                  Conditions or our Privacy Policy, please contact us at
                  support@mywanderlinx.com. We are committed to protecting your
                  privacy and will address any inquiries promptly.
                </p>
              </div>

              <div>
                <p className="text">
                  By accessing or using WanderLinx services, you agree to abide
                  by these Terms and Conditions. WanderLinx reserves the right
                  to modify or update these terms at any time without prior
                  notice. It is the user's responsibility to review and stay
                  informed of any changes. Failure to comply with these terms
                  may result in the termination of your account and access to
                  WanderLinx services.
                </p>
              </div>
            </div>
          </div>
          <div className="hstack gap-2 justify-content-end">
            {!currentUser?.term_condition && (
              <div className="text-end hstack gap-2 justify-content-end">
                <button
                  onClick={acceptTermsCondition}
                  className="btn btn-success"
                  disabled={loading}
                >
                  {loading ? "loading" : "Accept"}
                </button>
                <button
                  onClick={autoLogout}
                  className="btn btn-outline-danger"
                  disabled={loading}
                >
                  <i className="ri-close-line align-bottom me-1"></i>
                  {loading ? "loading" : "Decline"}
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TermsCondition;
