import { object, string, boolean, number, mixed, date, array, ref } from "yup";
import { parse, isValid as isValidDate } from "date-fns";
import moment from "moment";

const dateFormat = "yyyy-MM-dd";

const removeCommas = (value: string): string => value.replace(/,/g, "");

const isValidNumber = (value: string): boolean => {
  const numberWithoutCommas = removeCommas(value);
  const number = parseFloat(numberWithoutCommas);
  return !isNaN(number) && number >= 0 && number <= 999999999999; // Adjust max value as needed
};

const validateMobile = (val: any) => {
  const regex1 = /^\+639\d{9}$/; // Format: +639190950915
  const regex2 = /^09\d{9}$/; // Format: 09190950915
  return regex1.test(val) || regex2.test(val);
};

const validDate = string()
  .nullable()
  .test("is-valid-date", `Date should be in ${dateFormat} format`, (value) => {
    if (!value) return true; // If you want it to be optional
    const parsedDate = parse(value, dateFormat, new Date());
    return isValidDate(parsedDate);
  })
  .default(() => new Date().toISOString().slice(0, 10));

export const loginSchema = object({
  email: string().email("Must be a valid email").required("Email is required"),
  password: string().required("Password is required"),
  remember_me: boolean(),
});

export const SearchFormSchema = object({
  bookType: string().nullable(),
  adults: number().nullable(),
  originLocationCode: string().required("Origin is required"),
  destinationLocationCode: string().required("Destination is required"),
  departureDate: validDate,
  arrivalDate: validDate,
});

export const DirectSearchFormSchema = object({
  leavingFrom: string().required("Leaving from field is required"),
  goingTo: string().required("Going to field is required"),
  departureDate: validDate,
  arrivalDate: validDate,
});

export const AgentFormSchema = object({
  fname: string().required("First name required"),
  lname: string().required("Last name required"),
  subscription_id: number().required("Subscription field is required"),
  dob: date()
    .required("Date of Birth is required.")
    .test("is-18", "Must be at least 18 years old", (value) => {
      if (!value) {
        return false;
      }
      const today = new Date();
      const birthDate = new Date(value); // your date of birth
      const age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        return age - 1 >= 18;
      }
      return age >= 18;
    }),
  cnumber: string()
    .matches(/^(\+?\d{11,13})$/, "Phone number is not valid")
    .required("Phone number is required"),
  email: string().email("Must be a valid email").required("Email is required"),
  profile_img: mixed()
    .nullable()
    .test(
      "fileSize",
      "File size too large. Maximum size is 5MB.",
      (val) => !val || (val && (val as File).size <= 5242880)
    )
    .test(
      "fileType",
      "Unsupported file format. Only PNG, JPEG, and JPG are allowed.",
      (val) =>
        !val ||
        (val &&
          ["image/png", "image/jpg", "image/jpeg"].includes((val as File).type))
    ),
});

export const AffiliateFormSchema = object({
  fname: string().required("First name required"),
  lname: string().required("Last name required"),
  activation_code: string().required("Activation code field is required"),
  dob: date()
    .required("Date of Birth is required.")
    .test("is-18", "Must be at least 18 years old", (value) => {
      if (!value) {
        return false;
      }
      const today = new Date();
      const birthDate = new Date(value); // your date of birth
      const age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        return age - 1 >= 18;
      }
      return age >= 18;
    }),
  cnumber: string()
    .matches(/^(\+?\d{11,13})$/, "Phone number is not valid")
    .required("Phone number is required"),
  email: string().email("Must be a valid email").required("Email is required"),
  created_by: number().required("Created by is requred"),
});

export const ResenItinerary = object({
  email: string().email("Must be a valid email").required("Email is required"),
});

export const ChangeFlightSchema = object({
  email: string().email("Must be a valid email").required("Email is required"),
  id: string().required("You must select a flight"),
});

// Custom test for URLs and domains
const noDomainOrUrl = {
  name: "noDomainOrUrl",
  test: (value: string | undefined) => {
    if (!value) return true;

    // Check for URLs (http://, https://, www.)
    const urlPattern = /(https?:\/\/|www\.)/i;
    if (urlPattern.test(value)) {
      return false;
    }

    // Check for domain-like patterns (anything.tld)
    const domainPattern = /[a-zA-Z0-9]+\.[a-zA-Z]{2,}/i;
    if (domainPattern.test(value)) {
      return false;
    }

    return true;
  },
  message: "Cannot contain URLs or domain names",
};

export const CompanyFormSchema = object({
  page: string().required("Page is required").trim(),

  dti: string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .trim(),

  agency_name: string()
    .required("Agency Name is required")
    .test(noDomainOrUrl)
    .max(255, "Agency Name must not exceed 255 characters")
    .matches(
      /^[a-zA-Z0-9\s\-\.,'"&()]+$/,
      "Agency Name contains invalid characters"
    )
    .trim(),

  business_adress: string()
    .required("Address is required")
    .test(noDomainOrUrl)
    .max(255, "Address must not exceed 255 characters")
    .matches(
      /^[a-zA-Z0-9\s\-\.,'"#&()]+$/,
      "Address contains invalid characters"
    )
    .trim(),

  city_id: number()
    .required("City Required")
    .positive("Invalid city selection")
    .integer("Invalid city selection"),

  province_id: number()
    .required("Province is required")
    .positive("Invalid province selection")
    .integer("Invalid province selection"),

  zipcode: string()
    .required("Zipcode is required")
    .matches(/^\d+$/, "Zipcode must contain only numbers")
    .min(4, "Zipcode must be at least 4 digits")
    .max(10, "Zipcode must not exceed 10 digits")
    .trim(),
});

export const MarkUpSettingFormSchema = object({
  intl_addons_id: number().required("Add-on filter is required"),
  intl_trav_id: number().required("Traveller filter is required"),
  intl_meal: string()
    .required("International Meal is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { intl_addons_id } = context.parent;
        return intl_addons_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
  intl_baggage: string()
    .required("International Baggage is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { intl_addons_id } = context.parent;
        return intl_addons_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
  intl_adults: string()
    .required("International Adults is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { intl_trav_id } = context.parent;
        return intl_trav_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
  intl_child: string()
    .required("International Child is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { intl_trav_id } = context.parent;
        return intl_trav_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
  intl_infant: string()
    .required("International Infant is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { intl_trav_id } = context.parent;
        return intl_trav_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
  threshold_balance: string(),
  dom_addons_id: number().required("Add-on filter is required"),
  dom_trav_id: number().required("Traveller filter is required"),
  domestic_meal: string()
    .required("Domestic Meal is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { dom_addons_id } = context.parent;
        return dom_addons_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
  domestic_baggage: string()
    .required("Domestic Baggage is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { dom_addons_id } = context.parent;
        return dom_addons_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
  domestic_adults: string()
    .required("Domestic Adults is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { dom_trav_id } = context.parent;
        return dom_trav_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
  domestic_child: string()
    .required("Domestic Child is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { dom_trav_id } = context.parent;
        return dom_trav_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
  domestic_infant: string()
    .required("Domestic Infant is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { dom_trav_id } = context.parent;
        return dom_trav_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
  hotel_markup_id: number().required("Hotel filter is required"),
  domestic_hotel: string()
    .required("Domestic Hotel is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { hotel_markup_id } = context.parent;
        return hotel_markup_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
  intl_hotel: string()
    .required("International Hotel is required")
    .test(
      "is-valid-percentage",
      "Total percentage must not exceed 100%",
      function (value, context) {
        const val = value.replace("%", "");
        const num = parseFloat(val);
        const { hotel_markup_id } = context.parent;
        return hotel_markup_id !== 2 || num <= 0 || (num || 0) <= 100;
      }
    ),
});

const returnFlightFare = JSON.parse(
  localStorage.getItem("returnFlightFare") ?? "[]"
);

export const PassengerDetailsFormSchema = object({
  passenger: array(
    object({
      fname: string().required("First name is required"),
      lname: string().required("Last name is required"),
      pnr: string().required("PNR is required"),
      kg: string()
        .test("conditional-kg", "KG is required", function (kg) {
          const { pieces } = this.parent;
          if (pieces) {
            return !!kg;
          }
          return true;
        })
        .typeError("KG must be a number"),
      pieces: string(),
      return_kg: string()
        .test("conditional-return-kg", "KG is required", function (return_kg) {
          const { return_pieces } = this.parent;
          if (return_pieces) {
            return !!return_kg;
          }
          return true;
        })
        .typeError("KG must be a number"),
      return_pieces: string(),
      return_price: string()
        .test(
          "conditional-return-price",
          "Return price is required",
          function (return_price) {
            const { return_kg, return_pieces } = this.parent;
            if (return_kg || return_pieces) {
              return !!return_price;
            }
            return true;
          }
        )
        .typeError("Return Price must be a number"),
      ...(returnFlightFare.length !== 0 && {
        returned_pnr: string().required("Return PNR is required"),
      }),
      baggage: number(),
      price: string()
        .test("conditional-price", "Price is required", function (price) {
          const { kg, pieces } = this.parent;
          if (kg || pieces) {
            return !!price;
          }
          return true;
        })
        .typeError("Price must be a number"),
    })
  ),
  mobile: string()
    .required("Mobile is required")
    .test("mobile-format", "Invalid mobile number format", validateMobile),
  email: string().email("Must be a valid email").required("Email is required"),
  vendor_id: mixed()
    .test("is-valid-vendor", "Vendor is required", (value) => {
      // Check if the value is either a string or a number
      return typeof value === "string" || typeof value === "number";
    })
    .required("Vendor is required"),
  purchase_price: string()
    .typeError("Purchase price must be a number")
    .required("Purchase price is required"),
  selling_price: string()
    .typeError("Selling price must be a number")
    .required("Selling price is required"),
});

export const passwordChanged = object({
  oldPassword: string()
    .min(8, "Password must be at least 8 characters")
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]+$/,
    //   "Password must contain at least one letter and one number"
    // )
    .required("Old Password is required"),
  password: string()
    .min(8, "Password must be at least 8 characters")
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]+$/,
    //   "Password must contain at least one letter and one number"
    // )
    .required("Password is required"),
  confirmPassword: string()
    .oneOf([ref("password"), undefined], "Passwords must match")
    .required("Confirm Password is required"),
});

export const BillingFormSchema = object({
  credit_id: mixed().required("Credit required field"),
  payment_date: date().required("Date required field"),
  time: string().required("Time required field"),
  reference: string().required("Reference required field"),
  proof_of_payment: mixed()
    .required("Image of the Receipt required field")
    .test(
      "fileSize",
      "File size too large. Maximum size is 5MB.",
      (val) => !val || (val && (val as File).size <= 5242880)
    )
    .test(
      "fileType",
      "Unsupported file format. Only PNG, JPEG, and JPG are allowed.",
      (val) =>
        !val ||
        (val &&
          ["image/png", "image/jpg", "image/jpeg"].includes((val as File).type))
    ),
  mode_payment_id: mixed().required("Payment Option required field"),
});

export const BuyActivationFormSchema = object({
  subscription_id: mixed(),
  payment_date: date().required("Date required field"),
  time: string().required("Time required field"),
  reference: string().required("Reference required field"),
  proof_of_payment: mixed()
    .required("Image of the Receipt required field")
    .test(
      "fileSize",
      "File size too large. Maximum size is 5MB.",
      (val) => !val || (val && (val as File).size <= 5242880)
    )
    .test(
      "fileType",
      "Unsupported file format. Only PNG, JPEG, and JPG are allowed.",
      (val) =>
        !val ||
        (val &&
          ["image/png", "image/jpg", "image/jpeg"].includes((val as File).type))
    ),
  mode_payment_id: mixed().required("Payment Option required field"),
  payment_type_id: number().required("Payment Type required"),
});

export const TrainingFormSchema = object({
  title: string().required("Required field"),
  short_description: string().required("Required field"),
  is_main: number().required("Required field"),
  status: string().required("Required field"),
  parent_id: number(),
  category_id: number().required("Required field"),
  related_ids: mixed(),
  videoName: string(),
  duration: string(),
});

export const TransferForm = object({
  payout: number().required("Required field"),
  agent_id: number(),
  is_mobile: boolean().required("Required field"),
});

export const SubscriptionUpgradeSchema = object({
  code: string().required("Required field"),
});

const flightReview = JSON.parse(
  localStorage.getItem("DirectFlightReview") ?? "[]"
);

export const DirectPassengerDetails = object({
  passenger: array().of(
    object({
      fname: string().required("First name is required"),
      lname: string().required("Last name is required"),
      country_id: string().required("Country is required"),
      salutatation_id: string().required("Salutation is required"),
      day: string().required("Day is required"),
      month: string().required("Month is required"),
      year: string().required("Year is required"),
      ...((flightReview?.domestic === false ||
        flightReview?.isDomestic === false) && {
        passport_code: string().required("Passport Code is required"),
        passport_day: string().required("Passport Day is required"),
        passport_month: string().required("Passport Month is required"),
        passport_year: string().required("Passport Year is required"),
      }),
      ...(flightReview?.senior && {
        senior_doc_type: string(),
        senior_code: string().required("Senior Code is required"),
      }),
      ...(flightReview?.pwd && {
        pwd_code: string().required("PWD Code is required"),
        pwd_file: string(),
      }),
    })
  ),
  mobile: string()
    .required("Mobile is required")
    .test("mobile-format", "Invalid mobile number format", validateMobile),
  email: string().email("Must be a valid email").required("Email is required"),
  termCondition: boolean().required("Term and Conditions must be required"),
});

export const AddFundFormSchema = object({
  payment_date: date().required("Date required field"),
  paid_amount: string()
    .required("Amount is a required field")
    .test(
      "min-amount",
      "Amount must be at least 500",
      (value) => parseFloat(value) >= 500
    ),
  time: string().required("Time required field"),
  reference: string().required("Reference required field"),
  proof_of_payment: mixed()
    .required("Image of the Receipt required field")
    .test(
      "fileSize",
      "File size too large. Maximum size is 5MB.",
      (val) => !val || (val && (val as File).size <= 5242880)
    )
    .test(
      "fileType",
      "Unsupported file format. Only PNG, JPEG, and JPG are allowed.",
      (val) =>
        !val ||
        (val &&
          ["image/png", "image/jpg", "image/jpeg"].includes((val as File).type))
    ),
  mode_payment_id: mixed().required("Payment Option required field"),
  payment_type_id: number().required("Payment Type required"),
});

export const editJourneySchema = object({
  flights: array().of(
    object({
      arrivalDate: string().when("isDomestic", (isDomestic, schema) => {
        return isDomestic[0]
          ? schema
              .required("Arrival date is required")
              .test(
                "is-greater-or-equal",
                "Arrival date must be equal to or later than departure date",
                function (value) {
                  const { departureDate, departureTime, arrivalTime } =
                    this.parent;
                  if (!departureDate || !value) {
                    return true; // Skip validation if any of the required fields are missing
                  }

                  if (moment(departureDate).isSameOrBefore(value, "day")) {
                    return true;
                  }

                  const departureDateTime = moment(
                    `${departureDate} ${departureTime}`,
                    "YYYY-MM-DD HH:mm"
                  );
                  const arrivalDateTime = moment(
                    `${value} ${arrivalTime}`,
                    "YYYY-MM-DD HH:mm"
                  );
                  return departureDateTime.isBefore(arrivalDateTime);
                }
              )
          : schema;
      }),
      arrivalTime: string().when("isDomestic", (isDomestic, schema) => {
        return isDomestic[0]
          ? schema
              .required("Arrival time is required")
              .test(
                "is-greater-or-equal",
                "Arrival time must be later than departure time",
                function (value) {
                  const { departureDate, departureTime, arrivalDate } =
                    this.parent;

                  if (
                    !departureDate ||
                    !arrivalDate ||
                    !departureTime ||
                    !value
                  ) {
                    return true; // Skip validation if any of the required fields are missing
                  }

                  const departureDateTime = moment(
                    `${departureDate} ${departureTime}`,
                    "YYYY-MM-DD HH:mm"
                  );
                  const arrivalDateTime = moment(
                    `${arrivalDate} ${value}`,
                    "YYYY-MM-DD HH:mm"
                  );
                  return departureDateTime.isBefore(arrivalDateTime);
                }
              )
          : schema;
      }),
      departureDate: string().when("isDomestic", (isDomestic, schema) => {
        return isDomestic[0]
          ? schema
              .required("Departure date is required")
              .test(
                "is-greater-or-equal",
                "Departure date must be equal to or earlier than departure date",
                function (value) {
                  const { arrivalDate, departureTime, arrivalTime } =
                    this.parent;
                  if (!arrivalDate || !value) {
                    return true; // Skip validation if any of the required fields are missing
                  }

                  if (moment(value).isSameOrBefore(arrivalDate, "day")) {
                    return true;
                  }

                  const departureDateTime = moment(
                    `${value} ${departureTime}`,
                    "YYYY-MM-DD HH:mm"
                  );
                  const arrivalDateTime = moment(
                    `${arrivalDate} ${arrivalTime}`,
                    "YYYY-MM-DD HH:mm"
                  );
                  return departureDateTime.isBefore(arrivalDateTime);
                }
              )
          : schema;
      }),
      departureTime: string().when("isDomestic", (isDomestic, schema) => {
        return isDomestic[0]
          ? schema
              .required("Departure time is required")
              .test(
                "is-greater-or-equal",
                "Departure time must be earlier than arrival time",
                function (value) {
                  const { arrivalTime, departureDate, arrivalDate } =
                    this.parent;

                  if (
                    !departureDate ||
                    !arrivalDate ||
                    !arrivalTime ||
                    !value
                  ) {
                    return true; // Skip validation if any of the required fields are missing
                  }

                  const departureDateTime = moment(
                    `${departureDate} ${value}`,
                    "YYYY-MM-DD HH:mm"
                  );
                  const arrivalDateTime = moment(
                    `${arrivalDate} ${arrivalTime}`,
                    "YYYY-MM-DD HH:mm"
                  );
                  return departureDateTime.isBefore(arrivalDateTime);
                }
              )
          : schema;
      }),
      carrierCodeAndFlightNo: string().required(
        "Carrier code and Flight no. is required"
      ),
      carrierName: string().when("isDomestic", (isDomestic, schema) => {
        return !isDomestic[0]
          ? schema.required("Carrier name is required")
          : schema;
      }),
      duration: string().when("isDomestic", (isDomestic, schema) => {
        return !isDomestic[0]
          ? schema.required("Duration is required")
          : schema;
      }),
      layOverDuration: string().when("isDomestic", (isDomestic, schema) => {
        return !isDomestic[0]
          ? schema.required("Layover duration is required")
          : schema;
      }),
    })
  ),
});

export const addBaggageSchema = object({
  passengers: array().of(
    object({
      new_baggage: object({
        kg: string()
          .required("Weight is required")
          .test(
            "is-greater-or-equal",
            "Weight is required if price is set",
            function (value) {
              const { price } = this.parent;
              let parsedPrice = parseFloat(price);
              let parsedValue = parseFloat(value);
              if (!price || parsedPrice <= 0) {
                return true; // Skip validation if price is missing or <= 0
              }
              return parsedValue > 0; // kg must be greater than 0 if price is set
            }
          ),
        price: string()
          .required("Price is required")
          .test(
            "is-greater-or-equal",
            "Price is required if weight is greater than 0",
            function (value) {
              const { kg } = this.parent;
              let parsedKg = parseFloat(kg);
              let parsedValue = parseFloat(value);
              if (!kg || parsedKg <= 0) {
                return true; // Skip validation if kg is missing or <= 0
              }
              return parsedValue > 0; // price must be greater than 0 if kg is set
            }
          ),
      }),
      new_return_baggage: object({
        kg: string()
          .required("Weight is required")
          .test(
            "is-greater-or-equal",
            "Weight is required if price is set",
            function (value) {
              const { price } = this.parent;
              let parsedPrice = parseFloat(price);
              let parsedValue = parseFloat(value);
              if (!price || parsedPrice <= 0) {
                return true; // Skip validation if price is missing or <= 0
              }
              return parsedValue > 0; // kg must be greater than 0 if price is set
            }
          ),
        price: string()
          .required("Price is required")
          .test(
            "is-greater-or-equal",
            "Price is required if weight is greater than 0",
            function (value) {
              const { kg } = this.parent;
              let parsedKg = parseFloat(kg);
              let parsedValue = parseFloat(value);
              if (!kg || parsedKg <= 0) {
                return true; // Skip validation if kg is missing or <= 0
              }
              return parsedValue > 0; // price must be greater than 0 if kg is set
            }
          ),
      }),
    })
  ),
});

export const HotelSearchFormSchema = object({
  locationName: object({
    label: string().required("Destination is required"),
    value: string(),
  }).required("Location is required"),
  checkInDate: string().required("Check-in date is required"),
  checkOutDate: string().required("Check-out date is required"),
  roomCount: number()
    .required("Room count is required")
    .min(1, "Room count must be at least 1"),
  adultCount: number()
    .required("Adult count is required")
    .min(1, "Adult count must be at least 1"),
});

const SUPPORTED_FORMATS = ["image/jpeg", "image/png", "image/gif"];
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
export const HotelBookingFormSchema = object({
  check_in_datetime: string()
    .required("Check-in date & time is required")
    .typeError("Invalid date"),
  check_out_datetime: string()
    .required("Check-out date & time is required")
    .typeError("Invalid date")
    .test(
      "is-later-than-check-in",
      "Check-out date must be later than check-in date",
      function (value) {
        const { check_in_datetime } = this.parent;

        // Parse dates
        const checkInDate = moment(check_in_datetime);
        const checkOutDate = moment(value);

        // Compare check-out date to check-in date
        if (checkOutDate.isSameOrBefore(checkInDate)) {
          return false;
        }

        return true;
      }
    ),
  receiver_email: string()
    .email("Must be a valid email")
    .required("Email is required"),
  receiver_mobile: string()
    .required("Mobile is required")
    .test("mobile-format", "Invalid mobile number format", validateMobile),
  hotel_name: string().required("Hotel Name is required"),
  hotel_address: string().required("Hotel Address is required"),
  hotel_contact: string().nullable(),
  hotel_location: string().required("Destination is required"),
  hotel_rating: string().required("Star Rating is required"),
  booking_key: string().required("Booking ID is required"),
  total_rooms: number()
    .required("Room count is required")
    .min(1, "Room count must be at least 1"),
  adult_guests: array()
    .required("Adult guests are required")
    .of(
      object().shape({
        salutation: string().required("Salutation is required"),
        first_name: string().required("First name is required"),
        last_name: string().required("Last name is required"),
      })
    )
    .min(1, "At least one adult guest is required"),
  child_guests: array()
    .nullable()
    .of(
      object().shape({
        salutation: string().required("Salutation is required"),
        first_name: string().required("First name is required"),
        last_name: string().required("Last name is required"),
      })
    )
    .test("is-empty-or-valid", "Invalid child guests array", (value) => {
      return (
        !value ||
        value.length === 0 ||
        value.every((v) => v.salutation && v.first_name && v.last_name)
      );
    }),
  room_type: string().required("Room Type is required"),
  cancellation_policies: string().required("Cancellation Policy is required"),
  base_price: string()
    .required("Base price is required")
    .test("is-valid-number", "Base price must be a number", function (value) {
      if (!value) return true; // Skip if empty (handled by required validation)
      return isValidNumber(value);
    })
    .test("is-positive", "Base price must be greater than 0", function (value) {
      if (!value) return true;
      const num = parseFloat(value);
      return num > 0;
    }),
  fees_and_taxes: string()
    .nullable()
    .test(
      "is-valid-number",
      "Fees and taxes must be a number",
      function (value) {
        if (!value) return true;
        return isValidNumber(value);
      }
    )
    .test(
      "is-positive",
      "Fees and taxes must be greater than or equal to 0",
      function (value) {
        if (!value) return true;
        const num = parseFloat(value);
        return num >= 0;
      }
    ),
  selling_price: string()
    .required("Selling price is required")
    .test(
      "is-valid-number",
      "Selling price must be a number",
      function (value) {
        if (!value) return true;
        return isValidNumber(value);
      }
    )
    .test(
      "is-positive",
      "Selling price must be greater than 0",
      function (value) {
        if (!value) return true;
        const num = parseFloat(value);
        return num > 0;
      }
    )
    .test(
      "not-lower-than-sum",
      "Selling price must not be lower than the sum of base price and fees and taxes",
      function (value: string) {
        const { base_price, fees_and_taxes } = this.parent;

        // Convert values to numbers for comparison
        const basePriceNum = parseFloat(base_price || 0);
        const feesAndTaxesNum = parseFloat(fees_and_taxes || 0);
        const sellingPriceNum = parseFloat(value);

        // Validate that selling price >= base price + fees and taxes
        return sellingPriceNum >= basePriceNum + feesAndTaxesNum;
      }
    ),
  vendor_id: mixed().required("Vendor is required"),
  hotel_image: mixed()
    .required("An image is required")
    .test(
      "fileType",
      "Please upload only JPEG, PNG, or GIF images.",
      (file) => file && SUPPORTED_FORMATS.includes((file as File).type)
    )
    .test(
      "fileSize",
      "File size must not exceed 5MB",
      (file) => file && (file as File).size <= MAX_FILE_SIZE
    ),
});

export const DirectHotelBookingFormSchema = object({
  deliveryData: object({
    email: string()
      .email("Must be a valid email")
      .required("Email is required"),
    mobile: string()
      .required("Mobile is required")
      .test("mobile-format", "Invalid mobile number format", validateMobile),
  }),
  roomGuests: array().of(
    object().shape({
      adults: array()
        .of(
          object().shape({
            salutation: string().required("Salutation is required"),
            first_name: string().required("First name is required"),
            last_name: string().required("Last name is required"),
          })
        )
        .min(1, "At least one adult guest is required"),
      children: array()
        .nullable()
        .of(
          object().shape({
            salutation: string().required("Salutation is required"),
            first_name: string().required("First name is required"),
            last_name: string().required("Last name is required"),
          })
        )
        .test("is-empty-or-valid", "Invalid child guests array", (value) => {
          return (
            !value ||
            value.length === 0 ||
            value.every((v) => v.salutation && v.first_name && v.last_name)
          );
        }),
    })
  ),
  specialRequest: string()
    .nullable()
    .max(2000, "Special request cannot exceed 2000 characters."),
});
