import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logout, setLoader } from "../redux/actions";
import createAxiosInstance from "../request/Axios";
import Loader from "../utils/Loader";

const Logout: React.FC = () => {
  const dispatch = useDispatch();

  const logoutHandler = async () => {
    try {
      dispatch(setLoader(true));
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.post("/api/auth/logout");
      if (response.data.result.success) {
        await dispatch(setLoader(false));
        await dispatch(logout());
        window.location.href = `${process.env.REACT_APP_SIGNIN_URL}`;
      } else {
        console.error("Logout failed:", response.data.result.message);
        dispatch(setLoader(false));
      }
    } catch (error) {
      console.error("API error:", error);
      await dispatch(logout());
      dispatch(setLoader(false));
    }
  };

  return (
    <div className="d-grid">
      <button
        className="btn btn-soft-default d-flex align-items-center text-success"
        onClick={logoutHandler}
      >
        <i className="ri-logout-box-r-line ri-xl"></i>
        <span>Logout</span>
      </button>
      <Loader />
    </div>
  );
};

export default Logout;
