import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface CreditInsufficientBalanceInterface {
  showModal: boolean;
  handleClose: () => void;
}

const CreditInsufficientBalance: React.FC<
  CreditInsufficientBalanceInterface
> = ({ showModal, handleClose }) => {
  const { currentUser } = useSelector((state: any) => state);
  return (
    <Modal show={showModal} onHide={handleClose} centered backdrop="static">
      <Modal.Body className="text-center">
        <div className="mt-0">
          <i className="ri-alert-line ri-7x text-warning"></i>
          <p className="mb-3 fs-4">
            {`Insufficient Credit: ${currentUser?.credits}`}
          </p>
          <div>
            <div className="form-icon right">
              Sorry, we can't process your request because your credit balance
              is too low. Please buy more credit here:{" "}
              <Link to="/billing-history" className="text-info">
                Buy Credit
              </Link>
              .
            </div>
            <br />
          </div>
          <div className="hstack gap-2 justify-content-center">
            <a
              href="#"
              className="btn btn-link link-info fw-medium"
              onClick={handleClose}
            >
              <i className="ri-close-line me-1 align-middle"></i> Close
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreditInsufficientBalance;
