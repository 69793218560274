export enum Features {
  FEATURE_DIRECT_BOOKING = "FEATURE_DIRECT_BOOKING",
  FEATURE_FUND_REQUEST = "FEATURE_FUND_REQUEST",
  FEATURE_DIRECT_BOOKING_HISTORY = "FEATURE_DIRECT_BOOKING_HISTORY",
  FEATURE_AFFILIATE_DASHBOARD = "FEATURE_AFFILIATE_DASHBOARD",
  FEATURE_ACTIVATION_CODE = "FEATURE_ACTIVATION_CODE",
}

export enum FilterTrip {
  FILTER_FUTURE_TRIP = "Future-Trips",
  FILTER_PAST_TRIP = "Past-Trips",
  FILTER_ONEWAY_TRIP = "One-Way",
  FILTER_ROUND_TRIP = "Round-Trip",
}

export const baggagePiecesOption = [
  { value: 0, label: "Number of pieces" },
  { value: 1, label: "1 piece" },
  { value: 2, label: "2 pieces" },
  { value: 3, label: "3 pieces" },
  { value: 4, label: "4 pieces" },
];

export enum DEFAULT {
  AIRLINE_LOGO = "https://t4.ftcdn.net/jpg/02/51/95/53/360_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg",
}
