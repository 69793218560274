export enum Channel {
  COMPANY_UPDATE_DETAILS = "COMPANY_UPDATE_DETAILS",
  ADMIN_CREDITS_UPDATE_DETAILS = "ADMIN_CREDITS_UPDATE_DETAILS",
  EVENT_DRIVEN_UPDATE = "EVENT_DRIVEN_UPDATE",
  SEND_BROADCAST_MESSAGE = "SEND_BROADCAST_MESSAGE",
  CATEGORY_LIST = "CATEGORY_LIST:",
  TRAINING_VIDEO_PROGRESS = "TRAINING_VIDEO_PROGRESS:",
  SEND_PRIVATE_MESSAGE = "SEND_PRIVATE_MESSAGE",
  CURRENT_FUNDS = "CURRENT_FUNDS:",
  ADMIN_CREDIT_SUMMARY = "ADMIN_CREDIT_SUMMARY",
  FORCE_LOGOUT = "FORCE_LOGOUT",
  COMPANY = "COMPANY:",
}
