import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faStarHalfAlt,
  faStar as faEmptyStar,
} from "@fortawesome/free-solid-svg-icons";

import React from "react";

interface StarRatingProps {
  rating: number;
  otherClasses?: string;
}

const StarRating: React.FC<StarRatingProps> = ({ rating, otherClasses }) => {
  const maxStars = 5; // Maximum stars (5-star rating system)
  const filledStars = Math.floor(rating); // Number of fully filled stars
  const halfStars = rating % 1 >= 0.5 ? 1 : 0; // Check if there's a half-star
  const emptyStars = maxStars - filledStars - halfStars; // Remaining stars

  return (
    <>
      {/* Render filled stars */}
      {[...Array(filledStars)].map((_, index) => (
        <FontAwesomeIcon
          key={`filled-${index}`}
          icon={faStar}
          className={`text-warning px-1 ${otherClasses ? otherClasses : ""}`}
          size="lg"
        />
      ))}

      {/* Render half-star if applicable */}
      {halfStars > 0 && (
        <FontAwesomeIcon
          key="half-star"
          icon={faStarHalfAlt}
          className={`text-warning px-1 ${otherClasses ? otherClasses : ""}`}
          size="lg"
        />
      )}

      {/* Render empty stars */}
      {[...Array(emptyStars)].map((_, index) => (
        <FontAwesomeIcon
          key={`empty-${index}`}
          icon={faEmptyStar}
          className={`text-muted px-1 ${otherClasses ? otherClasses : ""}`}
          size="lg"
        />
      ))}
    </>
  );
};

export default StarRating;
