import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, setLoader, clearFlightSearch } from "../redux/actions";
import { useLocation } from "react-router-dom";
import createAxiosInstance from "../request/Axios";
import Loader from "../utils/Loader";
import Footer from "./Footer";
import { Features } from "../utils/Constants";
import InternetConnectivityCheck from "../utils/InternetConnectivityCheck";

const AppMenu: React.FC = () => {
  const dispatch = useDispatch();
  const url = useLocation();
  const [isActive, setActiveTab] = useState("");
  const { currentUser, auth } = useSelector((state: any) => state);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      document.body.classList.remove("vertical-sidebar-enable");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const isFeatureIncluded = (feature: Features): boolean => {
    if (currentUser?.features) {
      return currentUser?.features.some((i: any) => i.feature === feature);
    }
    return false;
  };

  useEffect(() => {
    if (!auth.authToken || auth.authToken === "") {
      logoutHandler();
    }
    if (
      url.pathname.split("/")[2] === undefined ||
      url.pathname.split("/")[2] !== "main-course"
    ) {
      localStorage.removeItem("mainCourse");
      localStorage.removeItem("videoProgress");
    }
    setActiveTab(url.pathname.split("/")[1]);
  }, [auth.authToken, url]);

  const isAdminTab = ["agent-list"].includes(isActive);
  const isTrainingTab = ["create-training", "trainings"].includes(isActive);
  const airlineFlightsTab = [
    "flight-booking",
    "booking-history-report",
  ].includes(isActive);
  const flightItineraryBuilderTab = [
    "e-ticket-generator",
    "e-ticket-history-report",
  ].includes(isActive);
  const hotelVoucherBuilderTab = [
    "e-voucher-generator",
    "e-voucher-history-report",
  ].includes(isActive);
  const manageBookingTab = [
    "flight-change",
    "add-baggage",
    "calendar-flight",
  ].includes(isActive);
  const hotelBookingTab = [
    "hotel-booking",
    "hotel-booking-history-report",
  ].includes(isActive);

  const isDirectBookingIncluded = isFeatureIncluded(
    Features.FEATURE_DIRECT_BOOKING
  );
  const isTravelFundIncluded = isFeatureIncluded(Features.FEATURE_FUND_REQUEST);
  const isBookingHistoryIncluded = isFeatureIncluded(
    Features.FEATURE_DIRECT_BOOKING_HISTORY
  );
  const isAffiliateIncluded = isFeatureIncluded(
    Features.FEATURE_AFFILIATE_DASHBOARD
  );
  const isActivationCodeIncluded = isFeatureIncluded(
    Features.FEATURE_ACTIVATION_CODE
  );

  const logoutHandler = async () => {
    try {
      dispatch(setLoader(true));
      const axiosInstance = await createAxiosInstance();
      const response = await axiosInstance.post("/api/auth/logout");
      if (response.data.result.success) {
        await dispatch(setLoader(false));
        await dispatch(logout());
        await dispatch(clearFlightSearch());
        window.location.href = `${process.env.REACT_APP_SIGNIN_URL}`;
      } else {
        dispatch(setLoader(false));
      }
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(logout());
      window.location.href = `${process.env.REACT_APP_SIGNIN_URL}`;
    }
  };

  const resetEditData = () => {
    if (localStorage.getItem("editData") !== null) {
      localStorage.removeItem("editData");
    }
  };

  return (
    <div className="app-menu navbar-menu" ref={sidebarRef}>
      <Loader />
      <InternetConnectivityCheck />
      <div className="navbar-brand-box">
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm">
            <img
              src="https://cdn.mywanderlinx.com/assets/images/wanderLinx-banner.png"
              alt="Wanderlinx banner"
              height="22"
            />
          </span>
          <span className="logo-lg">
            <img
              src="https://cdn.mywanderlinx.com/assets/images/wanderLinx-banner.png"
              alt="Wanderlinx banner"
              height="17"
            />
          </span>
        </Link>
        <Link to="/" className="logo logo-light mt-2">
          <span className="logo-sm">
            <img
              src="https://cdn.mywanderlinx.com/assets/images/wanderLinx-banner.png"
              alt="mjc-logo"
              height="22"
            />
          </span>
          <span className="logo-lg">
            <img
              src="https://cdn.mywanderlinx.com/assets/images/wanderLinx-banner.png"
              alt="mjc-logo"
              height="60"
            />
          </span>
        </Link>
        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      <div id="scrollbar">
        <div className="container-fluid">
          <div id="two-column-menu"></div>
          <ul className="navbar-nav" id="navbar-nav">
            <li className="menu-title">
              <span data-key="t-menu">Menu</span>
            </li>

            <li className="nav-item">
              <a
                className={`nav-link menu-link collapsed ${
                  airlineFlightsTab ? "drop-down-active" : ""
                }`}
                href="#airlineFlights"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="airlineFlights"
              >
                <i className="ri-plane-line"></i>{" "}
                <span data-key="t-authentication">Airline Flights</span>
              </a>
              <div className="menu-dropdown collapse" id="airlineFlights">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/flight-booking"
                      className={`nav-link menu-link ${
                        isActive === "flight-booking" ? "active" : ""
                      }`}
                    >
                      <span data-key="flight-booking">Flight Booking</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/booking-history-report"
                      className={`nav-link menu-link ${
                        isActive === "booking-history-report" ? "active" : ""
                      }`}
                    >
                      <span data-key="booking-history-report">
                        Transaction Reports
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            {/* <li className="nav-item">
              <a
                className={`nav-link menu-link collapsed ${
                  hotelBookingTab ? "drop-down-active" : ""
                }`}
                href="#hotels"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="hotels"
              >
                <i className="ri-file-list-3-line"></i>{" "}
                <span data-key="t-authentication">Hotel Booking</span>
              </a>
              <div className="menu-dropdown collapse" id="hotels">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/hotel-booking"
                      className={`nav-link menu-link ${
                        isActive === "hotel-booking" ? "active" : ""
                      }`}
                    >
                      <span data-key="hotel-booking">Search Hotel</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/hotel-booking-history-report"
                      className={`nav-link menu-link ${
                        isActive === "hotel-booking-history-report"
                          ? "active"
                          : ""
                      }`}
                    >
                      <span data-key="hotel-booking-history-report">
                        Transaction Reports
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}

            <li className="nav-item">
              <a
                className={`nav-link menu-link collapsed ${
                  flightItineraryBuilderTab ? "drop-down-active" : ""
                }`}
                href="#flightItineraryBuilder"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="flightItineraryBuilder"
              >
                <i className="ri-file-list-3-line"></i>{" "}
                <span data-key="t-authentication">
                  Flight Itinerary Builder
                </span>
              </a>
              <div
                className="menu-dropdown collapse"
                id="flightItineraryBuilder"
              >
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/e-ticket-generator"
                      className={`nav-link menu-link ${
                        isActive === "e-ticket-generator" ? "active" : ""
                      }`}
                    >
                      <span data-key="e-ticket-generator">
                        Generate Flight Itinerary
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/e-ticket-history-report"
                      className={`nav-link menu-link ${
                        isActive === "e-ticket-history-report" ? "active" : ""
                      }`}
                    >
                      <span data-key="e-ticket-history-report">
                        Transaction Reports
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li className="nav-item">
              <a
                className={`nav-link menu-link collapsed ${
                  hotelVoucherBuilderTab ? "drop-down-active" : ""
                }`}
                href="#hotelVoucherBuilder"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="hotelVoucherBuilder"
              >
                <i className="ri-building-2-line"></i>{" "}
                <span data-key="t-authentication">Hotel Voucher Builder</span>
              </a>
              <div className="menu-dropdown collapse" id="hotelVoucherBuilder">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/e-voucher-generator"
                      className={`nav-link menu-link ${
                        isActive === "e-voucher-generator" ? "active" : ""
                      }`}
                    >
                      <span data-key="e-voucher-generator">
                        Generate Hotel Voucher
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/e-voucher-history-report"
                      className={`nav-link menu-link ${
                        isActive === "e-voucher-history-report" ? "active" : ""
                      }`}
                    >
                      <span data-key="e-voucher-history-report">
                        Transaction Reports
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li className="nav-item">
              <a
                className={`nav-link menu-link collapsed ${
                  manageBookingTab ? "drop-down-active" : ""
                }`}
                href="#manageBooking"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="manageBooking"
              >
                <i className="ri-pie-chart-line"></i>{" "}
                <span data-key="t-authentication">Booking Management</span>
              </a>
              <div className="menu-dropdown collapse" id="manageBooking">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/flight-change"
                      className={`nav-link menu-link ${
                        isActive === "flight-change" ? "active" : ""
                      }`}
                    >
                      <span data-key="flight-change">Update Flight Change</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/add-baggage"
                      className={`nav-link menu-link ${
                        isActive === "add-baggage" ? "active" : ""
                      }`}
                    >
                      <span data-key="add-baggage">Baggage Customization</span>
                    </Link>
                  </li>
                  <li className="nav-item hide-on-mobile">
                    <Link
                      to="/calendar-flight"
                      className={`nav-link menu-link ${
                        isActive === "calendar-flight" ? "active" : ""
                      }`}
                    >
                      <span data-key="calendar-flight">
                        Flight Schedule Calendar
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li className="nav-item">
              <Link
                to="/travel-fund"
                className={`nav-link menu-link ${
                  isActive === "travel-fund" ? "active" : ""
                }`}
              >
                <i className="ri-money-dollar-circle-line"></i>
                <span data-key="account-setting">Travel Fund</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/billing-history"
                className={`nav-link menu-link ${
                  isActive === "billing-history" ? "active" : ""
                }`}
              >
                <i className="ri-wallet-line"></i>
                <span data-key="account-setting">Credits Account</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/activation-code"
                className={`nav-link menu-link ${
                  isActive === "activation-code" ? "active" : ""
                }`}
              >
                <i className="ri-barcode-fill"></i>
                <span data-key="account-setting">Activation Code</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/affiliate-dashboard"
                className={`nav-link menu-link ${
                  isActive === "affiliate" ? "active" : ""
                }`}
              >
                <i className="ri-dashboard-line"></i>
                <span data-key="search-flight">Affiliate Dashboard</span>
              </Link>
            </li>

            <div>
              {currentUser?.roles === "Admin" ? (
                <div>
                  <li className="nav-item">
                    <a
                      className={`nav-link menu-link collapsed ${
                        isTrainingTab ? "drop-down-active" : ""
                      }`}
                      href="#sidebarTraining"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarTraining"
                    >
                      <i className="ri-slideshow-4-line"></i>{" "}
                      <span data-key="t-authentication">Training</span>
                    </a>
                    <div
                      className="menu-dropdown collapse"
                      id="sidebarTraining"
                    >
                      <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                          <Link
                            to="/create-training"
                            className={`nav-link menu-link ${
                              isActive === "create-training" ? "active" : ""
                            }`}
                            onClick={() => resetEditData()}
                          >
                            <span data-key="create-training">Create</span>{" "}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/trainings"
                            className={`nav-link menu-link ${
                              isActive === "trainings" ? "active" : ""
                            }`}
                          >
                            <span data-key="trainings">List</span>{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="nav-item">
                    <a
                      className={`nav-link menu-link collapsed ${
                        isAdminTab ? "drop-down-active" : ""
                      }`}
                      href="#sidebarAuth"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarAuth"
                    >
                      <i className="ri-account-circle-line"></i>{" "}
                      <span data-key="t-authentication">Admin Account</span>
                    </a>
                    <div className="menu-dropdown collapse" id="sidebarAuth">
                      <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                          <Link
                            to="/agent-list"
                            className={`nav-link menu-link ${
                              isActive === "agent-list" ? "active" : ""
                            }`}
                          >
                            <span data-key="Create_Itinerary">
                              Agent List/Report
                            </span>{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </div>
              ) : (
                <li className="nav-item">
                  <Link
                    to="/trainings"
                    className={`nav-link menu-link ${
                      isActive === "trainings" ? "active" : ""
                    }`}
                  >
                    <i className="bx bx-chalkboard"></i>
                    <span data-key="trainings">E-Learnings</span>
                  </Link>
                </li>
              )}
            </div>

            <li className="nav-item">
              <Link
                to="/account-setting"
                className={`nav-link menu-link ${
                  isActive === "account-setting" ? "active" : ""
                }`}
              >
                <i className="ri-list-settings-line"></i>
                <span data-key="account-setting">Account Setting</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/provider-list"
                className={`nav-link menu-link ${
                  isActive === "provider-list" ? "active" : ""
                }`}
              >
                <i className="ri-file-list-3-fill"></i>
                <span data-key="activation-code">Provider list</span>
              </Link>
            </li>

            {currentUser?.roles === "Admin" && (
              <li className="nav-item">
                <Link
                  to="/payment-history"
                  className={`nav-link menu-link ${
                    isActive === "payment-history" ? "active" : ""
                  }`}
                >
                  <i className="ri-secure-payment-line"></i>
                  <span data-key="account-setting">Payment History</span>
                </Link>
              </li>
            )}

            <li className="nav-item">
              <Link
                to="/terms-condition"
                className={`nav-link menu-link ${
                  isActive === "terms-condition" ? "active" : ""
                }`}
              >
                <i className="ri-newspaper-fill"></i>
                <span data-key="search-flight">Terms & Condition</span>
              </Link>
            </li>

            <li className="nav-item" onClick={logoutHandler}>
              <Link to="/logout" className="menu-link nav-link label-cursor">
                <i className="ri-logout-circle-line"></i>
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="sidebar-background"></div>
      <Footer />
    </div>
  );
};

export default AppMenu;
