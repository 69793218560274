import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import type { GlobalSearchResults } from "../types/GlobalSearchType";

interface SearchModalProps {
  showModal: boolean;
  handleClose: () => void;
  data: GlobalSearchResults;
}

const GlobalSearchResult: React.FC<SearchModalProps> = ({
  showModal,
  handleClose,
  data = [],
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prev) => (prev + 1) % data.length);
  };

  const handlePrevious = () => {
    setActiveIndex((prev) => (prev - 1 + data.length) % data.length);
  };

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleString("en-US", {
      dateStyle: "medium",
      timeStyle: "short",
    });
  };

  // Empty state handling
  if (!data || data.length === 0) {
    return (
      <Modal show={showModal} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Search Details:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="text-center">
            <i className="ri-information-line ri-3x text-warning mb-3"></i>
            <h5>No Results Found</h5>
            <p className="text-muted">
              No search results are available to display.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  const currentItem = data[activeIndex];

  if (!currentItem) return null;

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton className="bg-light">
        <Modal.Title className="d-flex align-items-center">
          <i className="ri-search-line me-2 text-primary"></i>
          Search Results ({activeIndex + 1} of {data.length})
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4">
        {data.length > 1 && (
          <div className="d-flex justify-content-between mb-4">
            <button
              className="btn btn-sm btn-light"
              onClick={handlePrevious}
              disabled={activeIndex === 0}
            >
              <i className="ri-arrow-left-s-line me-1"></i>
              Previous
            </button>
            <button
              className="btn btn-sm btn-light"
              onClick={handleNext}
              disabled={activeIndex === data.length - 1}
            >
              Next
              <i className="ri-arrow-right-s-line ms-1"></i>
            </button>
          </div>
        )}

        <div className="position-relative">
          {/* Basic Information */}
          <div className="card mb-4">
            <div className="card-header bg-light">
              <h6 className="mb-0">Basic Information</h6>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col-md-4">
                  <label className="text-muted small">ID</label>
                  <p className="mb-0 fw-medium">{currentItem?.id || "N/A"}</p>
                </div>
                <div className="col-md-4">
                  <label className="text-muted small">Key ID</label>
                  <p className="mb-0 fw-medium">
                    {currentItem?.key_id || "N/A"}
                  </p>
                </div>
                <div className="col-md-4">
                  <label className="text-muted small">Itinerary Key</label>
                  <p className="mb-0 fw-medium">
                    {currentItem?.itin_key || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* User Information */}
          {currentItem?.user && (
            <div className="card mb-4">
              <div className="card-header bg-light">
                <h6 className="mb-0">User Information</h6>
              </div>
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-md-6">
                    <label className="text-muted small">Full Name</label>
                    <p className="mb-0 fw-medium">
                      {currentItem.user.details?.full_name || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <label className="text-muted small">Email</label>
                    <p className="mb-0 fw-medium">
                      {currentItem.user.email || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <label className="text-muted small">Account ID</label>
                    <p className="mb-0 fw-medium">
                      {currentItem.user.account_id || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Booked Data */}
          {currentItem?.booked_data && (
            <div className="card mb-4">
              <div className="card-header bg-light">
                <h6 className="mb-0">Booking Information</h6>
              </div>
              <div className="card-body">
                <div className="bg-light p-3 rounded">
                  <pre
                    className="mb-0"
                    style={{ maxHeight: "200px", overflow: "auto" }}
                  >
                    {JSON.stringify(currentItem.booked_data, null, 2)}
                  </pre>
                </div>
              </div>
            </div>
          )}

          {/* Booking File */}
          {currentItem?.booking_file && (
            <div className="card mb-4">
              <div className="card-header bg-light">
                <h6 className="mb-0">Booking File</h6>
              </div>
              <div className="card-body">
                <div className="bg-light p-3 rounded">
                  <pre
                    className="mb-0"
                    style={{ maxHeight: "200px", overflow: "auto" }}
                  >
                    {JSON.stringify(currentItem.booking_file, null, 2)}
                  </pre>
                </div>
              </div>
            </div>
          )}

          {/* Created At */}
          <div className="card">
            <div className="card-header bg-light">
              <h6 className="mb-0">Timeline</h6>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center">
                <i className="ri-time-line me-2 text-muted"></i>
                <span className="text-muted small">Created:</span>
                <span className="ms-2 fw-medium">
                  {formatDate(currentItem?.created_at)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="bg-light">
        <div className="d-flex justify-content-between w-100">
          <div className="text-muted small">
            Showing result {activeIndex + 1} of {data.length}
          </div>
          <button className="btn btn-danger" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GlobalSearchResult;
