import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const InternetConnectivityCheck: React.FC = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <Modal
      size="sm"
      show={!isOnline}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" role="status" className="color-0ab39c" />
      </Modal.Body> */}
      <Modal.Body className="text-center">
        <div className="mt-0">
          <i className="ri-wifi-off-line ri-7x text-danger"></i>
          <div>
            <div className="form-icon right">
              It looks like you're not connected to the internet.
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InternetConnectivityCheck;
