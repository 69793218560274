import { FC, useEffect, useState } from "react";
import { ToasterMessage } from "../utils/Toaster";
import PageTopbar from "../includes/PageTopbar";
import AppMenu from "../includes/AppMenu";
import AsyncSelect from "react-select/async";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  HotelSearchFormData,
  Select2Default,
} from "../interface/DataInterface";
import { HotelSearchFormSchema } from "../formValidation/validation";
import { fetchHotelDropdownOptions, searchByHotelId } from "../request/api";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { DatePicker } from "rsuite";
import moment from "moment";
import InputErrorMsg from "../utils/InputErrorMsg";
import { Logger } from "../utils/Logger";
import { setMyHotel } from "../redux/actions";
import { HotelResult, RoomGuest } from "../types/Types";
import DOMPurify from "dompurify";
import Swal from "sweetalert2";
import { OptionsOrGroups } from "react-select";
import StarRating from "../utils/StarRating";
import HotelFilterList from "../utils/HotelFilterList";
import HotelPriceRangeFilter from "../utils/HotelPriceRangeFilter";
import HotelLoadingModal from "../utils/HotelLoadingModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const fallbackImage = "http://cdn.via.com/static/hotel/images/no_image.jpg";

const HotelInitialSearchForm: HotelSearchFormData = {
  locationName: "",
  checkInDate: moment().add(1, "days").startOf("day").toDate(),
  checkOutDate: moment().add(2, "days").startOf("day").toDate(),
  roomCount: 1,
  adultCount: 1,
  childCount: 0,
};

const initialRoomGuest = [
  {
    adults: [
      {
        salutation: "Mr",
        first_name: "",
        last_name: "",
      },
      {
        salutation: "Mr",
        first_name: "",
        last_name: "",
      },
    ],
    children: [],
  },
];

export const getTotalAdults = (roomGuests: Array<RoomGuest>) => {
  return roomGuests?.reduce(
    (sum: number, guest: any) => sum + (guest.adults.length || 0),
    0
  );
};

export const getTotalChildren = (roomGuests: any) => {
  return roomGuests?.reduce(
    (sum: number, guest: any) => sum + (guest.children.length || 0),
    0
  );
};

const HotelBooking: FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfNights, setNumberOfNights] = useState(1);
  const [roomGuests, setRoomGuests] = useState<RoomGuest[]>(initialRoomGuest);
  const [showRoomAndGuestDropdown, setShowRoomAndGuestDropdown] =
    useState(false);
  const [hotelResults, setHotelResults] = useState<HotelResult[]>([]);
  const [hotelDefault, setHotelDefault] = useState<Select2Default>({
    label: "",
    value: "",
  });
  const [hotelOptions, setHotelOptions] = useState<Select2Default[]>([]);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [amenities, setAmenities] = useState<string[]>([]);
  const [propertyTypes, setPropertyTypes] = useState<string[]>([]);
  const [ratings, setRatings] = useState<string[]>([]);
  const [cities, setLandmarks] = useState<string[]>([]);
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState<string[]>(
    []
  );
  const [selectedRating, setSelectedRatings] = useState<number[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [filterByKeyword, setFilterByKeyword] = useState<string>("");
  const [searchResults, setSearchResults] = useState<HotelResult[]>([]);
  const [selectedMinPrice, setSelectedMinPrice] = useState<number>(0);
  const [selectedMaxPrice, setSelectedMaxPrice] = useState<number>(0);
  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [isSearching, setIsSearching] = useState(false);

  const today = moment().startOf("day").toDate();
  const nextDate = moment().add(1, "days").startOf("day").toDate();

  const onSubmit = async (
    values: any,
    {
      resetForm,
      setSubmitting,
      setFieldError,
    }: {
      resetForm: () => void;
      setSubmitting: (isSubmitting: boolean) => void;
      setFieldError: (field: string, message: string) => void;
    }
  ) => {
    const checkIn = moment(values.checkInDate);
    const checkOut = moment(values.checkOutDate);
    const locationNameLabel = values.locationName.label;
    setShowRoomAndGuestDropdown(false);
    setHotelResults([]);
    setIsLoading(true);
    setIsSearching(true);
    setSubmitting(true);
    setTotalResults(0);
    setSearchResults([]);
    setAmenities([]);
    setPropertyTypes([]);
    setRatings([]);
    setLandmarks([]);
    setMinPrice(0);
    setMaxPrice(50000);
    setNumberOfNights(checkOut.diff(checkIn, "days"));
    setSearchKeyword(
      locationNameLabel.charAt(0).toUpperCase() + locationNameLabel.slice(1)
    );

    const transformedRooms = roomGuests.map((room) => {
      const adultsCount = room.adults.length;
      const childrenCount = room.children.length;
      const childrenAges = room.children.map((child) => child?.age?.toString());

      return {
        adults: adultsCount.toString(),
        children: childrenCount.toString(),
        childrenAges: childrenAges,
      };
    });

    const updatedValues = {
      checkInDate: checkIn.format("YYYY-MM-DD"),
      checkOutDate: checkOut.format("YYYY-MM-DD"),
      regionIds: [values.locationName.value],
      rooms: transformedRooms,
      getRates: "true",
      getRooms: "true",
    };

    await searchByHotelId(dispatch, updatedValues)
      .then((res) => {
        Logger.info("searchByHotelId: ", res);
        setHotelResults(res.result.data.hotel_results);
        setSearchResults(res.result.data.hotel_results);
        setTotalResults(res.result.data.total_hotels);
        setAmenities(res.result.data.amenities);
        setPropertyTypes(res.result.data.property_types);
        setRatings(res.result.data.ratings);
        setLandmarks(res.result.data.cities);
        setMinPrice(Number(res.result.data.min_price));
        setMaxPrice(Number(res.result.data.max_price));
        setIsLoading(false);
      })
      .catch((err) => {
        if ([400, 404, 500].includes(err?.response?.status)) {
          const sanitizedHtml = DOMPurify.sanitize(
            err?.response?.data?.result?.message ?? "Something went wrong."
          );

          Swal.fire({
            icon: "warning",
            html: sanitizedHtml,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsSearching(false);
        setSubmitting(false);
      });
  };

  const navigateToSelectRoom = (reference: HotelResult) => {
    const checkIn = moment(reference.search_details.checkIn);
    const checkOut = moment(reference.search_details.checkOut);

    const updatedReference = {
      ...reference,
      is_domestic: reference.is_domestic,
      search_details: {
        ...reference.search_details,
        numberOfNights: checkOut.diff(checkIn, "days"),
        roomGuests: roomGuests,
      },
    };

    dispatch(
      setMyHotel({
        savedDirectHotelFormDetails: {
          ...updatedReference,
        },
      })
    ); // Save the selected hotel details to the Redux store

    localStorage.setItem(
      "savedDirectHotelFormDetails",
      JSON.stringify(updatedReference)
    ); // Save the selected hotel details to the local storage

    window.location.href = "/select-room"; // Redirect to the room selection page
  };

  const fetchAllHotels = async () => {
    await fetchHotelDropdownOptions(dispatch)
      .then((res) => {
        setHotelOptions(res?.result?.data ?? []);
      })
      .catch((err) => {
        if ([404, 500].includes(err?.response?.status)) {
          if (!err?.response?.data?.result?.is_credit) {
            const sanitizedHtml = DOMPurify.sanitize(
              err?.response?.data?.result?.message ?? "Something went wrong."
            );
            Swal.fire({
              icon: "warning",
              html: sanitizedHtml,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
              buttonsStyling: false,
            });
          }
        }
      });
  };

  const filterHotels = (inputValue: string) => {
    return hotelOptions.filter(
      (i) =>
        i.label &&
        typeof i.label === "string" &&
        i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const debouncedFetchOptions = debounce(
    (inputValue, callback, dispatch, setHotelOptions) => {
      fetchHotelDropdownOptions(dispatch, { search: inputValue })
        .then((res) => {
          const apiResults = res?.result?.data ?? [];

          // Update the state with new options
          setHotelOptions((prev: any) => [...prev, ...apiResults]);

          // Provide results to the callback
          callback(apiResults);
        })
        .catch((err) => {
          console.error("Error fetching options from API:", err);
          callback([]);
        });
    },
    1000 // debounce delay in milliseconds
  );

  const callbackOptions = (
    inputValue: string,
    callback: (
      options: OptionsOrGroups<{ label: string; value: string }, any>
    ) => void
  ) => {
    // Attempt to filter results locally first
    const results = filterHotels(inputValue);
    debouncedFetchOptions(inputValue, callback, dispatch, setHotelOptions);
  };

  const handleRoomCountChange = (operation: string) => {
    const MIN_ROOMS = 1; // Minimum number of rooms allowed
    const MAX_ROOMS = 5; // Maximum number of rooms allowed

    setRoomGuests((prevSelectedRoom) => {
      const newRoomCount =
        operation === "add"
          ? Math.min(prevSelectedRoom.length + 1, MAX_ROOMS)
          : Math.max(prevSelectedRoom.length - 1, MIN_ROOMS);

      const newRoomGuests =
        operation === "add" && newRoomCount > prevSelectedRoom.length
          ? [
              ...prevSelectedRoom,
              {
                adults: [
                  {
                    salutation: "Mr",
                    first_name: "",
                    last_name: "",
                  },
                ],
                children: [],
              },
            ] // Add a default room object
          : prevSelectedRoom.slice(0, newRoomCount); // Remove the last room object when subtracting

      return newRoomGuests; // Return the updated array
    });
  };

  const handleAdultCountChange = (operation: string, index: number) => {
    setRoomGuests((prevSelectedRoom) => {
      const newRoomGuests = prevSelectedRoom.map((room, i) => {
        if (i === index) {
          if (operation === "add") {
            // Add a new adult object (e.g., with default fields)
            return {
              ...room,
              adults: [
                ...room.adults,
                { salutation: "Mr", first_name: "", last_name: "" },
              ],
            };
          } else if (operation === "remove" && room.adults.length > 1) {
            // Remove the last adult, ensuring at least 1 remains
            return {
              ...room,
              adults: room.adults.slice(0, -1),
            };
          }
        }
        return room; // No change for other rooms
      });

      return newRoomGuests; // Return the updated array
    });
  };

  const handleChildrenCountChange = (operation: string, index: number) => {
    const MIN_CHILDREN = 0; // Minimum number of children allowed
    const MAX_CHILDREN = 3; // Maximum number of children allowed

    setRoomGuests((prevSelectedRoom) => {
      const newRoomGuests = prevSelectedRoom.map((room, i) => {
        if (i === index) {
          if (operation === "add" && room.children.length < MAX_CHILDREN) {
            // Add a new adult object (e.g., with default fields)
            return {
              ...room,
              children: [
                ...room.children,
                { salutation: "Mr", first_name: "", last_name: "", age: 2 },
              ],
            };
          } else if (
            operation === "remove" &&
            room.adults.length > MIN_CHILDREN
          ) {
            // Remove the last adult, ensuring at least 1 remains
            return {
              ...room,
              children: room.children.slice(0, -1),
            };
          }
        }
        return room; // No change for other rooms
      });

      return newRoomGuests; // Return the updated array
    });
  };

  const handleFilter = (
    amenities: string[],
    propertyTypes: string[],
    starRatings: number[],
    locations: string[],
    filterByKeyword: string,
    minPrice: number,
    maxPrice: number
  ) => {
    // Filter results based on all conditions
    return searchResults.filter((hotelResult: HotelResult) => {
      // Check if all selected amenities are included
      if (amenities.length > 0) {
        const hasAllAmenities = amenities.every((amenity) =>
          hotelResult["facilities_and_services"].some(
            (service: string) => service === amenity // Exact match
          )
        );
        if (!hasAllAmenities) return false;
      }

      // Check if the property type matches
      if (propertyTypes.length > 0 && hotelResult["property_type"]) {
        const matchesPropertyType = propertyTypes.some(
          (type) => type === hotelResult["property_type"] // Exact match
        );
        if (!matchesPropertyType) return false;
      }

      // Check if the star rating matches
      if (starRatings.length > 0) {
        const matchesStarRating = starRatings.some((starRating: number) => {
          const rtg = hotelResult["current_rating"];
          return (
            Number(rtg) >= Number(starRating) &&
            Number(rtg) < Number(starRating) + 1
          );
        });
        if (!matchesStarRating) return false;
      }

      // Check if the location matches
      if (Array.isArray(locations) && locations.length > 0) {
        const matchesLocation = locations.some((location) =>
          hotelResult["city"]
            .map((city: string) => city.toLowerCase())
            .includes(location.toLowerCase())
        );

        if (!matchesLocation) return false;
      }

      // Check if the keyword matches the hotel name
      if (filterByKeyword) {
        const matchesKeyword = hotelResult["name"]
          .toLowerCase()
          .includes(filterByKeyword.toLowerCase());
        if (!matchesKeyword) return false;
      }

      // Check if the price range matches the hotel lowest price
      if (minPrice && maxPrice) {
        let lowestPrice = parseFloat(
          hotelResult["lowest_price"].replace(/,/g, "")
        );

        const matchesPriceRange =
          Number(lowestPrice) >= Number(minPrice) &&
          Number(lowestPrice) <= Number(maxPrice);

        if (!matchesPriceRange) return false;
      }

      // If all checks pass, include this hotel result
      return true;
    });
  };

  useEffect(() => {
    fetchAllHotels();
    setIsLoading(true);
  }, []);

  useEffect(() => {
    const filteredHotels = handleFilter(
      selectedAmenities,
      selectedPropertyTypes,
      selectedRating,
      selectedLocations,
      filterByKeyword,
      selectedMinPrice,
      selectedMaxPrice
    );
    setHotelResults(filteredHotels);
    setTotalResults(filteredHotels.length);
  }, [
    selectedAmenities,
    selectedPropertyTypes,
    selectedRating,
    selectedLocations,
    filterByKeyword,
    selectedMinPrice,
    selectedMaxPrice,
  ]);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset overflow when the component unmounts
    };
  }, [isLoading]);

  return (
    <div id="layout-wrapper">
      <ToasterMessage />
      <PageTopbar />
      <AppMenu />
      {isSearching && <HotelLoadingModal showModal={isSearching} />}

      <div className="main-content mt-md-0">
        <div className="page-content p-1 p-md-3 mt-ctm">
          <div className="container-fluid px-0 px-md-2">
            <div className="position-relative mx-n4 mt-n4 ">
              <div
                className="position-relative back mt-5"
                style={{ height: "300px" }}
              >
                <div
                  className="position-absolute end-25 ms-5 mt-5"
                  style={{ zIndex: "5", marginTop: "85px !important" }}
                >
                  <div className="mt-5 ms-n2">
                    <h3 className="fw-bolder text-white fs-1">
                      Enjoy Your Dream Vacation
                    </h3>
                    <div className="text-white fs-4 pe-md-0 pe-4">
                      More than 360,000 hotel, hostels, apartments and guest
                      houses around the world.
                    </div>
                  </div>
                </div>
                <img
                  src="https://cdn.mywanderlinx.com/assets/images/Planeback.jpg"
                  className="position-absolute top-0 start-0 end-0 w-100 h-100"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div className="overlay-content">nice</div>
              </div>
            </div>

            {/* Searching Flight */}
            <Formik
              initialValues={HotelInitialSearchForm}
              validationSchema={HotelSearchFormSchema}
              onSubmit={onSubmit}
            >
              {({ errors, values, isSubmitting, setFieldValue }) => {
                return (
                  <Form autoComplete="off">
                    <div
                      id="myDiv"
                      className="row mt-0 mt-md-0 d-md-block mt-n5"
                    >
                      <div className="col-lg-12">
                        {/* Flight Seaching Section */}
                        <div className="bg-white mt-0 mt-md-n5 rounded-3 border-shadow mb-3">
                          <div className="position-relative p-3 py-md-4 py-5 p-md-4">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="row">
                                  <div className="col-lg-12 col-12 py-2 pe-md-1 py-md-0">
                                    <div className="form-floating">
                                      <AsyncSelect
                                        cacheOptions
                                        {...(hotelDefault.label &&
                                        hotelDefault.value
                                          ? { value: hotelDefault }
                                          : null)}
                                        defaultOptions={hotelOptions}
                                        loadOptions={callbackOptions}
                                        onChange={(selectedOption: any) => {
                                          setFieldValue(
                                            "locationName",
                                            selectedOption
                                          );
                                        }}
                                        isClearable
                                        placeholder="Destination"
                                        name="locationName.label"
                                        className={`w-100`}
                                        styles={{
                                          control: (base: any) => ({
                                            ...base,
                                            boxShadow: "none",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            "&:hover": {
                                              border: "1px solid #ccc",
                                            },
                                            height: "55px",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            fontSize: "1.015625rem",
                                          }),
                                          dropdownIndicator: (base: any) => ({
                                            ...base,
                                            display: "none",
                                          }),
                                          indicatorSeparator: (base: any) => ({
                                            ...base,
                                            display: "none",
                                          }),
                                          input: (base: any) => ({
                                            ...base,
                                            textTransform: "capitalize",
                                          }),
                                          menu: (base: any) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                      />
                                      <ErrorMessage
                                        name="locationName.label"
                                        component={InputErrorMsg}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 my-2 mt-md-0 mb-md-0">
                                <div className="row">
                                  <div className="col-lg-6 col-6 pe-0 ps-md-0">
                                    <div
                                      className=""
                                      style={{ height: "55px" }}
                                    >
                                      <Field
                                        value={values.checkInDate}
                                        name="checkInDate"
                                        component={DatePicker}
                                        format="dd MMM yyyy"
                                        onChange={(selectedOption: any) => {
                                          if (!selectedOption) return;
                                          const newDate = moment(selectedOption)
                                            .add(1, "days")
                                            .set({
                                              hour: 0,
                                              minute: 0,
                                              second: 0,
                                              millisecond: 0,
                                            }) // Reset time to 00:00:00
                                            .toDate();
                                          setFieldValue(
                                            "checkInDate",
                                            selectedOption
                                          );
                                          setFieldValue(
                                            "checkOutDate",
                                            newDate
                                          );
                                        }}
                                        disabledDate={(date: {
                                          getTime: () => number;
                                        }) => {
                                          return (
                                            date.getTime() < today.getTime()
                                          );
                                        }}
                                        placeholder=" - Select -"
                                        size="lg"
                                        style={{
                                          width: 340,
                                        }}
                                        oneTap
                                        placement="auto"
                                        className="hotel-date-range hdr-rounded-start"
                                      />
                                      <ErrorMessage
                                        name="checkInDate"
                                        component={InputErrorMsg}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-6 ps-0 pe-md-1">
                                    <div
                                      className=""
                                      style={{ height: "55px" }}
                                    >
                                      <Field
                                        value={values.checkOutDate}
                                        name="checkOutDate"
                                        component={DatePicker}
                                        format="dd MMM yyyy"
                                        onChange={(selectedOption: any) => {
                                          if (!selectedOption) return;
                                          setFieldValue(
                                            "checkOutDate",
                                            selectedOption
                                          );
                                        }}
                                        disabledDate={(date: {
                                          getTime: () => number;
                                        }) => {
                                          const checkInDate =
                                            values.checkInDate;

                                          if (
                                            checkInDate &&
                                            date.getTime() <=
                                              checkInDate.getTime()
                                          ) {
                                            return true;
                                          }

                                          if (
                                            date.getTime() < nextDate.getTime()
                                          ) {
                                            return true;
                                          }

                                          return false; // Enable other dates
                                        }}
                                        placeholder=" - Select -"
                                        size="lg"
                                        style={{
                                          width: 340,
                                        }}
                                        oneTap
                                        placement="auto"
                                        className="hotel-date-range hdr-rounded-end"
                                      />
                                      <ErrorMessage
                                        name="checkOutDate"
                                        component={InputErrorMsg}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div className="row">
                                  <div className="col-lg-12 col-12 py-2 px-md-0 py-md-0">
                                    <div
                                      onClick={() =>
                                        setShowRoomAndGuestDropdown(
                                          (prev) => !prev
                                        )
                                      }
                                      className="p-2 px-3 rounded-1 position-relative cursor-pointer"
                                      style={{
                                        height: "55px",
                                        border: "1px solid #ced4da",
                                      }}
                                    >
                                      <div className="text-muted ">
                                        Rooms and Guests
                                      </div>
                                      <div className="fw-semibold ">
                                        {roomGuests.length} Room,{" "}
                                        {getTotalAdults(roomGuests)} Adult
                                        {`${
                                          getTotalAdults(roomGuests) > 1
                                            ? "s"
                                            : ""
                                        }`}
                                        {`${
                                          getTotalChildren(roomGuests)
                                            ? `, ${getTotalChildren(
                                                roomGuests
                                              )} ${
                                                getTotalChildren(roomGuests) > 1
                                                  ? "Children"
                                                  : "Child"
                                              }`
                                            : ""
                                        }`}
                                      </div>
                                      <div className="position-absolute end-0 top-0 mt-2 me-2">
                                        <i className="ri-arrow-down-s-line fs-3 text-muted"></i>
                                      </div>
                                      <div>
                                        <ErrorMessage
                                          name="roomCount"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                      <div>
                                        <ErrorMessage
                                          name="adultCount"
                                          component={InputErrorMsg}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {showRoomAndGuestDropdown && (
                                    <div
                                      className="position-absolute p-md-0"
                                      style={{ zIndex: "5", top: "60px" }}
                                    >
                                      <div className="p-2 card shadow-lg border border-1">
                                        {/* Room Count */}
                                        <div className="row p-2">
                                          <div className="col-7 col-lg-7">
                                            <div>Rooms</div>
                                          </div>
                                          <div className="col-5 text-end">
                                            <div className="row align-items-center">
                                              <div className="col text-center pe-0">
                                                <div>
                                                  <i
                                                    onClick={() =>
                                                      handleRoomCountChange(
                                                        "remove"
                                                      )
                                                    }
                                                    className="ri-indeterminate-circle-line fs-5 cursor-pointer"
                                                  ></i>
                                                </div>
                                              </div>
                                              <div className="col text-center fw-semibold">
                                                {roomGuests.length}
                                              </div>
                                              <div className="col text-center ps-0">
                                                <div>
                                                  <i
                                                    onClick={() =>
                                                      handleRoomCountChange(
                                                        "add"
                                                      )
                                                    }
                                                    className="ri-add-circle-line fs-5 cursor-pointer"
                                                  ></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {roomGuests.map(
                                          (roomGuest, roomGuestIdx) => (
                                            <div
                                              key={`room-guest-${roomGuestIdx}`}
                                              className="p-2"
                                            >
                                              {/* Adult Count */}
                                              <div className="row p-2">
                                                <div className="col-7 col-lg-7 font-700">
                                                  Room {roomGuestIdx + 1}
                                                </div>
                                              </div>
                                              <div className="row p-2">
                                                <div className="col-7 col-lg-7">
                                                  <div>Adults 12+ Yrs</div>
                                                </div>
                                                <div className="col-5 text-end">
                                                  <div className="row align-items-center">
                                                    <div className="col text-center pe-0">
                                                      <div>
                                                        <i
                                                          onClick={() =>
                                                            handleAdultCountChange(
                                                              "remove",
                                                              roomGuestIdx
                                                            )
                                                          }
                                                          className="ri-indeterminate-circle-line fs-5 cursor-pointer"
                                                        ></i>
                                                      </div>
                                                    </div>
                                                    <div className="col text-center fw-semibold">
                                                      {roomGuest.adults.length}
                                                    </div>
                                                    <div className="col text-center ps-0">
                                                      <div>
                                                        <i
                                                          onClick={() =>
                                                            handleAdultCountChange(
                                                              "add",
                                                              roomGuestIdx
                                                            )
                                                          }
                                                          className="ri-add-circle-line fs-5 cursor-pointer"
                                                        ></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* Children Count */}
                                              <div className="row p-2">
                                                <div className="col-7 col-lg-7">
                                                  <div>Children 2 - 12 Yrs</div>
                                                </div>
                                                <div className="col-5 text-end">
                                                  <div className="row align-items-center">
                                                    <div className="col text-center pe-0">
                                                      <div>
                                                        <i
                                                          onClick={() =>
                                                            handleChildrenCountChange(
                                                              "remove",
                                                              roomGuestIdx
                                                            )
                                                          }
                                                          className="ri-indeterminate-circle-line fs-5"
                                                        ></i>
                                                      </div>
                                                    </div>
                                                    <div className="col text-center fw-semibold">
                                                      {
                                                        roomGuest.children
                                                          .length
                                                      }
                                                    </div>
                                                    <div className="col text-center ps-0">
                                                      <div>
                                                        <i
                                                          onClick={() =>
                                                            handleChildrenCountChange(
                                                              "add",
                                                              roomGuestIdx
                                                            )
                                                          }
                                                          className="ri-add-circle-line fs-5"
                                                        ></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="p-2">
                                                Please select your child's age
                                                at check-in to help us find the
                                                best room types and discounts
                                                for your needs.
                                              </div>
                                              {roomGuest.children.length > 0 &&
                                                roomGuest.children.map(
                                                  (child, childIdx) => (
                                                    <div
                                                      key={`child-${childIdx}`}
                                                      className="row p-2 pt-0 align-items-center"
                                                    >
                                                      <div className="col-6 col-lg-6">
                                                        <div>
                                                          AGE: Child{" "}
                                                          {childIdx + 1}
                                                        </div>
                                                      </div>
                                                      <div className="col text-end">
                                                        <select
                                                          className="form-select border-0 text-primary fw-normal"
                                                          style={{
                                                            padding:
                                                              ".2rem 1.4rem .2rem .1rem",
                                                            textAlign: "right",
                                                            backgroundPosition:
                                                              "right .5rem",
                                                          }}
                                                        >
                                                          {[
                                                            2, 3, 4, 5, 6, 7, 8,
                                                            9, 10, 11, 12,
                                                          ].map(function (
                                                            childAge,
                                                            childAgeIdx
                                                          ) {
                                                            return (
                                                              <option
                                                                key={`child-age-${childAgeIdx}`}
                                                                value={childAge}
                                                              >
                                                                {childAge} years
                                                                old
                                                              </option>
                                                            );
                                                          })}
                                                        </select>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              <hr className="my-2" />
                                            </div>
                                          )
                                        )}

                                        <div className="text-end my-2 pe-2">
                                          <button
                                            type="button"
                                            onClick={() =>
                                              setShowRoomAndGuestDropdown(false)
                                            }
                                            className="btn btn-info btn-sm"
                                          >
                                            Done!
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-1 col-12 px-md-1 pe-md-2 mt-2 mt-md-0">
                                <button
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="btn btn-success waves-effect waves-light"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    fontSize: "16px",
                                  }}
                                >
                                  <i className="mdi mdi-magnify font-20"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>

            <div className="row mt-0 mt-md-3">
              {isLoading && (
                <>
                  <div className="col-lg-3 d-md-block d-md-block d-none">
                    <div className="card border-shadow">
                      <div className="placeholder-glow">
                        <div
                          className="placeholder bg-success w-100 rounded-0"
                          style={{ height: "90px" }}
                        ></div>
                      </div>
                      <div className="accordion accordion-flush filter-accordion">
                        <div className="card-body border-bottom">
                          <div>
                            <div className="placeholder-glow">
                              <div className="text-muted text-uppercase fs-12 fw-medium">
                                Room Facilities & Services
                              </div>
                            </div>
                            <div className="row mt-2 placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-50"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-25"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-50"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-75"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-50"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div>
                              <div className="text-secondary mt-2 fw-medium text-muted">
                                Show All
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion accordion-flush filter-accordion">
                        <div className="card-body border-bottom">
                          <div>
                            <p className="text-muted text-uppercase fs-12 fw-medium mb-2">
                              Property Type
                            </p>
                            <div className="row mt-2 placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-50"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-25"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-50"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-75"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-50"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-25"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div>
                              <div className="text-secondary mt-2 fw-medium text-muted">
                                Show All
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion accordion-flush filter-accordion">
                        <div className="card-body border-bottom">
                          <div>
                            <p className="text-muted text-uppercase fs-12 fw-medium mb-2">
                              Star Rating
                            </p>
                            <ul className="list-unstyled mb-0 filter-list">
                              <li>
                                <div className="row placeholder-glow">
                                  <div className="col-sm-9 col-9">
                                    <span
                                      className="placeholder rounded-5"
                                      style={{ width: "20px" }}
                                    ></span>
                                    <span className="placeholder w-25"></span>
                                  </div>
                                  <div className="col-sm-3 col-3 text-end">
                                    <div className="placeholder w-25 rounded-5"></div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="row placeholder-glow">
                                  <div className="col-sm-9 col-9">
                                    <span
                                      className="placeholder rounded-5"
                                      style={{ width: "20px" }}
                                    ></span>
                                    <span className="placeholder w-25"></span>
                                  </div>
                                  <div className="col-sm-3 col-3 text-end">
                                    <div className="placeholder w-25 rounded-5"></div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="row placeholder-glow">
                                  <div className="col-sm-9 col-9">
                                    <span
                                      className="placeholder rounded-5"
                                      style={{ width: "20px" }}
                                    ></span>
                                    <span className="placeholder w-25"></span>
                                  </div>
                                  <div className="col-sm-3 col-3 text-end">
                                    <div className="placeholder w-25 rounded-5"></div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="row placeholder-glow">
                                  <div className="col-sm-9 col-9">
                                    <span
                                      className="placeholder rounded-5"
                                      style={{ width: "20px" }}
                                    ></span>
                                    <span className="placeholder w-25"></span>
                                  </div>
                                  <div className="col-sm-3 col-3 text-end">
                                    <div className="placeholder w-25 rounded-5"></div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="row placeholder-glow">
                                  <div className="col-sm-9 col-9">
                                    <span
                                      className="placeholder rounded-5"
                                      style={{ width: "20px" }}
                                    ></span>
                                    <span className="placeholder w-25"></span>
                                  </div>
                                  <div className="col-sm-3 col-3 text-end">
                                    <div className="placeholder w-25 rounded-5"></div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="accordion accordion-flush filter-accordion">
                        <div className="card-body border-bottom">
                          <div>
                            <p className="text-muted text-uppercase fs-12 fw-medium mb-2">
                              Location
                            </p>
                            <div className="row mt-2 placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-50"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-25"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-50"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-75"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-50"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-25"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div className="row placeholder-glow">
                              <div className="col-sm-9 col-9">
                                <span
                                  className="placeholder rounded-5"
                                  style={{ width: "20px" }}
                                ></span>
                                <span className="placeholder w-75"></span>
                              </div>
                              <div className="col-sm-3 col-3 text-end">
                                <div className="placeholder w-25 rounded-5"></div>
                              </div>
                            </div>
                            <div>
                              <div className="text-secondary mt-2 fw-medium text-muted">
                                Show All
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="row mb-2">
                      <div className="col-lg-9 col-9">
                        <div className="row placeholder-glow">
                          <div className="col-sm-3 col-3 pe-0">
                            <span className="placeholder placeholder-lg w-100 h-75 rounded-0"></span>
                          </div>
                          <div className="col-sm-3 col-3 pe-0">
                            <span className="placeholder placeholder-lg w-100 h-75 rounded-0"></span>
                          </div>
                          <div className="col-sm-3 col-3">
                            <span className="placeholder placeholder-lg w-100 h-75 rounded-0"></span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-3">
                        <div className="row placeholder-glow">
                          <div className="col-sm-6 col-6 pe-0">
                            <span className="placeholder placeholder-lg w-100 h-75 rounded-0"></span>
                          </div>
                          <div className="col-sm-6 col-6 ps-2">
                            <span className="placeholder placeholder-lg w-100 h-75 rounded-0"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container mb-2 card">
                      <div className="row align-items-center">
                        <div className="col-lg-3 px-0">
                          <div className="placeholder-glow">
                            <div className="placeholder placeholder placeholder-lg w-100 rounded-0 hotel-img-important-height"></div>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="row py-2 ps-0 align-items-center">
                            <div className="col-lg-9 col-8 border-2 border-end">
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-25 p-2 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-50 h-75 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-75 h-75 rounded-0"></div>
                              </div>
                              <div className="mt-1">
                                <div className="mb-2 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25 h-75 rounded-0 p-2"></div>
                                </div>
                                <div className="d-flex gap-1 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-4 text-end">
                              <div className="d-inline">
                                <div className="placeholder-glow">
                                  <div className="placeholder w-50 rounded-0"></div>
                                </div>
                                <div className="my-2 placeholder-glow">
                                  <div className="placeholder p-2 w-75 rounded-0"></div>
                                </div>
                                <div className="placeholder-glow mb-1">
                                  <div className="placeholder w-50 p-1 rounded-0"></div>
                                </div>
                              </div>
                              <div>
                                <div className="placeholder-glow">
                                  <div className="placeholder p-3 w-100 rounded-0"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container mb-2 card">
                      <div className="row align-items-center">
                        <div className="col-lg-3 px-0">
                          <div className="placeholder-glow">
                            <div className="placeholder placeholder placeholder-lg w-100 rounded-0 hotel-img-important-height"></div>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="row py-2 ps-0 align-items-center">
                            <div className="col-lg-9 col-8 border-2 border-end">
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-25 p-2 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-50 h-75 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-75 h-75 rounded-0"></div>
                              </div>
                              <div className="mt-1">
                                <div className="mb-2 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25 h-75 rounded-0 p-2"></div>
                                </div>
                                <div className="d-flex gap-1 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-4 text-end">
                              <div className="d-inline">
                                <div className="placeholder-glow">
                                  <div className="placeholder w-50 rounded-0"></div>
                                </div>
                                <div className="my-2 placeholder-glow">
                                  <div className="placeholder p-2 w-75 rounded-0"></div>
                                </div>
                                <div className="placeholder-glow mb-1">
                                  <div className="placeholder w-50 p-1 rounded-0"></div>
                                </div>
                              </div>
                              <div>
                                <div className="placeholder-glow">
                                  <div className="placeholder p-3 w-100 rounded-0"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container mb-2 card">
                      <div className="row align-items-center">
                        <div className="col-lg-3 px-0">
                          <div className="placeholder-glow">
                            <div className="placeholder placeholder placeholder-lg w-100 rounded-0 hotel-img-important-height"></div>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="row py-2 ps-0 align-items-center">
                            <div className="col-lg-9 col-8 border-2 border-end">
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-25 p-2 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-50 h-75 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-75 h-75 rounded-0"></div>
                              </div>
                              <div className="mt-1">
                                <div className="mb-2 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25 h-75 rounded-0 p-2"></div>
                                </div>
                                <div className="d-flex gap-1 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-4 text-end">
                              <div className="d-inline">
                                <div className="placeholder-glow">
                                  <div className="placeholder w-50 rounded-0"></div>
                                </div>
                                <div className="my-2 placeholder-glow">
                                  <div className="placeholder p-2 w-75 rounded-0"></div>
                                </div>
                                <div className="placeholder-glow mb-1">
                                  <div className="placeholder w-50 p-1 rounded-0"></div>
                                </div>
                              </div>
                              <div>
                                <div className="placeholder-glow">
                                  <div className="placeholder p-3 w-100 rounded-0"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container mb-2 card d-none d-md-block">
                      <div className="row align-items-center">
                        <div className="col-lg-3 px-0">
                          <div className="placeholder-glow">
                            <div className="placeholder placeholder placeholder-lg w-100 rounded-0 hotel-img-important-height"></div>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="row py-2 ps-0 align-items-center">
                            <div className="col-lg-9 col-8 border-2 border-end">
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-25 p-2 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-50 h-75 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-75 h-75 rounded-0"></div>
                              </div>

                              <div className="mt-1">
                                <div className="mb-2 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25 h-75 rounded-0 p-2"></div>
                                </div>
                                <div className="d-flex gap-1 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-4 text-end">
                              <div className="d-inline">
                                <div className="placeholder-glow">
                                  <div className="placeholder w-50 rounded-0"></div>
                                </div>
                                <div className="my-2 placeholder-glow">
                                  <div className="placeholder p-2 w-75 rounded-0"></div>
                                </div>
                                <div className="placeholder-glow mb-1">
                                  <div className="placeholder w-50 p-1 rounded-0"></div>
                                </div>
                              </div>
                              <div>
                                <div className="placeholder-glow">
                                  <div className="placeholder p-3 w-100 rounded-0"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container mb-2 card d-none d-md-block">
                      <div className="row align-items-center">
                        <div className="col-lg-3 px-0">
                          <div className="placeholder-glow">
                            <div className="placeholder placeholder placeholder-lg w-100 rounded-0 hotel-img-important-height"></div>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="row py-2 ps-0 align-items-center">
                            <div className="col-lg-9 col-8 border-2 border-end">
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-25 p-2 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-50 h-75 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-75 h-75 rounded-0"></div>
                              </div>

                              <div className="mt-1">
                                <div className="mb-2 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25 h-75 rounded-0 p-2"></div>
                                </div>
                                <div className="d-flex gap-1 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-4 text-end">
                              <div className="d-inline">
                                <div className="placeholder-glow">
                                  <div className="placeholder w-50 rounded-0"></div>
                                </div>
                                <div className="my-2 placeholder-glow">
                                  <div className="placeholder p-2 w-75 rounded-0"></div>
                                </div>
                                <div className="placeholder-glow mb-1">
                                  <div className="placeholder w-50 p-1 rounded-0"></div>
                                </div>
                              </div>
                              <div>
                                <div className="placeholder-glow">
                                  <div className="placeholder p-3 w-100 rounded-0"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container mb-2 card d-none d-md-block">
                      <div className="row align-items-center">
                        <div className="col-lg-3 px-0">
                          <div className="placeholder-glow">
                            <div className="placeholder placeholder placeholder-lg w-100 rounded-0 hotel-img-important-height"></div>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="row py-2 ps-0 align-items-center">
                            <div className="col-lg-9 col-8 border-2 border-end">
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-25 p-2 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-50 h-75 rounded-0"></div>
                              </div>
                              <div className="placeholder-glow">
                                <div className="placeholder placeholder-lg w-75 h-75 rounded-0"></div>
                              </div>

                              <div className="mt-1">
                                <div className="mb-2 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25 h-75 rounded-0 p-2"></div>
                                </div>
                                <div className="d-flex gap-1 placeholder-glow">
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                  <div className="placeholder placeholder-lg w-25"></div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-4 text-end">
                              <div className="d-inline">
                                <div className="placeholder-glow">
                                  <div className="placeholder w-50 rounded-0"></div>
                                </div>
                                <div className="my-2 placeholder-glow">
                                  <div className="placeholder p-2 w-75 rounded-0"></div>
                                </div>
                                <div className="placeholder-glow mb-1">
                                  <div className="placeholder w-50 p-1 rounded-0"></div>
                                </div>
                              </div>
                              <div>
                                <div className="placeholder-glow">
                                  <div className="placeholder p-3 w-100 rounded-0"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {!isLoading && (
                <>
                  {/* Flight sorting section sidebar hides on mobile view. */}
                  <div className="col-lg-3 d-md-block d-md-block d-none">
                    <div className="card border-shadow">
                      <div className="card-body border-bottom flr_note_bck">
                        <div className="bell_icon">
                          <i className="bx bx-bell"></i>
                        </div>
                        <h5 style={{ color: "white" }}>Filters</h5>
                        <div className="ds_width_95">
                          Effortlessly locate your hotel property by utilizing
                          the filter options below.
                        </div>
                      </div>

                      <div className="accordion accordion-flush filter-accordion">
                        <div className="card-body border-bottom">
                          <div>
                            <input
                              type="text"
                              className="form-control fs-13 text-muted"
                              id="hotel_search_keyword"
                              onChange={(e) => {
                                const keyword = e.target.value;
                                setFilterByKeyword(keyword);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Hotel Sort by Prices */}
                      <HotelPriceRangeFilter
                        minPriceFromHotel={minPrice}
                        maxPriceFromHotel={maxPrice}
                        setMinPriceFilter={(val: any) =>
                          setSelectedMinPrice(val)
                        }
                        setMaxPriceFilter={(val: any) =>
                          setSelectedMaxPrice(val)
                        }
                      />
                      {/* Hotel Sort by Facilities */}
                      <HotelFilterList
                        title="Room Facilities & Services"
                        items={amenities ?? []}
                        setSelectedItems={(val: any) =>
                          setSelectedAmenities(val)
                        }
                      />
                      {/* Hotel Sort by Property */}
                      <HotelFilterList
                        title="Property Type"
                        items={propertyTypes ?? []}
                        setSelectedItems={(val: any) =>
                          setSelectedPropertyTypes(val)
                        }
                      />
                      {/* Hotel Sort by Star Rating */}
                      <HotelFilterList
                        title="Star Rating"
                        items={ratings ?? []}
                        setSelectedItems={(val: any) => setSelectedRatings(val)}
                        isRating={true}
                      />
                      {/* Hotel Sort by Location */}
                      <HotelFilterList
                        title="Location"
                        items={cities ?? []}
                        setSelectedItems={(val: any) =>
                          setSelectedLocations(val)
                        }
                      />
                    </div>
                  </div>

                  {/* All Flight List section */}
                  <div className="col-lg-9">
                    <div className="d-flex align-items-center">
                      <div className="col-lg-9">
                        <div className="d-flex mb-1">
                          <div
                            className="p-1 px-3 text-white rounded-start "
                            style={{ background: "#546679" }}
                          >
                            <i className="fa-solid fa-door-open text-white ms-n2"></i>{" "}
                            {totalResults ?? 0} hotel
                            {totalResults > 1 ? "s" : ""} found in{" "}
                            {searchKeyword}
                          </div>
                          <div
                            className="d-flex bg-white rounded-end "
                            style={{ border: "1px solid #54667969" }}
                          >
                            <div className="p-1 px-3 border-1 border-end cursor-pointer">
                              Recommended
                            </div>
                            <div className="p-1 px-3 cursor-pointer d-none d-md-block">
                              TripAdvisor Rating
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 text-end d-none d-md-block">
                        <div className="d-inline-flex">
                          <div className="px-2 border-2 border-end cursor-pointer">
                            Previous Day
                          </div>
                          <div className="px-2 cursor-pointer">Next Day</div>
                        </div>
                      </div>
                    </div>

                    {/* Flight List Non-stop */}
                    {hotelResults.map((hVal: HotelResult, hIdx: number) => {
                      return (
                        <div
                          key={`hotel-result-${hIdx}`}
                          className="container mb-2 card"
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-3 px-0">
                              <div className="live-preview">
                                <div
                                  id={`hotel_result_${hIdx}`}
                                  className="carousel slide"
                                >
                                  <div className="carousel-inner">
                                    <div className="carousel-item active">
                                      <img
                                        src={hVal.main_image}
                                        className="rounded-start p-0 w-100 img-fluid"
                                        alt="One Slide"
                                        style={{ height: "150px" }}
                                        onError={() => {
                                          return fallbackImage;
                                        }}
                                      />
                                    </div>
                                    {hVal.extra_images.map((imgVal, imgIdx) => {
                                      return (
                                        <div
                                          key={`extra-image-${imgIdx}`}
                                          className="carousel-item"
                                        >
                                          <img
                                            src={imgVal}
                                            className="rounded-start p-0 w-100 img-fluid"
                                            alt="Two Slide"
                                            style={{ height: "150px" }}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div
                                    className="position-absolute bottom-0 w-100 start-0 px-3 py-4"
                                    style={{
                                      background:
                                        "linear-gradient(to bottom, rgba(6, 27, 65, 0) 0%, rgb(6 27 65 / 43%) 100%)",
                                    }}
                                  ></div>
                                  <div className="position-absolute bottom-0 start-50 mb-3 ms-n5">
                                    <StarRating
                                      rating={hVal?.current_rating ?? 0}
                                    />
                                  </div>
                                  <button
                                    className="carousel-control-prev"
                                    type="button"
                                    data-bs-target={`#hotel_result_${hIdx}`}
                                    data-bs-slide="prev"
                                  >
                                    <span
                                      className="carousel-control-prev-icon w-50"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">
                                      Previous
                                    </span>
                                  </button>
                                  <button
                                    className="carousel-control-next"
                                    type="button"
                                    data-bs-target={`#hotel_result_${hIdx}`}
                                    data-bs-slide="next"
                                  >
                                    <span
                                      className="carousel-control-next-icon w-50 "
                                      aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">
                                      Next
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-9">
                              <div className="row py-2 ps-0 align-items-center">
                                <div className="col-lg-9 col-8 border-2 border-end">
                                  <div>
                                    <img
                                      src={hVal.rating_logo ?? ""}
                                      alt=""
                                      style={{ width: "85px" }}
                                    />
                                    <span className="ms-1 fw-semibold">
                                      {hVal.overall_review.numRv ?? 0} Reviews
                                    </span>
                                  </div>
                                  <div className="fs-4 fw-semibold text-success text-truncate w-100">
                                    {hVal.name}
                                  </div>
                                  <div className="fs-14 fw-medium text-truncate w-100">
                                    <FontAwesomeIcon
                                      icon={faLocationDot}
                                      className="text-warning"
                                    />{" "}
                                    {hVal.address}
                                  </div>

                                  <div className="mt-1">
                                    <div className="mb-1 text-muted">
                                      Property Facilities & Services:
                                    </div>
                                    <div className="d-flex gap-1 text-truncate w-100">
                                      {hVal.facilities_and_services
                                        .slice(0, 4)
                                        .map((amenity, amenityIdx) => {
                                          return (
                                            <>
                                              <div
                                                key={`amenity-${amenityIdx}`}
                                                className="px-1 border border-1 rounded-1 text-bg-light"
                                              >
                                                {amenity}
                                              </div>
                                            </>
                                          );
                                        })}

                                      {hVal.facilities_and_services.length >
                                        4 && (
                                        <div className="px-1 border border-1 rounded-1 text-bg-light">
                                          +
                                          {hVal.facilities_and_services.length -
                                            4}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-3 col-4 text-end">
                                  <div className="d-inline">
                                    <div className="fs-11 mb-n1">
                                      Starting from
                                    </div>
                                    <div className="d-inline-flex align-items-center">
                                      <div className="fw-medium me-1 d-md-block d-none">
                                        ₱
                                      </div>
                                      <div className="fs-3 fw-bold text-danger mb-n1">
                                        {hVal.lowest_price}
                                      </div>
                                    </div>
                                    <div className="fs-13">
                                      {numberOfNights} night
                                      {numberOfNights > 1 && `s`},{" "}
                                      {getTotalAdults(roomGuests)} adult
                                      {getTotalAdults(roomGuests) > 1 && `s`}
                                      {getTotalChildren(roomGuests) > 0 &&
                                        `, ${getTotalChildren(roomGuests)} ${
                                          getTotalChildren(roomGuests) === 1
                                            ? "child"
                                            : "children"
                                        }`}
                                    </div>
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-success w-md waves-effect waves-light py-1 mt-1"
                                      onClick={() => navigateToSelectRoom(hVal)}
                                    >
                                      <span className="d-md-block d-none">
                                        {" "}
                                        Select Rooms
                                      </span>
                                      <span className="d-block d-md-none">
                                        Rooms
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelBooking;
