import { FC, useEffect, useState } from "react";
import { ToasterMessage } from "../utils/Toaster";
import PageTopbar from "../includes/PageTopbar";
import AppMenu from "../includes/AppMenu";
import { HotelResult } from "../types/Types";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { directBookHotel, getHotelRepriceData } from "../request/api";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";
import HotelPaymentConfirmationModal from "../modal/HotelPaymentConfirmationModal";
import ResponseModal from "../modal/ResponseModal";
import { setMyHotel } from "../redux/actions";
import { cloneDeep } from "lodash";
import { DirectHotelBookingFormSchema } from "../formValidation/validation";
import InputErrorMsg from "../utils/InputErrorMsg";
import {
  adultSalutationOptions,
  childSalutationOptions,
} from "./CreateEVoucher";
import HotelSidebar from "../includes/hotel/HotelSidebar";
import { SpinnerLoader } from "../utils/Loading";
import { Message, useToaster } from "rsuite";

export const initialHotelResultData = {
  main_image: "",
  extra_images: [],
  name: "",
  address: "",
  description: "",
  facilities_and_services: [],
  room_types: [],
  overall_review: {
    rtg: 0,
    numRv: "0",
    reviews: [],
  },
  current_rating: 0,
  rating_logo: "",
  location_longitude: "",
  location_latitude: "",
  check_in_time: "",
  check_out_time: "",
  search_details: {
    checkIn: "",
    checkOut: "",
    checkInDate: moment().add(1, "days").startOf("day").toDate(),
    checkOutDate: moment().add(2, "days").startOf("day").toDate(),
    roomNights: 1,
    numberOfNights: 1,
    regionId: "",
    hotelId: "",
    regionName: "",
    rooms: [],
    city: "",
    country: "",
    bookingKey: "",
    deliveryData: {
      email: "",
      mobile: "",
    },
    roomGuests: [
      {
        adults: [
          {
            salutation: "Mr",
            first_name: "",
            last_name: "",
          },
        ],
        children: [],
      },
    ],
    roomType: {
      room_name: "",
      room_rate_per_night: 0,
      meal_plan: "",
      minimum_night: 1,
      is_refundable: false,
      cancellation_policies: "",
      itinerary_key: "",
      max_adults: 1,
      max_children: 0,
      important_infos: [],
      total_selling_price: 0,
      total_fees_and_taxes: 0,
      agent_fee: 0,
    },
    specialRequest: "",
  },
  hotel_id: "",
  property_type: "",
  lowest_price: "0.00",
  is_domestic: false,
  city: [],
};

const GuestDetails: FC = () => {
  const dispatch = useDispatch();
  const toaster = useToaster();
  const { myHotel } = useSelector((state: any) => state);
  const [hotelResultData, setHotelResultData] = useState<HotelResult>(
    initialHotelResultData
  );
  const [isCancellationPolicyAgreed, setIsCancellationPolicyAgreed] =
    useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleBookHotel = async (values: HotelResult) => {
    setIsLoading(true);

    await directBookHotel(dispatch, values)
      .then((res) => {
        setMessage(res?.result?.message);
        setIsSuccess(res?.result?.success);
        setIsResponseModalOpen(true);

        setTimeout(() => {
          window.location.href = "/hotel-booking-history-report";
        }, 500);
      })
      .catch((err) => {
        setIsSuccess(err?.response?.data?.result?.success);
        if ([400, 404, 500].includes(err?.response?.status)) {
          const sanitizedHtml = DOMPurify.sanitize(
            err?.response?.data?.result?.message ?? "Something went wrong."
          );
          Swal.fire({
            icon: "warning",
            html: sanitizedHtml,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const showBookingConfirmationModal = async (values: any) => {
    // Deep clone the hotelResultData before updating it
    const updatedReference = cloneDeep(hotelResultData);

    // Now modify the cloned object with new values
    updatedReference.search_details = {
      ...values,
      deliveryData: values.deliveryData,
      roomGuests: values.roomGuests,
      specialRequest: values.specialRequest ?? "",
    };

    setHotelResultData(updatedReference);

    localStorage.setItem(
      "savedDirectHotelFormDetails",
      JSON.stringify(updatedReference)
    ); // Save the selected hotel details to the local storage

    await getHotelRepriceData(dispatch, {
      itineraryKey: values.roomType.itinerary_key,
      total_nights: values.numberOfNights,
      check_in_date: updatedReference.search_details.checkIn,
    })
      .then((res: any) => {
        const repriceData = res?.result?.data;

        setShowPaymentModal(true);

        dispatch(
          setMyHotel({
            savedDirectHotelFormDetails: {
              ...updatedReference,
              search_details: {
                ...updatedReference.search_details,
                bookingKey: repriceData.booking_key,
                deliveryData: updatedReference.search_details.deliveryData,
                roomGuests: updatedReference.search_details.roomGuests,
                specialRequest: updatedReference.search_details.specialRequest,
                roomType: {
                  ...updatedReference.search_details.roomType,
                  room_rate_per_night: repriceData.new_room_rate_per_night,
                  cancellation_policies: repriceData.cancellation_policies,
                  important_infos: repriceData.important_infos,
                  total_selling_price: repriceData.new_total_selling_price,
                  total_fees_and_taxes: repriceData.new_total_fees_and_taxes,
                  agent_fee: repriceData.agent_fee,
                },
              },
            },
          })
        );
      })
      .catch((err: any) => {
        if ([400, 404, 500].includes(err?.response?.status)) {
          const sanitizedHtml = DOMPurify.sanitize(
            err?.response?.data?.result?.message ?? "Something went wrong."
          );
          Swal.fire({
            icon: "warning",
            html: sanitizedHtml,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
  };

  const normalizeGuestDetailsErrors = (field: string) => {
    return field
      .replace("deliveryData.email", "Email address")
      .replace("deliveryData.mobile", "Mobile number")
      .replace(/^deliveryData\.(\d+)/, (_, num) => `Room ${parseInt(num) + 1}`)
      .replace(/^roomGuests\.(\d+)/, (_, num) => `Room ${parseInt(num) + 1}`) // Convert `roomGuests.0` to `Adult Guest 1`
      .replace(/\.adults\.(\d+)/, (_, num) => `: Adult ${parseInt(num) + 1}`) // Convert `.adults.0` to `: Adult 1`
      .replace(/\.children\.(\d+)/, (_, num) => `: Child ${parseInt(num) + 1}`) // Convert `.children.0` to `: Child 1`
      .replace(/\./g, " ") // Replace remaining dots with spaces
      .replace(/_/g, " ") // Convert underscores to spaces
      .replace(/\b[a-z]/g, (char) => char.toUpperCase()); // Capitalize each word
  };

  useEffect(() => {
    const storedHotelDetails = JSON.parse(
      localStorage.getItem("savedDirectHotelFormDetails") ?? "{}"
    );

    if (!storedHotelDetails || Object.keys(storedHotelDetails).length === 0) {
      console.warn("No valid hotel details found in local storage.");
      return;
    }

    setHotelResultData({ ...storedHotelDetails });
  }, []);

  return (
    <div id="layout-wrapper">
      {isLoading && <SpinnerLoader />}
      {!isLoading && (
        <>
          <ToasterMessage />
          <PageTopbar />
          <AppMenu />
          <ResponseModal
            showModal={isResponseModalOpen}
            handleClose={() => setIsResponseModalOpen(false)}
            message={message}
            isSuccess={isSuccess}
          />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid p-0 ps-md-2 pe-md-3">
                <div className="row">
                  <div className="col">
                    <div className="h-100">
                      <Formik
                        initialValues={hotelResultData.search_details}
                        validationSchema={DirectHotelBookingFormSchema}
                        onSubmit={showBookingConfirmationModal}
                        enableReinitialize={true}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          isSubmitting,
                          setSubmitting,
                          setFieldValue,
                          setFieldError,
                          setFieldTouched,
                          validateForm,
                        }) => {
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-lg-8 order-lg-1 order-2">
                                  <div className="order-md-2 order-2 order">
                                    <div className="py-2 pt-0">
                                      <h5 className=" mb-1">
                                        Information about guests
                                      </h5>
                                      <p className="mb-0">
                                        Guest names must match the valid ID
                                        which will be used at check-in.
                                      </p>
                                    </div>
                                    {values.roomGuests.map(
                                      (roomGuest, roomGuestIdx) => {
                                        return (
                                          <div
                                            key={`room-guest-${
                                              roomGuestIdx + 1
                                            }`}
                                            className="card mb-3 border-shadow"
                                          >
                                            <div className="card-header">
                                              <h6 className="card-title mb-0">
                                                Room {roomGuestIdx + 1}
                                              </h6>
                                            </div>
                                            <div className="card-body">
                                              {roomGuest.adults.map(
                                                (adult, adultIdx) => (
                                                  <div
                                                    key={`adult-guest-${
                                                      adultIdx + 1
                                                    }`}
                                                  >
                                                    <h5 className="mb-2">
                                                      Guest (Adult){" "}
                                                      {adultIdx + 1}
                                                    </h5>
                                                    <div className="row">
                                                      <div className="col-lg-2 col-5 pe-lg-0 mb-3 mt-2 mt-md-0">
                                                        <div className="form-floating">
                                                          <Field
                                                            as="select"
                                                            className={`form-select form-select-lg selc_gender pt-2 ${
                                                              errors?.roomGuests &&
                                                              (
                                                                errors
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.adults?.[
                                                                adultIdx
                                                              ]?.salutation &&
                                                              touched?.roomGuests &&
                                                              (
                                                                touched
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.adults?.[
                                                                adultIdx
                                                              ]?.salutation
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                            id={`roomGuests[${roomGuestIdx}].adults[${adultIdx}].salutation`}
                                                            name={`roomGuests[${roomGuestIdx}].adults[${adultIdx}].salutation`}
                                                            aria-label="form-select-lg example"
                                                            style={{
                                                              height:
                                                                "50px !important",
                                                            }}
                                                          >
                                                            <option
                                                              value="0"
                                                              disabled
                                                            >
                                                              Title
                                                            </option>
                                                            {adultSalutationOptions.map(
                                                              (
                                                                adultSalutationOption
                                                              ) => (
                                                                <option
                                                                  key={
                                                                    adultSalutationOption.label
                                                                  }
                                                                  value={
                                                                    adultSalutationOption.label
                                                                  }
                                                                >
                                                                  {
                                                                    adultSalutationOption.label
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </Field>
                                                          <ErrorMessage
                                                            name={`roomGuests[${roomGuestIdx}].adults[${adultIdx}].salutation`}
                                                            component={
                                                              InputErrorMsg
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-lg-5 mb-3">
                                                        <div className="form-floating input-group-lg">
                                                          <Field
                                                            type="text"
                                                            id={`roomGuests[${roomGuestIdx}].adults[${adultIdx}].first_name`}
                                                            name={`roomGuests[${roomGuestIdx}].adults[${adultIdx}].first_name`}
                                                            className={`form-control text-uppercase ${
                                                              errors?.roomGuests &&
                                                              (
                                                                errors
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.adults?.[
                                                                adultIdx
                                                              ]?.first_name &&
                                                              touched?.roomGuests &&
                                                              (
                                                                touched
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.adults?.[
                                                                adultIdx
                                                              ]?.first_name
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                            placeholder="First Name"
                                                            style={{
                                                              height:
                                                                "50px !important",
                                                            }}
                                                          />
                                                          <ErrorMessage
                                                            name={`roomGuests[${roomGuestIdx}].adults[${adultIdx}].first_name`}
                                                            component={
                                                              InputErrorMsg
                                                            }
                                                          />
                                                          <label
                                                            className=""
                                                            htmlFor="firstnamefloatingInput"
                                                          >
                                                            {" "}
                                                            First Name
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <div className="col-lg-5 ps-md-0 mb-0">
                                                        <div className="form-floating input-group-lg">
                                                          <Field
                                                            type="text"
                                                            id={`roomGuests[${roomGuestIdx}].adults[${adultIdx}].last_name`}
                                                            name={`roomGuests[${roomGuestIdx}].adults[${adultIdx}].last_name`}
                                                            className={`form-control text-uppercase ${
                                                              errors?.roomGuests &&
                                                              (
                                                                errors
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.adults?.[
                                                                adultIdx
                                                              ]?.last_name &&
                                                              touched?.roomGuests &&
                                                              (
                                                                touched
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.adults?.[
                                                                adultIdx
                                                              ]?.last_name
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                            placeholder="Last Name"
                                                            style={{
                                                              height:
                                                                "50px !important",
                                                            }}
                                                          />
                                                          <ErrorMessage
                                                            name={`roomGuests[${roomGuestIdx}].adults[${adultIdx}].last_name`}
                                                            component={
                                                              InputErrorMsg
                                                            }
                                                          />
                                                          <label htmlFor="lastnamefloatingInput">
                                                            Last Name
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}

                                              {roomGuest.children.map(
                                                (child, childIdx) => (
                                                  <div
                                                    key={`child-guest-${childIdx}`}
                                                  >
                                                    <h5 className="mb-2">
                                                      Guest (Child){" "}
                                                      {childIdx + 1}
                                                    </h5>
                                                    <div className="row">
                                                      <div className="col-lg-2 col-5 pe-lg-0 mb-3 mt-2 mt-md-0">
                                                        <div className="form-floating">
                                                          <Field
                                                            as="select"
                                                            className={`form-select form-select-lg selc_gender pt-2 ${
                                                              errors?.roomGuests &&
                                                              (
                                                                errors
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.children?.[
                                                                childIdx
                                                              ]?.salutation &&
                                                              touched?.roomGuests &&
                                                              (
                                                                touched
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.children?.[
                                                                childIdx
                                                              ]?.salutation
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                            id={`roomGuests[${roomGuestIdx}].children[${childIdx}].salutation`}
                                                            name={`roomGuests[${roomGuestIdx}].children[${childIdx}].salutation`}
                                                            aria-label="form-select-lg example"
                                                            style={{
                                                              height:
                                                                "50px !important",
                                                            }}
                                                          >
                                                            <option
                                                              value="0"
                                                              disabled
                                                            >
                                                              Title
                                                            </option>
                                                            {childSalutationOptions.map(
                                                              (
                                                                childSalutationOption
                                                              ) => (
                                                                <option
                                                                  key={
                                                                    childSalutationOption.label
                                                                  }
                                                                  value={
                                                                    childSalutationOption.label
                                                                  }
                                                                >
                                                                  {
                                                                    childSalutationOption.label
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </Field>
                                                          <ErrorMessage
                                                            name={`roomGuests[${roomGuestIdx}].children[${childIdx}].salutation`}
                                                            component={
                                                              InputErrorMsg
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-lg-5 mb-3">
                                                        <div className="form-floating input-group-lg">
                                                          <Field
                                                            type="text"
                                                            id={`roomGuests[${roomGuestIdx}].children[${childIdx}].first_name`}
                                                            name={`roomGuests[${roomGuestIdx}].children[${childIdx}].first_name`}
                                                            className={`form-control text-uppercase ${
                                                              errors?.roomGuests &&
                                                              (
                                                                errors
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.children?.[
                                                                childIdx
                                                              ]?.first_name &&
                                                              touched?.roomGuests &&
                                                              (
                                                                touched
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.children?.[
                                                                childIdx
                                                              ]?.first_name
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                            placeholder="First Name"
                                                            style={{
                                                              height:
                                                                "50px !important",
                                                            }}
                                                          />
                                                          <ErrorMessage
                                                            name={`roomGuests[${roomGuestIdx}].children[${childIdx}].first_name`}
                                                            component={
                                                              InputErrorMsg
                                                            }
                                                          />
                                                          <label
                                                            className=""
                                                            htmlFor="firstnamefloatingInput"
                                                          >
                                                            {" "}
                                                            First Name
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <div className="col-lg-5 ps-md-0 mb-0">
                                                        <div className="form-floating input-group-lg">
                                                          <Field
                                                            type="text"
                                                            id={`roomGuests[${roomGuestIdx}].children[${childIdx}].last_name`}
                                                            name={`roomGuests[${roomGuestIdx}].children[${childIdx}].last_name`}
                                                            className={`form-control text-uppercase ${
                                                              errors?.roomGuests &&
                                                              (
                                                                errors
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.children?.[
                                                                childIdx
                                                              ]?.last_name &&
                                                              touched?.roomGuests &&
                                                              (
                                                                touched
                                                                  ?.roomGuests?.[
                                                                  roomGuestIdx
                                                                ] as any
                                                              )?.children?.[
                                                                childIdx
                                                              ]?.last_name
                                                                ? "is-invalid"
                                                                : ""
                                                            }`}
                                                            placeholder="Last Name"
                                                            style={{
                                                              height:
                                                                "50px !important",
                                                            }}
                                                          />
                                                          <ErrorMessage
                                                            name={`roomGuests[${roomGuestIdx}].children[${childIdx}].last_name`}
                                                            component={
                                                              InputErrorMsg
                                                            }
                                                          />
                                                          <label htmlFor="lastnamefloatingInput">
                                                            Last Name
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  <div className="card mb-3 border-shadow">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <h6 className="card-title mb-0">
                                            Special Requests{" "}
                                            <span className="fs-12 text-muted">
                                              (Optional)
                                            </span>
                                          </h6>
                                          <div>
                                            <div className="text-muted my-2">
                                              Your request will be sent to the
                                              property immediately after
                                              booking. but cannot guarantee to
                                              fulfill all requests.
                                            </div>
                                            <Field
                                              as="textarea"
                                              name="specialRequest"
                                              className="form-control"
                                              id="exampleFormControlTextarea5"
                                              rows={3}
                                              maxLength={2000}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="bg-white rounded-3 p-3 border-shadow">
                                    <h5 className=" mb-2">
                                      Contact Details{" "}
                                      <span className="fs-12 text-muted">
                                        (for E-voucher)
                                      </span>
                                    </h5>
                                    <div className="row gap-2 gap-md-0">
                                      <div className="col-lg-6">
                                        <div className="form-floating input-group-lg">
                                          <Field
                                            type="text"
                                            id={`deliveryData.email`}
                                            name={`deliveryData.email`}
                                            className={`form-control text-lowercase ${
                                              errors.deliveryData?.email &&
                                              touched.deliveryData?.email
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            placeholder="Enter your email"
                                            style={{
                                              height: "50px !important",
                                            }}
                                          />
                                          <ErrorMessage
                                            name="deliveryData.email"
                                            component={InputErrorMsg}
                                          />
                                          <label htmlFor="firstnamefloatingInput">
                                            Email Address
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="form-floating input-group-lg">
                                          <Field
                                            type="text"
                                            id={`deliveryData.mobile`}
                                            name={`deliveryData.mobile`}
                                            className={`form-control ${
                                              errors.deliveryData?.mobile &&
                                              touched.deliveryData?.mobile
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            placeholder="Enter your mobile number"
                                            style={{
                                              height: "50px !important",
                                            }}
                                          />
                                          <ErrorMessage
                                            name="deliveryData.mobile"
                                            component={InputErrorMsg}
                                          />
                                          <label htmlFor="deliveryData.mobile">
                                            Mobile Number
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-center bg-body rounded-3 mt-2">
                                      <div className="py-1 text-muted">
                                        <i className="fa-regular fa-circle-question"></i>{" "}
                                        Your Hotel Voucher will be sent to this
                                        email address
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-3 row align-items-center">
                                    <div className="col-lg-6"></div>
                                    <div className="col-lg-6 text-end">
                                      <button
                                        type="button"
                                        disabled={isSubmitting}
                                        className="btn btn-success bg-gradient waves-effect waves-light"
                                        onClick={(e) => {
                                          e.preventDefault();

                                          validateForm(values).then(
                                            (errors) => {
                                              if (
                                                Object.keys(errors).length > 0
                                              ) {
                                                // Flatten nested error objects into key-value pairs
                                                const flattenErrors = (
                                                  obj: any,
                                                  prefix = ""
                                                ): [string, string][] =>
                                                  Object.entries(obj).flatMap(
                                                    ([key, value]) => {
                                                      const fieldName = prefix
                                                        ? `${prefix}.${key}`
                                                        : key;
                                                      return typeof value ===
                                                        "string"
                                                        ? [[fieldName, value]]
                                                        : typeof value ===
                                                          "object"
                                                        ? flattenErrors(
                                                            value,
                                                            fieldName
                                                          )
                                                        : [];
                                                    }
                                                  );

                                                // Generate error messages dynamically
                                                const errorMessages =
                                                  flattenErrors(errors).map(
                                                    ([field, error]) => (
                                                      <li key={field}>
                                                        <strong>
                                                          {normalizeGuestDetailsErrors(
                                                            field
                                                          )}
                                                          :
                                                        </strong>{" "}
                                                        {error}
                                                      </li>
                                                    )
                                                  );

                                                toaster.push(
                                                  <Message
                                                    showIcon
                                                    type="error"
                                                    closable
                                                  >
                                                    <strong>
                                                      Validation Errors:
                                                    </strong>
                                                    <br />
                                                    {errorMessages}
                                                  </Message>,
                                                  {
                                                    placement: "topEnd",
                                                    duration: 30000,
                                                  }
                                                );

                                                setSubmitting(false); // Prevent submission
                                                return;
                                              }

                                              // Proceed to open modal if no errors
                                              showBookingConfirmationModal(
                                                values
                                              );
                                            }
                                          );
                                        }}
                                      >
                                        {isSubmitting
                                          ? "Loading..."
                                          : "Confirm Booking"}
                                      </button>
                                    </div>
                                  </div>
                                  <HotelPaymentConfirmationModal
                                    showModal={showPaymentModal}
                                    isCancellationPolicyAgreed={
                                      isCancellationPolicyAgreed
                                    }
                                    isLoading={isLoading}
                                    handleClose={() => {
                                      setIsCancellationPolicyAgreed(false);
                                      setShowPaymentModal(false);
                                    }}
                                    handleBookHotel={handleBookHotel}
                                    setIsCancellationPolicyAgreed={(val: any) =>
                                      setIsCancellationPolicyAgreed(val)
                                    }
                                  />
                                </div>

                                <div className="col-lg-4 mt-5 order-lg-2 order-1">
                                  <HotelSidebar
                                    isDirectHotel={true}
                                    checkInDateTime={
                                      values.checkIn +
                                      " " +
                                      hotelResultData.check_in_time
                                    }
                                    checkOutDateTime={
                                      values.checkOut +
                                      " " +
                                      hotelResultData.check_out_time
                                    }
                                    hotelName={hotelResultData?.name}
                                    hotelImage={hotelResultData?.main_image}
                                    hotelRating={
                                      hotelResultData?.overall_review?.rtg
                                    }
                                    hotelAddress={hotelResultData?.address}
                                    roomType={
                                      hotelResultData?.search_details?.roomType
                                        ?.room_name
                                    }
                                    directRoomInclusions={
                                      hotelResultData?.search_details?.roomType
                                        ?.meal_plan
                                    }
                                    totalRooms={
                                      (
                                        hotelResultData?.search_details
                                          ?.rooms ?? []
                                      ).length
                                    }
                                    adultGuests={hotelResultData?.search_details?.roomGuests?.flatMap(
                                      (guest) => guest.adults || []
                                    )}
                                    childGuests={hotelResultData?.search_details?.roomGuests?.flatMap(
                                      (guest) => guest.children || []
                                    )}
                                    sellingPrice={
                                      hotelResultData?.search_details?.roomType
                                        ?.total_selling_price
                                    }
                                    feesAndTaxes={
                                      hotelResultData?.search_details?.roomType
                                        ?.total_fees_and_taxes
                                    }
                                    isCancellationPolicyAgreed={
                                      isCancellationPolicyAgreed
                                    }
                                    showBookingConfirmationModal={
                                      showBookingConfirmationModal
                                    }
                                    directValues={values}
                                    isSubmitting={isSubmitting}
                                    setSubmitting={setSubmitting}
                                    setFieldValue={setFieldValue}
                                    setFieldError={setFieldError}
                                    setFieldTouched={setFieldTouched}
                                  />
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GuestDetails;
