import { io, Socket } from "socket.io-client";
import { Channel } from "../utils/Channel";

const SOCKET_SERVER_URL =
  process.env.REACT_APP_SOCKET_SERVER_URL || "http://localhost:5000";

class SocketService {
  private socket: Socket;
  private companyId: string | undefined; // or adjust the type according to your needs

  constructor(companyId: string | undefined) {
    this.companyId = companyId;
    this.socket = io(SOCKET_SERVER_URL, {
      transports: ["websocket"],
      query: {
        companyId: this.companyId,
      },
    });
  }

  connect() {
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  on(eventName: string, callback: (...args: any[]) => void) {
    this.socket.on(eventName, callback);
  }

  broadCast(eventName: string, data: any) {
    this.socket.emit(Channel.SEND_BROADCAST_MESSAGE, { eventName, data });
  }

  private(eventName: string, data: any, to: string) {
    this.socket.emit(Channel.SEND_PRIVATE_MESSAGE, { to, eventName, data });
  }
}

export default SocketService;
