import { Button, Modal } from "react-bootstrap"
import { RiCheckboxCircleLine, RiCloseCircleLine } from 'react-icons/ri';

interface ReturnModalInterface {
  showModal: boolean;
  handleClose: () => void;
  message: string | undefined;
  isSuccess: boolean;
}

const ResponseModal: React.FC<ReturnModalInterface> = ({
  showModal,
  handleClose,
  message,
  isSuccess
}) => {
  return (
    <Modal show={showModal} onHide={handleClose} centered backdrop="static">
      <Modal.Body className="text-center p-5">
        <div className="mt-0">
          <div>
            {!isSuccess ? (<RiCloseCircleLine className="ri-7x text-danger" />) : (<RiCheckboxCircleLine className="ri-7x text-success" />)}
            <h4 className="mb-3">{message}</h4>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ResponseModal;