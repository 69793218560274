import React, { useEffect, useRef, useState } from "react";
import noUiSlider from "nouislider";

let isInitialized = false;

interface HotelPriceRangeProps {
  minPriceFromHotel: number;
  maxPriceFromHotel: number;
  setMinPriceFilter: (field: any) => any;
  setMaxPriceFilter: (field: any) => any;
}

const HotelPriceRangeFilter: React.FC<HotelPriceRangeProps> = ({
  minPriceFromHotel,
  maxPriceFromHotel,
  setMinPriceFilter,
  setMaxPriceFilter,
}) => {
  const [minPrice, setMinPrice] = useState<number>(minPriceFromHotel);
  const [maxPrice, setMaxPrice] = useState<number>(maxPriceFromHotel);

  // Create a ref to store the slider element
  const sliderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const priceRangeSlider = sliderRef.current;

    if (priceRangeSlider) {
      // Initialize noUiSlider
      noUiSlider.create(priceRangeSlider, {
        start: [minPrice, maxPrice],
        connect: true,
        range: {
          min: minPriceFromHotel,
          max: maxPriceFromHotel,
        },
        step: 1,
      });

      // Update the state whenever the slider is updated
      priceRangeSlider.noUiSlider.on(
        "change",
        (values: (string | number)[]) => {
          if (!isInitialized) {
            isInitialized = true; // Ignore first call (mount)
            return;
          }
          const numericValues: number[] = values.map((value) => Number(value)); // Ensure values are numbers
          setMinPrice(Math.round(numericValues[0]));
          setMaxPrice(Math.round(numericValues[1]));
        }
      );
    }

    return () => {
      // Clean up slider if needed
      if (priceRangeSlider && priceRangeSlider.noUiSlider) {
        priceRangeSlider.noUiSlider.destroy();
      }
    };
  }, []); // Run this effect only once on mount (no dependency on minPrice or maxPrice)

  useEffect(() => {
    setMinPriceFilter(minPrice);
    setMaxPriceFilter(maxPrice);
  }, [minPrice, maxPrice]);

  return (
    <div className="accordion accordion-flush filter-accordion">
      <div className="card-body border-bottom">
        <p className="text-muted text-uppercase fs-12 fw-medium mb-3">
          Price Range
        </p>
        <div className="">
          {/* Use the ref here */}
          <div
            ref={sliderRef}
            className="noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr hotel-price-range-filter"
          ></div>
          <div className="d-flex justify-content-between mt-3">
            <div className="fs-14">
              Min. Price <span className="fw-bold">{minPrice}</span>
            </div>
            <div className="fs-14">
              Max. Price <span className="fw-bold">{maxPrice}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelPriceRangeFilter;
