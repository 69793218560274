import createAxiosInstance, { getAirportData } from "./Axios";
import { Logger } from "../utils/Logger";
import { setNewAuthToken } from "./ApiHelperFunctions";
import {
  logout,
  setFlightSearch,
  setCurrentUser,
  clearCurrentUser,
  setFlightType,
  setMyFlight,
  setToasterMessage,
  setVendor,
  setReturnFlightSearch,
  setLoader,
  setFlightFilter,
  setCategories,
  setTrainings,
  setAirlineFilter,
} from "../redux/actions";
import { filterObject } from "../utils/helpers";

export const searchFlight = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/search/flight", {
      params: filterObject(data),
    });
    Logger.info("Flight search .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    if (response.data.result?.data) {
      if (response?.data?.result?.data?.onwardJourneys) {
        dispatch(setFlightSearch(response.data.result?.data?.onwardJourneys));
      }

      if (response?.data?.result?.data?.returnJourneys) {
        dispatch(
          setReturnFlightSearch(response.data.result?.data?.returnJourneys)
        );
      }

      if (response?.data?.result?.data?.airlines) {
        dispatch(setAirlineFilter(response?.data?.result?.data?.airlines));
      }
    }
    dispatch(
      setToasterMessage({
        isShow: true,
        header: " Flight Search",
        type: response.data.result?.success ? "success" : "danger",
        message: response.data.result?.data
          ? response.data.result?.message
          : "No result found",
      })
    );
    dispatch(setFlightType(data?.returnDate === null));
    dispatch(setMyFlight({ isSearching: true }));
    dispatch(setFlightFilter({}));
    return response.data;
  } catch (err: any) {
    Logger.error("Error Flight search:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const searchAirport = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    if (data) {
      data = filterObject(data);
    }

    const response = await axiosInstance.get("/api/airports/airlines", {
      params: data,
    });
    Logger.info("Get Airport: ", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Get Airport: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchVendor = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/vendor/fetch", {
      params: data,
    });
    Logger.info("Get Vendor list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    dispatch(setVendor(response.data.result?.data));

    fetchFlightCategory(dispatch);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Vendor:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const baggageVendor = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/baggage/fetch", {
      params: data,
    });
    Logger.info("Get Baggage list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem("baggage", JSON.stringify(response.data.result?.data));
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Baggage:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchSalotation = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/salotation/fetch", {
      params: data,
    });
    Logger.info("Get Salotation list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "salotation",
      JSON.stringify(response.data.result?.data)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Salotation:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchAirlines = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/airlines/fetch", {
      params: data,
    });
    Logger.info("Get Airline list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Airline:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchNumberOfStops = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/number-of-stops/fetch", {
      params: data,
    });
    Logger.info("Get Depart time list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Depart time:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchDepartTime = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/depart-time/fetch", {
      params: data,
    });
    Logger.info("Get Depart time list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Depart time:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchPaymentStatus = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/payment-status/fetch", {
      params: data,
    });
    Logger.info("Get Payment Status list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Payment Status:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const createAgent = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  const formData = new FormData();

  // Add fields to formData with type checks
  for (const [key, value] of Object.entries(data)) {
    if (value !== null && value !== undefined && value !== "") {
      if (typeof value === "number") {
        formData.append(key, value.toString()); // Convert number to string
      } else if (typeof value === "string" || value instanceof Blob) {
        formData.append(key, value);
      }
    }
  }

  // Headers for multipart/form-data
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axiosInstance.post("/api/user/create", formData, {
      headers: headers,
    });

    Logger.info("Create agent.", response.data);

    setNewAuthToken(dispatch, response.data.result?.data?.access_token);

    return response.data;
  } catch (err: any) {
    Logger.error("Error creating agent:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const createAffiliate = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  const formData = new FormData();

  // Add fields to formData with type checks
  for (const [key, value] of Object.entries(data)) {
    if (value !== null && value !== undefined && value !== "") {
      if (typeof value === "number") {
        formData.append(key, value.toString()); // Convert number to string
      } else if (typeof value === "string" || value instanceof Blob) {
        formData.append(key, value);
      }
    }
  }

  // Headers for multipart/form-data
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axiosInstance.post("/api/agent/create", formData, {
      headers: headers,
    });

    Logger.info("Create affiliate.", response.data);

    setNewAuthToken(dispatch, response.data.result?.data?.access_token);

    return response.data;
  } catch (err: any) {
    Logger.error("Error creating affiliate:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const updateUserDetails = async (
  id: number,
  dispatch?: any,
  data?: any
) => {
  const axiosInstance = await createAxiosInstance();
  const formData = new FormData();

  // Add fields to formData with type checks
  for (const [key, value] of Object.entries(data)) {
    if (value !== null && value !== undefined && value !== "") {
      if (typeof value === "number") {
        formData.append(key, value.toString()); // Convert number to string
      } else if (typeof value === "string" || value instanceof Blob) {
        formData.append(key, value);
      }
    }
  }

  // Headers for multipart/form-data
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axiosInstance.post(
      `/api/user/update/${id}`,
      formData,
      {
        headers: headers,
      }
    );

    Logger.info("Update User Details", response.data);

    setNewAuthToken(dispatch, response.data.result?.data?.access_token);

    return response.data;
  } catch (err: any) {
    Logger.error("Error Updating User Details:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchUserList = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/user/list", {
      params: data,
    });
    Logger.info("Get User list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    await getUserSummary(dispatch);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Users:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchAffiliateList = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/user/list/affiliate", {
      params: data,
    });
    Logger.info("Get Affiliare list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Affiliates:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getIncomeHistory = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/income-history/list", {
      params: data,
    });
    Logger.info("Get Affiliate list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Affiliates:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getCreditConvertHistory = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/convert/list", {
      params: data,
    });
    Logger.info("Get Convert Credit list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Convert Credit:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const approvedCredit = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.put(`/api/convert/status/${id}`);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchRole = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/role/fetch", {
      params: data,
    });
    Logger.info("Get Role .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Role: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const login = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post("/api/auth/login", data);
    Logger.info("Authenticated .", response.data);
    setNewAuthToken(dispatch, response.data.result?.data?.access_token);
    delete response.data.result?.data?.access_token;
    delete response.data.result?.data?.token_type;
    delete response.data.result?.data?.expires_in;
    dispatch(setCurrentUser(response.data.result?.data));

    // Fetch Airport
    // searchAirport(dispatch).then((res) => {
    //   if (res?.result?.data) {
    //     dispatch(setAirports(res?.result?.data));
    //   }
    // });
    await fetchVendor(dispatch);
    await baggageVendor(dispatch);
    await fetchSalotation(dispatch);
    await fetchFlightCategory(dispatch);
    await fetchProviderList(dispatch);
    await subscriptions(dispatch);
    await getCategories(dispatch);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Authenticated: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const companyUpdateDetails = async (
  dispatch?: any,
  data?: any,
  id?: number
) => {
  const axiosInstance = await createAxiosInstance();
  const formData = new FormData();

  // Add fields to formData with type checks
  for (const [key, value] of Object.entries(data)) {
    if (value !== null && value !== undefined && value !== "") {
      if (typeof value === "number") {
        formData.append(key, value.toString()); // Convert number to string
      } else if (typeof value === "string" || value instanceof Blob) {
        formData.append(key, value);
      }
    }
  }

  // Headers for multipart/form-data
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axiosInstance.post(
      `/api/company/update/${id}`,
      formData,
      {
        headers: headers,
      }
    );

    Logger.info("Company Details .", response.data);
    setNewAuthToken(dispatch, response.data.result?.data?.access_token);
    if (response.data.result?.data) {
      dispatch(clearCurrentUser());
      dispatch(setCurrentUser(response.data.result?.data));
    }

    return response.data;
  } catch (err: any) {
    Logger.error("Error Company Details: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const uploadImage = async (
  queryPath: string,
  dispatch?: any,
  data?: any
) => {
  const axiosInstance = await createAxiosInstance();
  const formData = new FormData();

  // Add fields to formData with type checks
  for (const [key, value] of Object.entries(data)) {
    if (value !== null && value !== undefined && value !== "") {
      if (typeof value === "number") {
        formData.append(key, value.toString()); // Convert number to string
      } else if (typeof value === "string" || value instanceof Blob) {
        formData.append(key, value);
      }
    }
  }

  // Headers for multipart/form-data
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axiosInstance.post(`/api/${queryPath}`, formData, {
      headers: headers,
    });

    Logger.info("Upload Image", response.data);

    setNewAuthToken(dispatch, response.data.result?.access_token);

    return response.data;
  } catch (err: any) {
    Logger.error("Upload Image: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchAirportCity = async (iata?: string) => {
  try {
    const response = await getAirportData(iata);
    return response.data;
  } catch (err: any) {
    Logger.error("Upload Image: ", err);
  }
};

export const createPassenger = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post("/api/passenger/create", data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Authenticated: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchHistoryReport = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/history/report", {
      params: data,
    });
    Logger.info("Get History Report .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "summaryCount",
      JSON.stringify(response.data.result?.summaryCount)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error History Report:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const deleteHistory = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.delete(`/api/history/delete/${id}`);
    Logger.info("Delete History Report .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Deleting History Report:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchItinerary = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(
      `/api/itinerary/view/onward/${id}`
    );
    Logger.info("Get Itinerary .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "itinerary",
      JSON.stringify(response.data.result?.data)
    );
    dispatch(setMyFlight({ itineraryReturn: false }));
    return response.data;
  } catch (err: any) {
    Logger.error("Error Itinerary: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchItineraryReturn = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(
      `/api/itinerary/view/return/${id}`
    );
    Logger.info("Get Rerturn Itinerary .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "itinerary",
      JSON.stringify(response.data.result?.data)
    );
    dispatch(setMyFlight({ itineraryReturn: true }));
    return response.data;
  } catch (err: any) {
    Logger.error("Error Rerturn Itinerary: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const verifyLink = async (
  dispatch?: any,
  token?: string,
  redirect?: any
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/verify/token/${token}`);
    Logger.info("Verify token: ", response.data);

    if (response.data.result?.success) {
      setNewAuthToken(dispatch, response.data.result?.data?.access_token);
      if (response.data.result?.data) {
        dispatch(setCurrentUser(response.data.result?.data));
      }

      // Fetch Airport
      // searchAirport(dispatch).then((res) => {
      //   if (res?.result?.data) {
      //     dispatch(setAirports(res?.result?.data));
      //   }
      // });
      // await fetchVendor(dispatch);
      // await baggageVendor(dispatch);
      await fetchSalotation(dispatch);
      // await fetchFlightCategory(dispatch);
      // await fetchProviderList(dispatch);
      await subscriptions(dispatch);
      await dispatch(setLoader(false));
      redirect("/account-setting");
    } else {
      window.location.href = `${process.env.REACT_APP_SIGNIN_URL}`;
    }
  } catch (err: any) {
    Logger.error("Verify token: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchFlightCategory = async (dispatch?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/flight/category`);
    Logger.info("Get Flight Category: ", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "flightCategory",
      JSON.stringify(response.data.result?.data)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error Flight Category: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const downloadOnwardItinerary = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(
      `/api/download/itinerary/onward/${id}`,
      {
        responseType: "blob", // Set the response type to 'blob'
      }
    );
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const downloadReturnItinerary = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(
      `/api/download/itinerary/returned/${id}`,
      {
        responseType: "blob", // Set the response type to 'blob'
      }
    );
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchProviderList = async (dispatch?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/provider/list`);
    Logger.info("Get Provider List .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "ProviderList",
      JSON.stringify(response.data.result?.data)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error Provider List: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const passwordChange = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      "/api/user/change-password",
      data
    );
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Password Change: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const resendItinerary = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post("/api/resend/itinerary", data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Resending Itinerary: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchCreditList = async (dispatch?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/credit/fetch`);
    Logger.info("Get Credit List .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "CreditList",
      JSON.stringify(response.data.result?.data)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error Credit List: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const buyCredit = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  const formData = new FormData();

  // Add fields to formData with type checks
  for (const [key, value] of Object.entries(data)) {
    if (value !== null && value !== undefined && value !== "") {
      if (typeof value === "number") {
        formData.append(key, value.toString()); // Convert number to string
      } else if (typeof value === "string" || value instanceof Blob) {
        formData.append(key, value);
      }
    }
  }

  // Headers for multipart/form-data
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axiosInstance.post("/api/buy/credit", formData, {
      headers: headers,
    });

    Logger.info("Buy Credit: ", response.data);

    setNewAuthToken(dispatch, response.data.result?.data?.access_token);

    return response.data;
  } catch (err: any) {
    Logger.error("Error Buy Credit: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const buyActivation = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  const formData = new FormData();

  // Add fields to formData with type checks
  for (const [key, value] of Object.entries(data)) {
    if (value !== null && value !== undefined && value !== "") {
      if (typeof value === "number") {
        formData.append(key, value.toString()); // Convert number to string
      } else if (typeof value === "string" || value instanceof Blob) {
        formData.append(key, value);
      }
    }
  }

  // Headers for multipart/form-data
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axiosInstance.post("/api/buy/activation", formData, {
      headers: headers,
    });

    Logger.info("Buy Activation: ", response.data);

    setNewAuthToken(dispatch, response.data.result?.data?.access_token);

    return response.data;
  } catch (err: any) {
    Logger.error("Error Buy Activation: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const convertBalance = async (dispatch?: any) => {
  const axiosInstance = await createAxiosInstance();

  try {
    const response = await axiosInstance.post("/api/balance/convert");

    Logger.info("Convert Balance to Credits: ", response.data);

    setNewAuthToken(dispatch, response.data.result?.data?.access_token);

    return response.data;
  } catch (err: any) {
    Logger.error("Error Convert Balance to Credits: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchPaymentMethod = async (dispatch?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/payment-method/fetch`);
    Logger.info("Get Payment Method List .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "PaymentMethodList",
      JSON.stringify(response.data.result?.data)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error Payment Method List: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchPaymentHistory = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/payment-history/list`, {
      params: data,
    });
    Logger.info("Get Payment History List .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Payment History List: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchFundHistory = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/fund/list`, {
      params: data,
    });
    Logger.info("Get Fund History List .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Fund History List: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchConversionsHistory = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/fund/conversions`, {
      params: data,
    });
    Logger.info("Get Conversions History List .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Conversions History List: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchConversionHistory = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/conversion-history/list`, {
      params: data,
    });
    Logger.info("Get Conversion History List .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Conversion History List: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const paymentConfirmed = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.put(`/api/payment/confirmed/${id}`);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const paymentActivationConfirmed = async (
  dispatch?: any,
  id?: number
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.put(
      `/api/activation-payment/confirmed/${id}`
    );
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const paymentInvalid = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.put(`/api/payment/invalid/${id}`);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getUserSummary = async (dispatch?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/user/summary`);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "userSummaryDetails",
      JSON.stringify(response.data.result?.data)
    );
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getUserData = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/user/show/${id}`);
    Logger.info("Get User Data.", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error User Data: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const subscriptions = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/subscription/list", {
      params: data,
    });
    Logger.info("Get Subscription list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "subscription",
      JSON.stringify(response.data.result?.data)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Subscription:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getCategories = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/category/list", {
      params: data,
    });
    Logger.info("Get Category list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    dispatch(setCategories(response.data.result?.data));
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Category:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getPublishedTraining = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/training/published", {
      params: data,
    });
    Logger.info("Get Published Training list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    dispatch(setTrainings(response.data.result?.data));
    return response.data;
  } catch (err: any) {
    Logger.error("Error Published getting Training:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getDraftTraining = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/training/drafts", {
      params: data,
    });
    Logger.info("Get Draft Training list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    dispatch(setTrainings(response.data.result?.data));
    return response.data;
  } catch (err: any) {
    Logger.error("Error Draft getting Training:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const createCategory = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post("/api/category/create", data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Category: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const createTraining = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post("/api/training/create", data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Category: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getRelatedTrainings = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/related/trainings/${id}`);
    Logger.info("Get Related Trainings .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Related Trainings:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getSubCourseTrainings = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(`/api/training/subcourse/`, data);
    Logger.info("Get Sub Course Trainings .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Sub Course Trainings:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const updateTraining = async (
  dispatch?: any,
  data?: any,
  id?: number
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.put(
      `/api/training/update/${id}`,
      data
    );
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const trainingUploadVideo = async (
  dispatch: any,
  chunk: Blob,
  chunkIndex: number,
  lastChunk: number
) => {
  const axiosInstance = await createAxiosInstance();
  const formData = new FormData();

  formData.append("file", chunk);
  formData.append("chunkIndex", chunkIndex.toString());
  formData.append("totalChunks", lastChunk.toString());

  // Headers for multipart/form-data
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axiosInstance.post(
      "/api/training/upload/video",
      formData,
      {
        headers: headers,
      }
    );

    Logger.info("Training Upload video.", response.data);

    setNewAuthToken(dispatch, response.data.result?.data?.access_token);

    return response.data;
  } catch (err: any) {
    Logger.error("Error Training Upload video:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getTrainingPublishedCategory = async (
  dispatch?: any,
  data?: any
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      `/api/training/published/categories/`,
      data
    );
    Logger.info("Get Published Categories.", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Published Categories:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getTrainingDraftCategory = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      `/api/training/draft/categories/`,
      data
    );
    Logger.info("Get Draft Category .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Draft Category:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const transferFund = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(`/api/transfer/funds`, data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    if (response.data.result?.data) {
      dispatch(setCurrentUser(response.data.result?.data));
    }
    return response.data;
  } catch (err: any) {
    Logger.error("Error Draft Category:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const verifyFundOTP = async (
  dispatch?: any,
  data?: any,
  id?: number
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.put(`/api/transfer/verify`, data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    if (response.data.result?.data) {
      dispatch(setCurrentUser(response.data.result?.data));
    }
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const verifyAccount = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/verify/account/${id}`);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const resendOtp = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(`/api/resend/transfer/otp/${id}`);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const termsCondition = async (
  dispatch?: any,
  id?: number,
  data?: any
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.put(
      `/api/company/terms-condition/${id}`,
      data
    );
    if (response.data.result?.data) {
      dispatch(setCurrentUser(response.data.result?.data));
    }
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getFundSummary = async (dispatch?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/fund/summary`);
    Logger.info("Get Fund Summary .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Fund Summary:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchRegions = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/address/regions", {
      params: data,
    });
    Logger.info("Get Regions .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Regions:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchProvinces = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/address/provinces", {
      params: data,
    });
    Logger.info("Get Provinces .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Provinces:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchBarangays = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/address/barangays", {
      params: data,
    });
    Logger.info("Get Barangays .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Barangays:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchMarkUpOptions = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/markup/options`);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const createUpdateMarkUpSettings = async (
  dispatch?: any,
  data?: any
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(`/api/markup/settings`, data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    if (response.data.result?.data) {
      dispatch(setCurrentUser(response.data.result?.data));
    }
    return response.data;
  } catch (err: any) {
    Logger.error("Error Setting Up Markup:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const updateFlight = async (dispatch?: any, data?: any) => {
  let payload = JSON.parse(JSON.stringify(data));
  payload.journeyOnward = JSON.stringify(payload.journeyOnward);
  payload.journeyReturn = JSON.stringify(payload.journeyReturn);
  payload.passengersInfo = JSON.stringify(payload.passengersInfo);
  Logger.info("updateFlight from api:", payload);
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      "/api/passenger/change-flight",
      filterObject(payload)
    );

    Logger.info("Update Flight .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error updating flight: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const subscriptionUpgrade = async (
  dispatch?: any,
  id?: number,
  data?: any
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      `/api/subscription/upgrade/${id}`,
      data
    );
    setNewAuthToken(dispatch, response.data.result?.access_token);
    if (response.data.result?.data) {
      dispatch(setCurrentUser(response.data.result?.data));
    }
    await subscriptions(dispatch);
    return response.data;
  } catch (err: any) {
    Logger.error("Error subscription upgrade:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const addBaggage = async (dispatch?: any, data?: any) => {
  let payload = JSON.parse(JSON.stringify(data));
  payload.journeyOnward = JSON.stringify(payload.journeyOnward);
  payload.journeyReturn = JSON.stringify(payload.journeyReturn);
  payload.passengersInfo = JSON.stringify(payload.passengersInfo);
  Logger.info("addBaggage from api:", payload);
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      "/api/passenger/add-baggage",
      filterObject(payload)
    );

    Logger.info("Add Baggage .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error adding baggage: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const exportAgent = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      "/api/generate/agent",
      data ? filterObject(data) : null
    );

    Logger.info("Add Baggage .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error adding baggage: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const exportOutsourcing = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      "/api/generate/outsourcing",
      data ? filterObject(data) : null
    );
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const exportDirectBooking = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      "/api/generate/direct-booking",
      data ? filterObject(data) : null
    );

    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Calendar History Report:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchCalendarHistoryReport = async (
  dispatch?: any,
  data?: any
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/history/calendar/report", {
      params: data,
    });
    Logger.info("Get Calendar History Report .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "calendarSummaryCount",
      JSON.stringify(response.data.result?.summaryCount)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error Calendar History Report:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchHotelDropdownOptions = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(
      "/api/direct-hotel/dropdown-options",
      {
        params: data,
      }
    );
    Logger.info("Get Hotels list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Hotels:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const searchByHotelId = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      "/api/direct-hotel/show-by-hotel",
      data
    );
    Logger.info("Get Hotels list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Hotels:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const bookHotel = async (dispatch?: any, data?: any) => {
  // Initialize FormData
  const formData = new FormData();

  // Append all keys from the `data` object to the FormData
  for (const key in data) {
    if (key === "hotel_image" && data[key] instanceof File) {
      // Directly append the file
      formData.append(key, data[key]);
    } else if (Array.isArray(data[key])) {
      // Handle array serialization (e.g., guests)
      formData.append(key, JSON.stringify(data[key]));
    } else {
      // Append other data fields
      formData.append(key, data[key]);
    }
  }

  // Headers for multipart/form-data
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  Logger.info("bookHotel from api:", formData);
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post("/api/hotel/book", formData, {
      headers: headers,
    });

    Logger.info("Book Hotel .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error booking hotel: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchHotelBookingHistoryReport = async (
  dispatch?: any,
  data?: any
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get("/api/hotel/history/report", {
      params: data,
    });
    Logger.info("Get Hotel Booking History Report .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "hotelBookingSummaryCount",
      JSON.stringify(response.data.result?.summaryCount)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error History Report:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchHotelBooking = async (dispatch?: any, id?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/hotel/view/${id}`);
    Logger.info("Get Hotel Booking .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "hotelBookingView",
      JSON.stringify(response.data.result?.data)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error History Report:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const downloadHotelBooking = async (dispatch?: any, id?: number) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(
      `/api/hotel/download/booking/${id}`,
      {
        responseType: "blob", // Set the response type to 'blob'
      }
    );
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const resendHotelBooking = async (
  dispatch?: any,
  id?: any,
  data?: any
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      `/api/hotel/resend/booking/${id}`,
      data
    );
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Resending Hotel Booking: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const deleteHotelBookingHistory = async (
  dispatch?: any,
  id?: number
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.delete(
      `/api/hotel/history/delete/${id}`
    );
    Logger.info("Delete Hotel Booking History Report .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Deleting Hotel Booking History Report:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const getHotelRepriceData = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      "/api/direct-hotel/get-reprice",
      data
    );
    Logger.info("Get Hotels list .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error getting Hotel Reprice:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const directBookHotel = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    let payload = JSON.parse(JSON.stringify(data));
    payload.deliveryData = JSON.stringify(payload.deliveryData);
    payload.payment = JSON.stringify(payload.payment);
    payload.roomGuests = JSON.stringify(payload.roomGuests);
    payload.roomType = JSON.stringify(payload.roomType);
    payload.search_details = JSON.stringify(payload.search_details);

    const response = await axiosInstance.post(
      "/api/direct-hotel/book",
      payload
    );
    Logger.info("Book hotel: ", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error booking the hotel:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchDirectHotelBookingHistoryReport = async (
  dispatch?: any,
  data?: any
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(
      "/api/direct-hotel/history/report",
      {
        params: data,
      }
    );
    Logger.info("Get Direct Hotel Booking History Report .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "directHotelBookingSummaryCount",
      JSON.stringify(response.data.result?.summaryCount)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error History Report:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchDirectHotelBooking = async (dispatch?: any, id?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/direct-hotel/view/${id}`);
    Logger.info("Get Direct Hotel Booking .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    localStorage.setItem(
      "directHotelBookingView",
      JSON.stringify(response.data.result?.data)
    );
    return response.data;
  } catch (err: any) {
    Logger.error("Error Direct History Report:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const downloadDirectHotelBooking = async (
  dispatch?: any,
  id?: number
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.get(
      `/api/direct-hotel/download/booking/${id}`,
      {
        responseType: "blob", // Set the response type to 'blob'
      }
    );
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const resendDirectHotelBooking = async (
  dispatch?: any,
  id?: any,
  data?: any
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      `/api/direct-hotel/resend/booking/${id}`,
      data
    );
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Resending Direct Hotel Booking: ", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const deleteDirectHotelBookingHistory = async (
  dispatch?: any,
  id?: number
) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.delete(
      `/api/direct-hotel/history/delete/${id}`
    );
    Logger.info("Delete Hotel Booking History Report .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Deleting Hotel Booking History Report:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};

export const fetchHotelRooms = async (dispatch?: any, data?: any) => {
  const axiosInstance = await createAxiosInstance();
  try {
    const response = await axiosInstance.post(
      `/api/direct-hotel/get-hotel-rooms`,
      data
    );
    Logger.info("Get Hotel Rooms .", response.data);
    setNewAuthToken(dispatch, response.data.result?.access_token);
    return response.data;
  } catch (err: any) {
    Logger.error("Error Get Hotel Rooms:", err);
    if (err?.response?.status === 401) {
      dispatch(logout());
    }
    throw err;
  }
};
