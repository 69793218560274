import React, { CSSProperties } from "react";
import { setMyFlight } from "../redux/actions";
import { useInView } from "react-intersection-observer";
import { EditSingleFlightDetail } from "../types/Types";
import moment from "moment";

interface TruncatedTextProps {
  text: string;
  maxWords: number;
}

export const TruncatedText: React.FC<TruncatedTextProps> = ({
  text,
  maxWords,
}) => {
  const truncate = (input: string): string => {
    return (
      input.split(" ").slice(0, maxWords).join(" ") +
      (input.split(" ").length > maxWords ? "..." : "")
    );
  };

  return <div>{truncate(text)}</div>;
};

interface LazyImageProps {
  src: string;
  alt: string;
  className?: string;
  height?: string;
  width?: string;
  imgSrc?: string;
  style?: CSSProperties;
}

export const LazyImage: React.FC<LazyImageProps> = ({
  src,
  alt,
  className,
  height,
  width,
  imgSrc,
  style,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Load image only once when it comes into view
  });

  return (
    <img
      ref={ref}
      alt={alt}
      className={className}
      height={height}
      width={width}
      src={inView ? src : undefined}
      data-src={src}
      img-src={imgSrc}
      style={style}
    />
  );
};

export const formatDate = (dateInput: any): any => {
  if (!dateInput) return null;

  let date: Date;

  if (typeof dateInput === "string") {
    // Parse the string to ensure it's a valid date
    date = new Date(dateInput);
    if (isNaN(date.getTime())) return null; // Invalid date string
  } else {
    date = dateInput;
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const agentDashSVG = () => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 120"
      width="220"
      height="145"
    >
      <style>{`.s0 {opacity: .05;fill: var(--vz-success)}`}</style>
      <path
        id="Shape 8"
        className="s0"
        d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
      ></path>
    </svg>
  );
};

export const filterObject = (
  updatedValues: Record<string, any>
): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(updatedValues).filter(
      ([_, v]) => v !== null && v !== undefined && v !== ""
    )
  );
};

export const getDurations = (start: string | Date, end: string | Date) => {
  // Convert the timestamps to Date objects
  const startD = new Date(start);
  const endD = new Date(end);
  // Get the time difference in milliseconds
  const timeDifferenceMillis = endD.getTime() - startD.getTime();

  // Convert milliseconds to days, hours, and minutes
  const days = Math.floor(timeDifferenceMillis / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifferenceMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor(
    (timeDifferenceMillis % (1000 * 60 * 60)) / (1000 * 60)
  );

  let result: string = "";

  if (days !== 0) {
    result += `${days}d `;
  }

  if (hours !== 0) {
    result += `${hours}h `;
  }

  if (minutes !== 0) {
    result += `${minutes}m`;
  }

  return result;
};

// Utility function to pad single-digit numbers with a leading zero
export const padZero = (num: number): string => String(num).padStart(2, "0");

// Utility function to format time
export const formatTime = (date: Date, isSeconds: boolean = true): string => {
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());
  return isSeconds ? `${hours}:${minutes}:${seconds}` : `${hours}:${minutes}`;
};

// Utility function to format date
export const dateFormatInReadableFormat = (date: Date): string => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

// Main function to format date and time
export const formatDateTime = (date: Date): string => {
  const time = formatTime(date);
  const formattedDate = dateFormatInReadableFormat(date);
  return `${time}, ${formattedDate}`;
};

export const getInterval = (
  startTime: string | Date,
  endTime: string | Date
) => {
  // Convert the timestamps to Date objects
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Calculate the time difference in minutes
  const minutesDifference = timeDifference / (1000 * 60);

  // Calculate the hours and remaining minutes
  const hours = Math.floor(minutesDifference / 60);
  const minutes = minutesDifference % 60;

  return `${hours}h ${minutes}m`;
};

export const formattedDateAbre = (date: Date): string => {
  const d = new Date(date);

  // Format the date to 'MMM dd' format
  return d.toLocaleDateString("en-US", { month: "short", day: "2-digit" });
};

export const formattedDateAbrev = (date: Date): string => {
  const d = new Date(date);

  // Define an array to hold the weekday names
  var weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Format the date to 'ddd, MMM dd' format
  return (
    weekdays[d.getDay()] +
    ", " +
    d.toLocaleDateString("en-US", { month: "short", day: "2-digit" })
  );
};

export const convertTimeFormat = (time: string | Date) => {
  // Create a Date object from the timestamp
  const date = new Date(time);

  // Extract the hours and minutes
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  // Return the time in the format "Xh Ym"
  return `${hours}h ${minutes}m`;
};

export const getHourMinutes = (
  dateString: Date,
  isMeridiem: boolean = false
): string => {
  // Create a Date object from the given string
  var date = new Date(dateString);

  // Get the hour and minutes from the date
  var hour = date.getHours();
  var minutes = date.getMinutes();

  var meridiem = hour >= 12 ? " PM" : " AM";

  // Format the hour and minutes
  var formattedTime =
    hour.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    (isMeridiem ? meridiem : "");

  // Output the formatted time
  return formattedTime;
};

export const terminalFormatted = (inputString: string): string => {
  let replacedString = inputString;

  if (inputString) {
    // Remove everything before "Terminal" followed by a number or just a number
    replacedString = replacedString.replace(
      /.*?(\bTerminal\s+\d+|\b\d+\b)/i,
      "$1"
    );

    // Replace "Terminal X" with "TX"
    replacedString = replacedString.replace(/\bTerminal\s+(\d+)\b/gi, "T$1");

    // Add "T" prefix to standalone numbers not already prefixed by "T"
    replacedString = replacedString.replace(/\b(\d+)\b(?!T)/g, "T$1");

    // Clean up extra spaces
    replacedString = replacedString.replace(/\s+/g, " ").trim();

    // Check if the resulting string starts with "T" followed by a number
    if (!/^T\d+$/.test(replacedString)) {
      return "";
    }
  }

  return replacedString;
};

interface FlightFare {
  baseFare?: number | string;
  priceFare?: string | number;
  feesTaxes?: number | string;
}

/**
 * Formats a given amount into a currency string in PHP.
 *
 * @param amount - The amount to be formatted.
 * @returns The formatted currency string.
 */
export const currencyFormat = (
  amount: number,
  isSymbol: boolean = true,
  isDefault: boolean = false
): string => {
  if (!isNaN(amount)) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Format the number as a currency string
    const formatted = formatter.format(amount);

    if (isSymbol) {
      // Replace the currency symbol (₱) with "Php"
      return formatted.replace("₱", "Php ");
    }

    if (isDefault) {
      return formatted.replace("₱", "₱ ");
    }

    return formatted.replace(/₱/g, "");
  }
  return "0";
};

/**
 * Computes the flight summary based on the given flight fare and passenger count.
 *
 * @param flightFare - The fare details for the flight.
 * @param passengerCount - The number of passengers.
 * @returns An object containing the total fare and the sum of tax, transaction fee, and fuel.
 */
export const flightSummaryComputation = (
  flightFare: FlightFare,
  passengerCount: number,
  addOns?: number,
  returnFlight?: any | undefined,
  myFlight?: any
): { total: string; taxTxnFee: string } => {
  // Destructure the flightFare object for easier access to properties
  const { priceFare, feesTaxes } = flightFare;

  let parsedPriceFare = 0;
  let parsedfeesTaxes = 0;
  let parsedFPriceFare = 0;
  let parsedRFeesTaxes = 0;
  let totalCost = 0;
  let totalFeesTaxes = 0;

  if (returnFlight !== undefined && returnFlight?.length !== 0) {
    if (
      typeof parsedFPriceFare === "string" ||
      typeof parsedFPriceFare === "number"
    ) {
      // Check if priceFare is a string or number
      parsedFPriceFare = Number(returnFlight?.priceFare);
    }

    if (
      typeof parsedRFeesTaxes === "string" ||
      typeof parsedRFeesTaxes === "number"
    ) {
      // Check if priceFare is a string or number
      parsedRFeesTaxes = Number(returnFlight?.feesTaxes);
    }
  }

  if (typeof priceFare === "string" || typeof priceFare === "number") {
    // Check if priceFare is a string or number
    parsedPriceFare = Number(priceFare);
  }

  if (typeof feesTaxes === "string" || typeof feesTaxes === "number") {
    // Check if priceFare is a string or number
    parsedfeesTaxes = Number(feesTaxes);
  }

  // Compute other costs
  if (myFlight.gTotal !== "") {
    parsedPriceFare = parseFloat(floatFormat(myFlight.gTotal));
    parsedfeesTaxes = parseFloat(floatFormat(myFlight.tFare));
    totalCost = parsedPriceFare + (addOns ?? 0);
    totalFeesTaxes = parsedfeesTaxes;
  } else {
    totalCost = parsedPriceFare + parsedFPriceFare + (addOns ?? 0);
    totalFeesTaxes = parsedfeesTaxes + parsedRFeesTaxes;

    // Store the computed values in localStorage
    localStorage.setItem("fareSummaryTotal", currencyFormat(totalCost));
    localStorage.setItem(
      "fareSummaryFeesTaxes",
      currencyFormat(totalFeesTaxes)
    );
  }

  return {
    total: currencyFormat(totalCost),
    taxTxnFee: currencyFormat(totalFeesTaxes),
  };
};

export const floatFormat = (amount?: string): string => {
  if (amount) {
    const numericValue = parseFloat(amount.replace(/[^0-9.]/g, ""));

    return numericValue.toFixed(2);
  }

  return "";
};

export function nonStopConversion(number: number): string {
  const numberStrings: string[] = ["", "one", "two", "three"];

  if (number >= 1 && number <= 3) {
    return numberStrings[number];
  }
  return "";
}

export const handleNumberOnly = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  // Check if the key pressed is a number (charCode between 48 and 57)
  if (event.charCode < 48 || event.charCode > 57) {
    event.preventDefault(); // Prevent the input if it's not a number
  }
};

export const handleNumberWithDot = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  // Get the pressed key as a character
  const pressedKey = String.fromCharCode(event.charCode);

  // Check if the pressed key is a digit (0-9) or a dot (.)
  if (!/[\d.,]/.test(pressedKey)) {
    event.preventDefault(); // Prevent the input if it's not a digit or dot
  }
};

export const thisDay = () => {
  const currentDate = new Date();
  return formatDate(currentDate.toISOString());
};

export const dynamicFieldsPattern = (val: string): string => {
  // Use a regular expression to remove the pattern like ".0." or ".1." and surrounding spaces
  // The pattern that resolves this is (String.key.string) this use for dynamic fields
  return val.replace(/\b\w+\.\d+\./g, "");
};

export const formikErrorSetter = (
  setFieldError: (field: string, message: string) => void,
  errorData: any,
  isDynamicFields: boolean = false
) => {
  for (const [key, value] of Object.entries<string>(errorData)) {
    if (Object.hasOwnProperty.call(errorData, key)) {
      if (isDynamicFields) {
        setFieldError(key, dynamicFieldsPattern(value));
      } else {
        setFieldError(key, value);
      }
    }
  }
};

export const handleCurrencyInput = (
  e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any) => void,
  key: string,
  sellingPrice?: string,
  isSellPrice: boolean = false
) => {
  const rawInput = e.target.value;

  // Remove non-numeric characters except for the period
  const numericInput = rawInput.replace(/[^0-9.]/g, "");

  // If the numericInput is not empty, parse it to a float
  if (numericInput) {
    // Update the input value with the formatted currency
    e.target.value = numericInput.replace(/Php/g, "");
    setFieldValue(key, e.target.value);
  } else {
    // If numericInput is empty, clear the input
    e.target.value = "";
    setFieldValue(key, "");
  }

  if (isSellPrice) {
    setFieldValue(
      "selling_price",
      currencyFormat(Number(floatFormat(sellingPrice)), false)
    );
  }
};

export const gettingPercentage = (price: any, dispatch?: any) => {
  const flightFare = JSON.parse(localStorage.getItem("flightFare") ?? "[]");
  const returnFlightFare = JSON.parse(
    localStorage.getItem("returnFlightFare") ?? "[]"
  );
  const addOnsPrice = localStorage.getItem("addOnsPrice");
  const fareSummaryTotal = localStorage.getItem("fareSummaryTotal");
  const fareSummaryFeesTaxes =
    localStorage.getItem("fareSummaryFeesTaxes") ?? "Php 0";

  let percent = flightFare?.percentage || 0;
  let gTotal = 0;
  let gTotalFees = 0;
  let addOns = 0;
  let tax = 0;
  let baseFare = 0;

  if (returnFlightFare.length !== 0) {
    if (fareSummaryTotal !== null && fareSummaryTotal !== undefined) {
      gTotal = parseFloat(floatFormat(fareSummaryTotal));
    }

    if (fareSummaryFeesTaxes !== null && fareSummaryFeesTaxes !== undefined) {
      gTotalFees = parseFloat(floatFormat(fareSummaryFeesTaxes));
    }
    percent = ((gTotalFees / gTotal) * 100) / 100;
  }

  if (addOnsPrice) {
    addOns = parseFloat(floatFormat(addOnsPrice));
  }

  if (price) {
    const summary = parseFloat(floatFormat(price)) - addOns;
    const gt = summary * percent;
    if (!isNaN(gt)) {
      tax = gt ? parseFloat(gt.toFixed(2)) : 0;
      baseFare = summary - tax;
      localStorage.setItem("baseFare", String(baseFare));
      localStorage.setItem("fareSummaryTotal", currencyFormat(summary));
      localStorage.setItem("fareSummaryFeesTaxes", currencyFormat(tax));
      dispatch(
        setMyFlight({
          gTotal: currencyFormat(summary),
          bFare: String(baseFare),
          tFare: currencyFormat(tax),
        })
      );
    }
  }
  return flightFare;
};

export const clearLocalStorage = () => {
  const keysToRemove = [
    "addOnsPrice",
    "baseFare",
    "FlightSearchPayload",
    "flightDetails",
    "returnFlightFare",
    "fareSummaryTotal",
    "flightFare",
    "fareSummaryFeesTaxes",
    "DirectFlightReview",
    "mealData",
    "baggageData",
  ];

  keysToRemove.forEach((key) => {
    if (localStorage.getItem(key) !== null) {
      localStorage.removeItem(key);
    }
  });
};

export const getAmount = (str: string): GLfloat => {
  // Regular expression to match the price portion
  const regex = /Php\s([\d,]+\.\d{2})/;

  // Extract the amount using match
  const match = str.match(regex);

  if (match) {
    const priceStr = match[1].replace(/,/g, "");
    return parseFloat(priceStr);
  }

  return 0;
};

export const calculateTimeDifference = (
  startTime: string,
  endTime: string
): any => {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  // Check if dates are valid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return "Invalid date";
  }

  const difference = endDate.getTime() - startDate.getTime();

  const hours = Math.floor(difference / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  return `${hours}h ${minutes}m`;
};

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  wait: number
): ((...args: Parameters<T>) => void) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  return function executedFunction(...args: Parameters<T>) {
    const later = () => {
      timeout = null;
      func(...args);
    };
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
  };
};

export const getToDate = (days: number) => {
  const currentDate = new Date();
  const endDate = new Date(currentDate);
  endDate.setDate(endDate.getDate() + days);
  return formatDate(endDate.toISOString());
};

export const getDayInterval = (toDate: string | Date) => {
  // Convert the timestamps to Date objects
  const from = new Date();
  const to = new Date(toDate);

  // Calculate the time difference in milliseconds
  const timeDifference = to.getTime() - from.getTime();

  // Convert the time difference from milliseconds to days
  let dayDifference = timeDifference / (1000 * 60 * 60 * 24);

  // If the day difference is negative or represents a past date, set it to 0
  if (dayDifference < 0) {
    dayDifference = -1;
  }

  // Return the absolute value of the day difference
  return Math.floor(dayDifference);
};

export const transformJourney = (journeys: EditSingleFlightDetail[]) => {
  if (journeys.length === 0) return "";

  const combinedCodes = [journeys[0].departureCode];

  for (let i = 0; i < journeys.length; i++) {
    const currentJourney = journeys[i];
    const nextJourney = journeys[i + 1];

    combinedCodes.push(currentJourney.arrivalCode);

    if (
      nextJourney &&
      currentJourney.arrivalCode !== nextJourney.departureCode
    ) {
      combinedCodes.push(nextJourney.departureCode);
    }
  }

  return combinedCodes.join(" - ");
};

export const formatPieces = (pieces: string | number | undefined): string => {
  return (Number(pieces) || 0) > 1 ? "Pcs" : "Pc";
};

export const purchaseTicketSVG = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      aria-hidden="true"
      className="text-success"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625zM7.5 15a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 017.5 15zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H8.25z"
        clipRule="evenodd"
      ></path>
      <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z"></path>
    </svg>
  );
};

export const CalendarIconSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-calendar text-info icon-dual-info"
    >
      <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
      <line x1="16" y1="2" x2="16" y2="6"></line>
      <line x1="8" y1="2" x2="8" y2="6"></line>
      <line x1="3" y1="10" x2="21" y2="10"></line>
    </svg>
  );
};

export const ensureKg = (value: string): string => {
  const trimmedValue = value.trim().toLowerCase();
  if (trimmedValue.endsWith("kg")) {
    return value.trim();
  }
  return `${value.trim()} kg`;
};

export const removeKg = (value: string | null): number => {
  if (!value) return 0;

  const trimmedValue = value.replace(/kg/i, "").trim();
  return Number(trimmedValue);
};

export const calculateTotalNights = (
  checkInDate: string | undefined,
  checkOutDate: string | undefined
) => {
  if (checkInDate === undefined || checkOutDate === undefined) return 0;
  return moment(moment(checkOutDate).startOf("day")).diff(
    moment(moment(checkInDate).startOf("day")),
    "days"
  );
};

export const sanitizePriceInput = (input: string) => {
  return input
    .replace(/[^0-9.]/g, "") // Allow only digits and dots
    .replace(/(\..*?)\./g, "$1") // Prevent multiple dots
    .replace(/^0+(\d)/, "$1") // Remove leading zeros unless after the dot
    .replace(/^\./, "0."); // Add leading zero before a standalone dot
};

export const sanitizePhoneNumber = (input: string) => {
  return input.replace(/[^0-9]/g, ""); // Allow only digits and dots
};

export const normalizeFieldName = (field: string) => {
  return field
    .replace(/_/g, " ") // Remove underscores
    .split(" ") // Split into words by space
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
    .join(" "); // Join the words back with spaces
};
