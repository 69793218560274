import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { currencyFormat } from "../utils/helpers";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { HotelResult } from "../types/Types";
import { Button } from "react-bootstrap";
import { getTotalAdults, getTotalChildren } from "../pages/HotelBooking";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import StarRating from "../utils/StarRating";
import moment from "moment";

interface HotelPaymentInterface {
  showModal: boolean;
  isLoading: boolean;
  isCancellationPolicyAgreed: boolean;
  handleClose: () => void;
  handleBookHotel: (values: any) => Promise<void>;
  setIsCancellationPolicyAgreed: (values: any) => void;
}

const HotelPaymentConfirmationModal: React.FC<HotelPaymentInterface> = ({
  showModal,
  isLoading,
  isCancellationPolicyAgreed,
  handleClose,
  handleBookHotel,
  setIsCancellationPolicyAgreed,
}) => {
  const hotelData = useSelector(
    (state: any) => state.myHotel.savedDirectHotelFormDetails
  );

  const handleSubmit = async () => {
    const newHotelData = cloneDeep(hotelData);
    handleBookHotel(newHotelData);
    handleClose();
  };

  useEffect(() => {
    setIsCancellationPolicyAgreed(false);
  }, [showModal]);

  return (
    <Modal show={showModal} onHide={handleClose} centered backdrop="static">
      <Modal.Header className="d-block text-center">
        <h4 className="modal-title">Booking Confirmation</h4>
      </Modal.Header>
      <Modal.Body className="pt-1">
        <div className="text-center mb-2 p-2 bg-soft-warning rounded-1 border-shadow border">
          <div className="fs-14">
            Please review the details below as there might be changes in
            cancellation policies, important information, and total price.
          </div>
        </div>
        <div
          style={{ border: "1px solid #d6d6d6" }}
          className="card rounded-3 mb-1"
        >
          <div className="card-body py-1">
            <div className="row p-2">
              <div className="col-6 col-md-5 border-end border-3 ps-2">
                <div className="fs-11 text-muted">Check-In</div>
                <div className="fs-14 fw-semibold">
                  {moment(hotelData.search_details.checkIn).format(
                    "ddd, MMM D YYYY"
                  )}
                </div>
                <div>
                  <span className="text-muted">After: </span>
                  <span className="fw-semibold fs-13">
                    {hotelData.check_in_time}
                  </span>
                </div>
              </div>
              <div className="col-6 col-md-5">
                <div className="fs-11 text-muted">Check-Out</div>
                <div className="fs-14 fw-semibold">
                  {moment(hotelData.search_details.checkOut).format(
                    "ddd, MMM D YYYY"
                  )}
                </div>
                <div>
                  <span className="text-muted">Before: </span>
                  <span className="fw-semibold fs-13">
                    {hotelData.check_out_time}
                  </span>
                </div>
              </div>
              <div className="col-2 col-md-2 text-center bg-soft-dark rounded-3 d-none d-md-block">
                <div className="fs-14 mt-2 fw-semibold">
                  {hotelData.search_details.numberOfNights}
                </div>
                <div>
                  night{hotelData.search_details.numberOfNights > 1 ? "s" : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ border: "1px solid #d6d6d6" }}
          className="card rounded-3 mb-2"
        >
          <div className="card-body">
            <div>
              <img
                src={hotelData.main_image}
                alt=""
                className="w-100 object-cover rounded-3"
                style={{ height: "150px" }}
              />
            </div>
            <div className="mt-2">
              <div className="row align-items-center">
                <div className="col-lg-8">
                  <div className="fs-15 fw-semibold ">{hotelData.name}</div>
                </div>
                <div className="col-lg-4 text-start text-md-end mb-2 mb-end-0">
                  <StarRating
                    rating={hotelData.current_rating ?? 0}
                    otherClasses="fs-14"
                  />
                </div>
              </div>
              <div>
                <div className="fs-13">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="text-warning"
                  />{" "}
                  {hotelData.address}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ border: "1px solid #d6d6d6" }}
          className="card rounded-3 mb-2"
        >
          <div className="card-body  shadow-lg">
            <div className="d-flex text-center pb-1 mb-2 border-bottom">
              <div className="col-5 text-start mb-2">
                <div className="fs-6 fw-semibold side-line ">Summary</div>
              </div>
              <div className="col text-end">
                <div className="fs-6 fw-semibold">Price</div>
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="col-5 text-start fs-14">
                {hotelData.search_details.roomGuests.length ?? 1} Room x{" "}
                {hotelData.search_details.numberOfNights} Night
                {hotelData.search_details.numberOfNights > 1 ? "s" : ""}
              </div>
              <div className="col text-end fs-14 fw-normal">
                {currencyFormat(
                  hotelData.search_details.roomType.total_selling_price -
                    hotelData.search_details.roomType.total_fees_and_taxes,
                  false
                )}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="col-5 text-start fs-14">Fees and taxes:</div>
              <div className="col text-end fs-14 fw-normal">
                {currencyFormat(
                  hotelData.search_details.roomType.total_fees_and_taxes,
                  false
                )}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="col-5 text-start fs-14">Room/Guest</div>
              <div className="col text-end fs-14 fw-normal">
                {hotelData.search_details.roomGuests.length ?? 1} /{" "}
                {getTotalAdults(hotelData.search_details.roomGuests) +
                  getTotalChildren(hotelData.search_details.roomGuests)}{" "}
                Pax
              </div>
            </div>
            <hr />
            <div className="d-flex mt-2">
              <div className="col-5 text-start">
                <div className="fs-5 fw-bold">Final Cost</div>
              </div>
              <div className="col text-end">
                <div className="fs-5 fw-bold text-danger">
                  {currencyFormat(
                    hotelData.search_details.roomType.total_selling_price
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ border: "1px solid #d6d6d6" }} className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <h6 className="card-title mb-0">
                  <i
                    className="ri-error-warning-line position-relative text-success fs-15"
                    style={{ top: "2px" }}
                  ></i>{" "}
                  Cancellation Policy
                </h6>
                <div className="p-2">
                  <p className="fs-14 mb-1">
                    {hotelData.search_details.roomType.cancellation_policies}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-check mb-2">
          <input
            onClick={() =>
              setIsCancellationPolicyAgreed((prev: boolean) => !prev)
            }
            className="form-check-input"
            type="checkbox"
          />
          <label className="form-check-label" htmlFor="formCheck1">
            I have READ and AGREE to the Cancellation Policy.
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="success"
          disabled={isLoading || !isCancellationPolicyAgreed}
        >
          {isLoading ? "Processing" : "Proceed to payment"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HotelPaymentConfirmationModal;
